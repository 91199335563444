import React, { useState } from 'react'
// import '../../../assets/BgMountain.jpg'
import { FaUser } from "react-icons/fa6";
import { FaLock } from "react-icons/fa";
import { MdVpnLock } from "react-icons/md";
import { Link } from 'react-router-dom';
import logo from '../../../src/assets/logo.png'
import authService from '../../services/authService';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { IoIosClose } from 'react-icons/io';
import { RiRobot3Line } from 'react-icons/ri';
import { HiOutlineMail } from "react-icons/hi";

import { LazyBackgroundImage } from '../../components/LazyImages';


// dd5d5a

// import jwt_decode from "jwt-decode";



const ForgotPasswordModal = ({ show, onClose, onSubmit, modalMessage }) => {
    const [email, setEmail] = useState('');

    if (!show) {
        return null;
    }

    const handleSubmit = () => {
        onSubmit(email);
    };

    return (
        <div className={`fixed inset-0 z-20 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-500 ${show ? 'opacity-100' : 'opacity-0'}`}>
            <div className="flex flex-col w-[600px] items-center justify-center bg-[#eeece7] px-4 py-8 rounded-3xl shadow-lg">
                <div className='flex justify-between w-full mb-5 -mt-3'>
                    <div className='rounded-full p-2 bg-[#f4ccc9]'>
                        <RiRobot3Line size={24} />
                    </div>
                    <button onClick={onClose} className=''>
                        <IoIosClose size={36} />
                    </button>
                </div>
                <div>
                    {modalMessage ? (
                        <div className='flex flex-col items-center justify-center'>
                            {/* <script src="https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs" type="module"></script>
                            <dotlottie-player src="https://lottie.host/4f3d7cb9-6ed2-469f-beff-5f25b66a7905/hnvq8zAxGw.json" background="transparent" speed="1" style={{ width: '300px', height: '300px' }} loop autoplay></dotlottie-player> */}
                            <p className='text-center text-lg font-semibold mb-8'>{modalMessage}</p>
                        </div>
                    ) : (
                        <>
                            <p className='font-semibold mb-3 text-lg text-center'>
                                Not a Problem! Let's get you back in.
                            </p>
                            <div className='flex space-x-3 items-center text-center mb-8'>
                                <p>Please enter your email to receive a password reset link.</p>
                            </div>
                            <div className='w-full space-y-4 text-sm flex flex-col mb-3'>
                                <div className="relative flex items-center w-full mb-5">
                                    <HiOutlineMail size={18} className="absolute ml-5" />
                                    <input
                                        className="pl-12 appearance-none rounded-full h-12 w-full leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
                                        id="email"
                                        type="text"
                                        placeholder="Email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </div>
                                <button
                                    type='button'
                                    onClick={handleSubmit}
                                    className="rounded-md ring-2 ring-slate-700 bg-[#f4ccc9] p-2 w-full"
                                >
                                    Submit
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const LoginForm = () => {
    const navigate = useNavigate();
    const [accountEmail, setAccountEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);


    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const auth = getAuth();

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        setLoading(true);
        setErrorMessage('');

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/login`, {
                accountEmail,
                password
            }, {
                withCredentials: true  // needed if the server is using cookies
            });
            if (response.data.msg === 'success!') {
                // Perform actions on success, such as saving the token or redirecting
                console.log("Login successful: ", response.data);
                localStorage.setItem('userToken', response.data.userToken);
                console.log('Token stored:', localStorage.getItem('userToken'));

                const token = localStorage.getItem('userToken');
                let userId = null;

                if (token) {
                    console.log('user token in storage:', token)
                    try {
                        const decodedToken = jwtDecode(token);
                        userId = decodedToken.id;
                        console.log('Decoded userId:', userId); // Debug log to ensure userId is decoded correctly
                    } catch (error) {
                        console.error('Failed to decode token:', error);
                    }
                }
                
                navigate("/login-loading", { state: { from: '/login' } });
            } else {
                // Handle cases where the server explicitly sends a non-success message within a successful HTTP response
                setErrorMessage('Login was not successful. Please check your credentials.');
            }
        } catch (error) {
            // Handle errors here
            console.error('Login error:', error);
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('Network error. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleForgotPassword = async (email) => {
        try {
            console.log("Sending password reset email to:", email);  // Add logging
            const actionCodeSettings = {
                url: `https://guestlink-56a36.web.app/action?email=${encodeURIComponent(email)}`,
                handleCodeInApp: true,
            };
            await sendPasswordResetEmail(auth, email);
            setModalMessage('Password reset email sent!');
        } catch (error) {
            console.error("Error sending password reset email:", error);  // Add logging
            setModalMessage('Error sending password reset email: ' + error.message);
        }
    };

    const handleCloseModal = () => {
        console.log('Closing modal');
        setShowModal(false);
        setModalMessage('');
    };

    const openModal = () => {
        console.log('Opening modal');
        setShowModal(true);
    };
    
    return (
        <LazyBackgroundImage
            name="snowHouse"
            className="bg-no-repeat bg-cover bg-center"
        >
            <form className=" flex flex-col items-center justify-center h-screen">
                <ForgotPasswordModal
                show={showModal}
                onClose={handleCloseModal}
                onSubmit={handleForgotPassword}
                modalMessage={modalMessage}
                />
                <div href="/assistants" className="logo flex items-center space-x-2 px-4 h-16 -mt-10">
                    <span className=" flex items-center text-4xl font-extrabold text-white space-x-3">
                        <img src={logo} alt="GuestLink Logo" className='h-16 w-16 ' />
                        <p className='mb-2 -ml-4'>GuestLink</p>
                    </span>
                </div>
                <div className="bg-slate-500/10 shadow-lg mt-24 rounded-2xl p-8 max-w-sm w-full backdrop-filter backdrop-blur-md bg-opacity-10 ring-2 ring-white ring-opacity-50">
                    <h2 className="text-4xl text-white font-bold mb-12 text-center">Login</h2>
                    {errorMessage && (
                        <div className="mb-4 -mt-6 text-red-500 font-bold text-center">
                            {errorMessage}
                        </div>
                    )}
                    <div className="mb-8">

                        <div className="relative flex items-center">
                            <FaUser className="absolute ml-5 text-gray-200" />
                            <input
                                onChange={e => {setAccountEmail(e.target.value)}}
                                className="pl-12 appearance-none bg-white bg-opacity-10 backdrop-blur-md rounded-full w-full py-2 px-3 text-gray-200 leading-tight focus:outline-none focus:ring focus:ring-white focus:ring-opacity-50"
                                id="username"
                                type="text"
                                placeholder="Email"
                            />
                        </div>
                    </div>
                    <div className="mb-10">
                        <div className="relative flex items-center">
                            <FaLock className="absolute ml-5 text-gray-200" />
                            <input
                                onChange={e => {setPassword(e.target.value)}}
                                className="pl-12  appearance-none bg-white bg-opacity-10 backdrop-blur-md rounded-full w-full py-2 px-3 text-gray-200 leading-tight focus:outline-none focus:ring focus:ring-white focus:ring-opacity-50"
                                id="password"
                                type="password"
                                placeholder="Password"
                            />
                        </div>
                    </div>

                    <div className="flex items-center justify-between mb-8">
                        <label className="inline-flex items-center">
                            <input type="checkbox" className="form-checkbox text-white rounded" />
                            <span className="ml-2 text-white font-bold">Remember me</span>
                        </label>
                        <button type='button' onClick={openModal} class="inline-block align-baseline font-bold text-sm text-purple-200 hover:text-blue-400" >
                            Forgot Password?
                        </button>
                    </div>
                    <div className="flex items-center text-center justify-center flex-grow">
                        <button onClick={handleLogin} className="w-full bg-blue-500 hover:bg-blue-400 active:bg-blue-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline">
                            Login
                        </button>
                        {/* <button type='submit' to={'/login-loading'} class="w-full bg-blue-500 hover:bg-blue-400 active:bg-blue-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline">
                            Login
                        </button> */}
                    </div>
                    <div className="mt-4 text-center">
                        <Link to={'/register'} className="inline-block align-baseline font-bold text-sm text-purple-200 hover:text-blue-400">
                            Don't have an account yet? Register
                        </Link>
                    </div>
                </div>
            </form>
        </LazyBackgroundImage>
    )
}

export default LoginForm;
