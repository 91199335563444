import './App.css';
import './views/Assistants'
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Assistants from './views/Assistants';
import CreateAssistant from './views/CreateAssistant';
import LoginForm from './views/Auth/LoginForm';
import LoginLoading from './views/Auth/LoginLoading';
import Settings from './views/Settings';
import LandingPage from './views/LandingPage';
import RegisterForm from './views/Auth/RegisterForm';
import CreationLoading from './views/CreationLoading';
import Analytics from './views/Analytics';
import UpdateAssistant from './views/UpdateAssistant';
import GhostRegister from './views/Auth/GhostRegister';
import CheckoutPage from './views/CheckoutPage';
import { useEffect } from 'react';
import ResetPassword from './views/ResetPassword';
import Communication from './views/Communication';
import MessageRenters from './views/MessageRenters';
// import UnansweredQuestions from './views/UnansweredQuestions';
import BlogDirectory from './views/BlogDirectory';
import Blog1 from './views/BlogContent/Blog1';
import Blog2 from './views/BlogContent/Blog2';
import Blog3 from './views/BlogContent/Blog3';

import { authenticateWithFirebase } from './services/firebaseAuth';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './config/firebase';

function App() {

    useEffect(() => {
        console.log('App component mounted, checking authentication');
        const jwtToken = localStorage.getItem('userToken');
        if (jwtToken) {
            console.log('JWT token found, initiating Firebase authentication');
            authenticateWithFirebase(jwtToken);
        } else {
            console.log('No JWT token found');
        }

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log('Firebase user authenticated:', user.uid);
            } else {
                console.log('No Firebase user authenticated');
            }
        });

        return () => unsubscribe();
    }, []);

    //for rewardful token storage
    useEffect(() => {
        // Initialize Rewardful
        window.rewardful = window.rewardful || function () {
            (window.rewardful.q = window.rewardful.q || []).push(arguments);
        };

        // Check for affiliate token in URL
        const urlParams = new URLSearchParams(window.location.search);
        const affiliateToken = urlParams.get('via');

        if (affiliateToken) {
            // If token is in URL, set it as the source
            window.rewardful('source', affiliateToken);
        }

        // Execute code when Rewardful is ready
        window.rewardful('ready', function () {
            if (window.Rewardful.referral) {
                console.log('This is a referral visit. Referral ID:', window.Rewardful.referral);
                // You can add any referral-specific logic here
            }

            if (window.Rewardful.affiliate) {
                console.log('Affiliate information:', window.Rewardful.affiliate);
                // You can use affiliate information to personalize the experience
            }
        });

    }, []);

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route element={<Assistants />} path="/assistants" />
                    <Route element={<CreateAssistant />} path="/create-assistant" />
                    <Route element={<LoginForm />} path="/login" />
                    <Route element={<LoginLoading />} path="/login-loading" />
                    <Route element={<Settings />} path="/settings" />
                    <Route exact element={<LandingPage />} path="/" />
                    <Route element={<RegisterForm />} path="/register" />
                    <Route element={<CreationLoading />} path="/creating-assistant" />
                    <Route element={<Analytics />} path="/analytics" />
                    <Route element={<UpdateAssistant />} path="/update-assistant/:id" />
                    <Route element={<GhostRegister />} path="/reg-g" />
                    <Route element={<CheckoutPage />} path="/payment" />
                    <Route element={<ResetPassword />} path="/action" />
                    <Route element={<Communication />} path="/communication" />
                    <Route element={<MessageRenters />} path="/message-renters/:assistantId" />



                    {/* <Route element={<UnansweredQuestions />} path="/unanswered-questions/:id" /> */}



                    <Route element={<BlogDirectory />} path="/blog-directory" />
                    <Route element={<Blog1 />} path="/AI-for-Airbnb-Hosts" />
                    <Route element={<Blog2 />} path="/Self-Manage-your-Short-Term-Rental" />
                    <Route element={<Blog3 />} path="/ical-instructions" />

                </Routes>
            </BrowserRouter>
        </div>
    );
}





export default App;
