import axios from 'axios';

const API_URL = `${process.env.REACT_APP_BACKEND_URL}/api/auth/`;

// handle registering a new user
// const register = async (accountEmail, password) => {
//     // send a POST request to the API with the user's information
//     try {
//         const response = await axios.post(API_URL + 'register', {
//             accountEmail,
//             password
//         });

//         if (response.data.userToken) {
//             console.log(response.data)
//             localStorage.setItem('userToken', JSON.stringify(response.data));
//         }

//         console.log(response.data)
//         // return the response from the API
//         return response.data;
//     } catch (error) {
//         console.log(error, "error registering authService");
//     }
// };

// // handle logging in an existing user
// const login = async (accountEmail, password) => {
//     // send a POST request to the API with the user's information
//     try {
//         const response = await axios.post(API_URL + 'login', {
//             accountEmail,
//             password,
//         }, { withCredentials: true });

//         // if the API returns an access token, store the user's information in local storage
//         if (response.data.userToken) {
//             console.log(response.data)
//             localStorage.setItem('userToken', JSON.stringify(response.data));
//         }

//         console.log(response.data)

//         // return the response from the API
//         return response.data;
//     } catch (error) {
//         console.log(error, "error loggin in authService")
//     }
// };


// handle logging out the current user
// const logout = async () => {
//     console.log('Removing userToken from localStorage');
//     localStorage.removeItem('userToken'); // Ensure this matches the key used in login
//     try {
//         const response = await axios.post(API_URL + 'logout');
//         console.log("Logged out from the server:", response.data);
//     } catch (error) {
//         console.log("Error logging out from the server authService", error);
//     }
// };

const logout = async () => {
    console.log('Removing userToken from localStorage');
    localStorage.removeItem('userToken'); // Ensure this matches the key used in login

    // Remove all cookies
    document.cookie.split(";").forEach(function (c) {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    try {
        const response = await axios.post(API_URL + 'logout');
        console.log("Logged out from the server:", response.data);
    } catch (error) {
        console.log("Error logging out from the server authService", error);
    }
};

// define the authentication service object
const authService = {
    logout,
};

// export the authentication service object
export default authService;
