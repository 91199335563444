import React, { useRef, useState, useEffect } from 'react';
import house from '../assets/snow-house.jpeg';
import { Link } from 'react-router-dom';
import { FaRegEdit } from "react-icons/fa";
import { IoIosStats } from "react-icons/io";



// assistant card colors:

// #e5def6 purple
//#ffdfe7 pink
//#d9e2f7 blue
//#d9e2f7 orange


const SampleCard = ({ assistant, onClick, isActive}) => {
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef();

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div onClick={onClick} className={`min-w-56 w-56 bg-white mb-3 rounded-2xl border border-gray-200 m-1 ${isActive ? 'ring ring-[#f6656c] ring-opacity-50' : ''}`}>
            {/* <img className="rounded-t-2xl w-full h-28" src={assistant.coverImageURL} alt="Assistant cover" /> */}
            <div className='w-full h-28 overflow-hidden mb-2'>
                {assistant.coverImageURL ? (
                    <img src={assistant.coverImageURL} className='object-cover object-center w-full h-full block rounded-t-2xl' alt="Assistant cover" />
                ) : (
                    <div className='w-full h-full rounded-t-2xl' style={{ backgroundColor: assistant.backgroundColor || '#e5def6' }}></div>
                )}
            </div>
            <div className="p-5">
                <h5 className="text-gray-900 font-bold text-xl tracking-tight mb-2">{assistant.propertyAddress}</h5>
                <div className="relative text-right">  {/* Added relative here */}
                    <button onClick={toggleMenu} type="button" className="text-gray-500 rounded-full p-2 hover:bg-gray-100 focus:outline-none">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7"></path>
                        </svg>
                    </button>
                    {showMenu && (
                        <div ref={menuRef} className='absolute -top-10 -right-40 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20'>
                            <div className='py-1'>
                                <Link to={`/update-assistant/${assistant._id}`} className='flex items-center space-x-2 px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100'>
                                    <FaRegEdit size={18} />
                                    <p className=''>Edit</p>
                                </Link>
                                <Link className='flex items-center space-x-2 px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100'>
                                    <IoIosStats size={18} />
                                    <p className=''>Details</p>
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SampleCard;
