import React, { useRef, useEffect, useState } from 'react';
import { LazyLoadedImage } from '../../components/LazyImages';
import { Link } from 'react-router-dom';
import { MoveDown } from 'lucide-react';
import { HiOutlineMail } from "react-icons/hi";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Blog3 = () => {
    const introRef = useRef(null);
    const importCalendarRef = useRef(null);
    const compatibilityRef = useRef(null);
    const stepByStepRef = useRef(null);
    const airbnbStepsRef = useRef(null);
    const VrboStepsRef = useRef(null);
    const bookingStepsRef = useRef(null);
    const optInFeatureRef = useRef(null);
    const bestPracticesRef = useRef(null);
    const navigate = useNavigate();
    const [email, setEmail] = useState();

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleGhost = async () => {
        // e.preventDefault();
        console.log('running handler');
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/ghost`);
            console.log('ghost:', response);
            const { userId } = response.data;

            localStorage.setItem('userToken', response.data.userToken);
            console.log('Token stored:', localStorage.getItem('userToken'));

            sessionStorage.setItem('ghostId', userId);
            navigate("/create-assistant", { state: { from: '/landing-page' } });
        } catch (error) {
            console.error('error with ghost:', error);
        }
    };

    return (
        <div className="blog-content min-h-screen bg-[#f5f4f0]">
            <header className="relative h-[400px] mb-12">
                <LazyLoadedImage
                    name="islandAerialHotel"
                    alt="Calendar syncing across devices"
                    className="absolute inset-0 w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="text-center text-white px-4">
                        <h1 className="text-4xl md:text-6xl font-bold mb-4">
                            Sync Your Calender and Master Guest Opt-In for Short-Term Rentals
                        </h1>
                        <p className="text-lg md:text-xl max-w-3xl mx-auto">
                            Streamline your rental management and build guest relationships with GuestLink's powerful return renter features.
                        </p>
                    </div>
                </div>
            </header>

            <main className="container mx-auto px-4 md:px-0 flex flex-col md:flex-row">
                <article className="md:w-2/3 md:pr-8">
                    <div className="flex items-center mb-8">
                        <LazyLoadedImage
                            name="texting"
                            alt="Author Avatar"
                            className="w-12 h-12 rounded-full mr-4"
                        />
                        <div>
                            <h2 className="font-semibold">Jack Piro</h2>
                            <p className="text-sm text-gray-600">Published 4 Aug 2024</p>
                        </div>
                    </div>

                    <section ref={introRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Introduction</h2>
                        <p className="mb-4">
                            Managing multiple short-term rental listings across various platforms can be challenging. GuestLink's calendar sync allows you to enable our unique opt-in feature. Encourage repeat renters and direct bookings by offering direct booking discounts and up to date offers.
                        </p>
                    </section>

                    <section ref={importCalendarRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Import a calendar to sync with your reservation calendar</h2>
                        <p className="mb-4">
                            When your calendars are synced across multiple platforms, you can view all your reservations, high-demand dates, and local events across all your calendars. This can help you increase performance by presenting opportunities to adjust your rates and prevent double-booking.
                        </p>
                    </section>

                    <section ref={compatibilityRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Compatibility</h2>
                        <p className="mb-4">
                            We support iCal, a file format you can use to import and sync Your calendars from whichever booking platform you use Follow the steps for your specific platform and copy paste the calender link when creating your assistant from the GuestLink website.
                        </p>
                    </section>

                    {/* <section ref={stepByStepRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Step-by-Step Guide to Import a Calendar</h2>
                        <p className="mb-4">
                            Each property has its own reservation calendar. Select the property you want to import a calendar for. You can import up to five calendars to each property.
                        </p>
                        <ol className="list-decimal pl-6 mb-4">
                            <li>Log in to your GuestLink account.</li>
                            <li>Select the appropriate listing.</li>
                            <li>Select Calendar.</li>
                            <li>Select Import & export.</li>
                            <li>Select Import a calendar to open the edit panel.</li>
                            <li>Retrieve the calendar address for the calendar you wish to import.</li>
                            <li>Paste the calendar address in the URL field in the edit panel.</li>
                            <li>Change webcal:// to http:// in the imported calendar's URL in the edit panel.</li>
                            <li>Select your preference for calendar block availability.</li>
                            <li>Name the calendar.</li>
                            <li>Select a color.</li>
                            <li>Select Import.</li>
                        </ol>
                    </section> */}

                    <section ref={airbnbStepsRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">How to Export Your Airbnb Calendar</h2>
                        <p className="mb-4">
                            To sync your Airbnb calendar with GuestLink, follow these steps:
                        </p>
                        <ol className="list-decimal pl-6 mb-4">
                            <li>Select Calendar.</li>
                            <li>Select the listing you want to export.</li>
                            <li>Find your pricing and availability settings in the side panel.</li>
                            <li>Select Availability.</li>
                            <li>Select Calendar Sync.</li>
                            <li>Select Export calendar.</li>
                            <li>Copy the iCal URL that appears in the pop-up window.</li>
                        </ol>
                        {/* Placeholder for Airbnb video */}
                        <div className="mb-4">
                            <h3 className="text-xl font-semibold mb-2">Video Tutorial: Exporting Airbnb Calendar</h3>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/kmua8fmZTI4?si=i64TQB-tr7pDUqpv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </section>

                    {/* <section ref={bookingStepsRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">How to Export Your Booking.com Calendar</h2>
                        <p className="mb-4">
                            To sync your Booking.com calendar with GuestLink, follow these steps:
                        </p>
                        <ol className="list-decimal pl-6 mb-4">
                            <li>Log in to the Extranet.</li>
                            <li>Select Rates & Availability then Calendar.</li>
                            <li>Select Sync calendars.</li>
                            <li>Copy the calendar link by selecting Copy link.</li>
                        </ol>
                        
                        <div className="mb-4">
                            <h3 className="text-xl font-semibold mb-2">Video Tutorial: Exporting Booking.com Calendar</h3>
                            
                        </div>
                    </section> */}

                    <section ref={VrboStepsRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">How to Export Your Vrbo Calendar</h2>
                        <p className="mb-4">
                            To sync your Vrbo calendar with GuestLink, follow these steps:
                        </p>
                        <ol className="list-decimal pl-6 mb-4">
                            <li>Log in to your Vrbo host account.</li>
                            <li>Select Calendar from the left navigation menu.</li>
                            <li>Click on Export Calendar at the top.</li>
                            <li>Check the box next to "Include tentative bookings."</li>
                            <li>Copy the iCal URL that appears.</li>
                        </ol>
                        {/* Placeholder for Vrbo video */}
                        <div className="mb-4">
                            <h3 className="text-xl font-semibold mb-2">Video Tutorial: Exporting Airbnb Calendar</h3>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/s4Pt8EvkByY?si=f41mhvhQZ5mG40ny" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>                        </div>
                    </section>

                    <section ref={optInFeatureRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Understanding and Utilizing the Opt-In Feature</h2>
                        <p className="mb-4">
                            GuestLink's opt-in feature is designed to help you build a direct relationship with your guests while respecting their privacy. Here's how it works:
                        </p>
                        <ul className="list-disc pl-6 mb-4">
                            <li>When guests check out, they receive an automated message thanking them for their stay and providing checkout instructions.</li>
                            <li>The message includes an invitation to opt-in for future updates and potential discounts for repeat stays.</li>
                            <li>Guests who opt-in are added to your contact list, which you can access through your GuestLink dashboard.</li>
                            <li>You can send targeted messages to your opted-in guests, such as special offers or updates about your property.</li>
                            <li>Guests can easily opt-out at any time, ensuring compliance with privacy regulations.</li>
                        </ul>
                    </section>

                    <section ref={bestPracticesRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Best Practices for Calendar Sync and Opt-In Feature</h2>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Regularly check that your calendars are syncing correctly to avoid any booking conflicts.</li>
                            <li>Use the opt-in feature to send personalized, value-adding communications to your guests.</li>
                            <li>Avoid over-communicating with opted-in guests; focus on quality over quantity in your messages.</li>
                            <li>Offer exclusive discounts or early booking opportunities to your opted-in guests to encourage loyalty.</li>
                            <li>Always include clear opt-out instructions in your communications to maintain trust and comply with regulations.</li>
                            <li>Use the insights from your opt-in list to tailor your property and services to your most frequent guests.</li>
                        </ul>
                    </section>
                </article>

                <aside className="sidebar md:w-1/3 mt-12 md:mt-0">
                    <div className="p-6 rounded-lg">
                        <div className="mb-6">
                            <LazyLoadedImage name="texting" alt="Alex Thompson" className="w-16 h-16 rounded-full mb-2" />
                            <h2 className="text-xl font-semibold">Jack Piro</h2>
                            <p className="text-sm text-gray-600">Published 4 Aug 2024</p>
                        </div>
                        <nav className="mb-8">
                            <h3 className="text-lg font-semibold mb-4">Table of Contents</h3>
                            <ul className="space-y-0">
                                {[
                                    { title: 'Introduction', ref: introRef },
                                    { title: 'Import a Calendar', ref: importCalendarRef },
                                    { title: 'Compatibility', ref: compatibilityRef },
                                    // { title: 'Step-by-Step Guide', ref: stepByStepRef },
                                    { title: 'Airbnb Calendar Export', ref: airbnbStepsRef },
                                    { title: 'Vrbo Calendar Export', ref: VrboStepsRef },
                                    { title: 'Booking.com Calendar Export', ref: bookingStepsRef },
                                    { title: 'Opt-In Feature', ref: optInFeatureRef },
                                    { title: 'Best Practices', ref: bestPracticesRef }
                                ].map((item, index) => (
                                    <li
                                        key={index}
                                        className={`hover:bg-[#f4ccc9] px-2 flex items-center justify-between border-black border-b-2 ${index === 0 ? 'border-t-2' : ''} py-3 cursor-pointer`}
                                        onClick={() => scrollToSection(item.ref)}
                                    >
                                        <span>{item.title}</span>
                                        <MoveDown />
                                    </li>
                                ))}
                            </ul>
                        </nav>

                        <div className="newsletter-signup">
                            <h3 className="text-xl font-semibold mb-2">Stay Updated</h3>
                            <p className="text-sm text-gray-600 mb-4">Get the latest tips on self-managing your short-term rental property with AI.</p>
                            <form className="space-y-4 flex flex-col justify-center">
                                <div className="relative flex items-center w-full mb-4">
                                    <HiOutlineMail size={18} className="absolute ml-5" />
                                    <input
                                        className="pl-12 bg-white appearance-none rounded-2xl h-12 w-full leading-tight ring-2 ring-black focus:ring-2 focus:ring-[#f6656c] focus:ring-opacity-50 focus:outline-none"
                                        id="email"
                                        type="email"
                                        placeholder="Your Email"
                                        value={email}
                                        onChange={e => { setEmail(e.target.value) }}
                                    />
                                </div>
                                <div className='flex items-center justify-center w-full px-5'>
                                    <button
                                        type="submit"
                                        className="adjustable-create-button w-full"
                                    >
                                        Subscribe
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </aside>
            </main>

            {/* Footer */}
            <footer className='flex flex-col w-full h-[400px] justify-center px-16 py-10 space-y-7 bg-white'>
                <div className='flex justify-between items-center h-full'>
                    <a href="/" className="logo flex items-center space-x-2 px-4 h-16 mt-3 mb-5">
                        <span className="text-black flex items-center text-2xl font-bold space-x-3 ">
                            <LazyLoadedImage name="logo" alt="GuestLink Logo" className='h-12 w-12 -ml-8' />
                            <p className='mb-2 -ml-4 text-3xl'>GuestLink</p>
                        </span>
                    </a>
                    <p className='text-2xl font-bold w-96 text-end'>Streamline your AirBNB Management Today</p>
                </div>
                <div className='flex justify-between pb-10 border-neutral-500 border-b-2 h-full'>
                    <div className='flex items-center space-x-10 font-light'>
                        <div className='space-y-2'>
                            <p>Email</p>
                            <p>hello@guestlinkai.com</p>
                        </div>
                        <div className='space-y-2'>
                            <p>Phone</p>
                            <p>+1 (408) 256-2545</p>
                        </div>
                    </div>
                    <div className='flex space-x-10 items-center'>
                        <button onClick={handleGhost} className='landing-glow-button'>Try It Now</button>
                        <Link to={"/register"} className='border-2 border-black px-4 py-2 rounded-full'>
                            Login or Sign Up
                        </Link>
                    </div>
                </div>
                <div className='flex justify-between font-light h-full text-sm '>
                    <div className='flex items-center space-x-5'>
                        <p>© 2023 GuestLink LLC.</p>
                        <p>All Rights Reserved</p>
                    </div>
                    <div className='flex space-x-8 items-center'>
                        <a
                            href='https://docs.google.com/document/d/e/2PACX-1vSxC7ZSjTLYfp_ZHB9Rna7kzz-10u2wl74pjZxZqbslaNAZKbxkP22UoJEVeOrQvalbF1HkAzZI0Ah3/pub'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy Policy
                        </a>
                        <a
                            href='https://docs.google.com/document/d/e/2PACX-1vR-N20f116EB2zHCuKWhBC6GpwFzpj-rZyUe5Rtp3MZaEnrmudBGStzB-JqZIK0kiPhw1obUGu08xyu/pub'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Terms of Service
                        </a>
                        <a
                            href='https://docs.google.com/document/d/e/2PACX-1vQnitjHjZ6fhtCcRGZxKyHWOOl0hiU9lhYhSpSke26MQ5Wmpf0jmJHbqGNP6iTnflrYD09gLdXaSWqT/pub'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Our Twilio Opt-in Disclosures
                        </a>
                    </div>
                </div>
            </footer>

            <div className='w-full h-[15px] self-center bg-[#FF5A5F]'>
                <p> </p>
            </div>
        </div>
    );
};

export default Blog3;