import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getAuth, confirmPasswordReset } from 'firebase/auth';
import axios from 'axios';
import logo from '../../src/assets/trial-logo.png'
import { FaLock } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const auth = getAuth();
    const location = useLocation();
    const actionCode = new URLSearchParams(location.search).get('oobCode');
    const email = new URLSearchParams(location.search).get('email'); // get the email from the query string
    const navigate = useNavigate();

    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match');
            return;
        }

        try {
            await confirmPasswordReset(auth, actionCode, newPassword);
            // Call your backend to update the password in your database
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/forgot-password`, { email, newPassword });
            setMessage('Password has been reset successfully');

            // Redirect to login page after 2 seconds
            setTimeout(() => {
                navigate('/login');
            }, 2000);
        } catch (error) {
            setMessage(`Error resetting password: ${error.message}`);
        }
    };

    return (
        // <div className="fixed inset-0 bg-black flex justify-center items-center">
        //     <form className="flex flex-col w-[600px] items-center justify-center bg-[#eeece7] px-4 py-8 rounded-3xl shadow-lg">
        //         <h2 className="font-semibold mb-3 text-lg text-center">Reset Password</h2>
        //         <input
        //             className="pl-4 appearance-none rounded-full h-12 w-full leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50 mb-4"
        //             type="password"
        //             value={newPassword}
        //             onChange={(e) => setNewPassword(e.target.value)}
        //             placeholder="New Password"
        //             required
        //         />
        //         <input
        //             className="pl-4 appearance-none rounded-full h-12 w-full leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50 mb-4"
        //             type="password"
        //             value={confirmPassword}
        //             onChange={(e) => setConfirmPassword(e.target.value)}
        //             placeholder="Confirm Password"
        //             required
        //         />
        //         <button
        //             type="submit"
        //             onClick={handleResetPassword}
        //             className="rounded-md ring-2 ring-slate-700 bg-[#f4ccc9] p-2 w-full mb-4"
        //         >
        //             Reset Password
        //         </button>
        //         <p className="text-center text-lg font-semibold">{message}</p>
        //     </form>
        // </div>

        <div className="bg-login-image flex flex-col items-center justify-center h-screen">
            <div className="logo flex items-center space-x-2 px-4 h-16 -mt-10">
                <span className="flex items-center text-3xl font-extrabold text-white">
                    <img src={logo} alt="GuestLink Logo" className='h-32 w-32 -ml-8' />
                    <p className='mb-2 -ml-4'>GuestLink</p>
                </span>
            </div>
            <div className="bg-slate-500/10 shadow-lg mt-24 rounded-2xl p-8 max-w-sm w-full backdrop-filter backdrop-blur-md bg-opacity-10 ring-2 ring-white ring-opacity-50">
                <h2 className="text-4xl text-white font-bold mb-12 text-center">Reset Password</h2>
                <form onSubmit={handleResetPassword}>
                    <div className="mb-8">
                        <div className="relative flex items-center">
                            <FaLock className="absolute ml-5 text-gray-200" />
                            <input
                                className="pl-12 appearance-none bg-white bg-opacity-10 backdrop-blur-md rounded-full w-full py-2 px-3 text-gray-200 leading-tight focus:outline-none focus:ring focus:ring-white focus:ring-opacity-50"
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="New Password"
                                required
                            />
                        </div>
                    </div>
                    <div className="mb-10">
                        <div className="relative flex items-center">
                            <FaLock className="absolute ml-5 text-gray-200" />
                            <input
                                className="pl-12 appearance-none bg-white bg-opacity-10 backdrop-blur-md rounded-full w-full py-2 px-3 text-gray-200 leading-tight focus:outline-none focus:ring focus:ring-white focus:ring-opacity-50"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm Password"
                                required
                            />
                        </div>
                    </div>
                    <div className="flex items-center text-center justify-center flex-grow">
                        <button onClick={() => (handleResetPassword)} className="w-full bg-blue-500 hover:bg-blue-400 active:bg-blue-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline">
                            Reset Password
                        </button>
                    </div>
                    <p className="text-center text-lg font-semibold mt-4 text-white">{message}</p>
                </form>
            </div>
        </div>

    );
};

export default ResetPassword;
