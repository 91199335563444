import React, { useRef, useState, useEffect } from 'react';
import house from '../assets/snow-house.jpeg';
import { Link, useLocation } from 'react-router-dom';

import { IoIosDownload } from "react-icons/io";
import { MdOutlineQuestionAnswer } from "react-icons/md";
import { IoIosStar } from "react-icons/io";
import { FiMessageSquare } from "react-icons/fi";
import { TbHomeStats } from "react-icons/tb";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoIosStats } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";
import axios from 'axios';




export default function StatsCard({assistant, handleShow}) {
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef();
    const location = useLocation()
    const numOfReviews = assistant.reviewsOfProperty.length;
    const numUnanswered = assistant.unansweredQuestions.length;
    /*If you use useLocation() directly in multiple places within your component, you are technically
    calling the function multiple times. Each call could potentially generate a new object, depending 
    on how the hook is implemented internally in react-router-dom. By calling it once and storing its result,
    you ensure that the location data is retrieved just once per component render, and you then work with 
    the same data snapshot everywhere in your component. */


    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // const handleDelete = async () => {
    //     const token = localStorage.getItem('userToken')
    //     try {
    //         const response = await axios.delete(`http://localhost:8000/api/assistants/delete/${assistant._id}`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }
    //         })
    //     } catch (error) {
    //         console.error('trouble deleteing -StatsCard.js:', error)
    //     }
    // }

    


    return (

        <div className=" bg-white w-full flex rounded-2xl p-4 border border-gray-200 ">
            {assistant.coverImageURL ? (
                <img
                    className="rounded-2xl min-h-[200px] max-h-[200px] min-w-[300px] max-w-[300px] object-cover"
                    src={assistant.coverImageURL}
                    alt="Assistant cover"
                />
            ) : (
                <div
                    className="rounded-2xl min-h-[200px] max-h-[200px] min-w-[300px] max-w-[300px]"
                    style={{ backgroundColor: assistant.backgroundColor || '#e5def6' }}
                ></div>
            )}
            <div className='flex pl-4 w-full'>

                <div className='flex flex-col w-full justify-between '>
                    <div className='text-2xl font-extrabold flex relative justify-between'>
                        <Link to={'/assistants'} className='mt-auto leading-none pt-3 hover:underline'>{assistant.propertyAddress}</Link>
                        <button onClick={toggleMenu} className=' rounded-full p-2 h-10 hover:bg-[#f4ccc9] transition duration-100 absolute top-0 right-0'>
                            <BsThreeDotsVertical size={24} />
                        </button>
                        {showMenu && (
                            <div ref={menuRef} className='absolute right-10 top-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-5'>
                                <div className='py-1'>
                                    <Link to={`/update-assistant/${assistant._id}`} className='flex items-center space-x-2 px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100'>
                                        <FaRegEdit size={18} />
                                        <p className=''>Edit</p>
                                    </Link>
                                    <Link to={'/assistants'} className='flex items-center space-x-2 px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100'>
                                        <IoIosStats size={18} />
                                        <p className=''>Details</p>
                                    </Link>
                                    {location.pathname === '/settings' && (
                                        <div onClick={() => {handleShow(assistant)}} className='flex items-center space-x-2 px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-red-300'>
                                            <FiTrash2 size={18} />
                                            <p className=''>Delete Forever</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='flex justify-between '>
                        <div className=' p-2'>
                            <p className='mb-1 text-md font-semibold'>New Reviews</p>
                            <div className='flex items-center space-x-3'>
                                <div className="relative flex items-center ">
                                    <TbHomeStats size={24} className="absolute ml-5" />
                                    <div
                                        className="bg-[#f4ccc9] flex items-center pl-12 truncate appearance-none rounded-2xl h-12 leading-tight "
                                        id="assistant-name"
                                        type="text"
                                    >
                                        <p className=' pl-2 mr-5 font-bold text-2xl'>{numOfReviews}</p>
                                    </div>
                                </div>
                                {/* <p className='p-1 rounded-2xl px-3 py-2 pt-3 bg-white h-12 inline-block hover:bg-[#f4ccc9]'>view →</p> */}
                                <div className='bg-white p-3 rounded-full hover:bg-[#f4ccc9] h-12 transition duration-100'>
                                    <IoIosDownload size={24} />
                                </div>
                            </div>
                        </div>

                        <div className='p-2'>
                            <p className='mb-1 text-md font-semibold'>Unanswered Questions</p>
                            <div className='flex items-center space-x-3'>
                                <div className="relative flex items-center ">
                                    <MdOutlineQuestionAnswer size={24} className="absolute ml-5" />
                                    <div
                                        className="bg-[#f4ccc9] flex items-center pl-12 truncate appearance-none rounded-2xl h-12 leading-tight "
                                        id="assistant-name"
                                        type="text"
                                    >
                                        <p className=' pl-2 mr-5 font-bold text-2xl'>{numUnanswered}</p>
                                    </div>
                                </div>
                                {/* <p className='p-1 rounded-2xl px-3 py-2 pt-3 bg-white inline-block h-12 hover:bg-[#f4ccc9]'>view →</p> */}
                                <div className='bg-white p-3 rounded-full hover:bg-[#f4ccc9] h-12 transition duration-100'>
                                    <IoIosDownload size={24} />
                                </div>
                            </div>
                        </div>

                        <div className='p-2'>
                            <p className='mb-1 text-md font-semibold'>Assistant Rating</p>
                            <div className='flex items-center space-x-3 '>
                                <div className="relative flex items-center ">
                                    <div
                                        className="bg-[#f4ccc9] flex items-center pl-5 truncate appearance-none rounded-2xl h-12 leading-tight "
                                        id="assistant-name"
                                        type="text"
                                    >
                                        <p className='flex spaxe-x-1 mr-5'>
                                            <IoIosStar />
                                            <IoIosStar />
                                            <IoIosStar />
                                            <IoIosStar />
                                            <IoIosStar />
                                        </p>
                                    </div>
                                </div>
                                {/* <CiCircleQuestion size={24} /> */}
                            </div>
                        </div>

                        <div className=' p-2'>
                            <p className='mb-1 text-md font-semibold'>Total Texts</p>
                            <div className='flex items-center space-x-3'>
                                <div className="relative flex items-center ">
                                    <FiMessageSquare size={24} className="absolute ml-5" />
                                    <div
                                        className="bg-[#f4ccc9] flex items-center pl-12 truncate appearance-none rounded-2xl h-12 leading-tight "
                                        id="assistant-name"
                                        type="text"
                                    >
                                        <p className=' pl-2 mr-5 font-bold text-2xl'>{assistant.totalTextsSent}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div>

            </div>
        </div>
    )
}
