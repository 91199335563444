import React, { useState, useRef } from 'react';
import { LazyLoadedImage } from '../../components/LazyImages';
import { Link } from 'react-router-dom';
import { MoveDown } from 'lucide-react';
import { HiOutlineMail } from "react-icons/hi";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Blog1 = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('')


    const introRef = useRef(null);
    const riseOfAIRef = useRef(null);
    const benefitsRef = useRef(null);
    const guestExperienceRef = useRef(null);
    const futureOfAIRef = useRef(null);
    const guestLinkRef = useRef(null);
    const conclusionRef = useRef(null);

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleGhost = async () => {
        // e.preventDefault();
        console.log('running handler');
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/ghost`);
            console.log('ghost:', response);
            const { userId } = response.data;

            localStorage.setItem('userToken', response.data.userToken);
            console.log('Token stored:', localStorage.getItem('userToken'));

            sessionStorage.setItem('ghostId', userId);
            navigate("/create-assistant", { state: { from: '/landing-page' } });
        } catch (error) {
            console.error('error with ghost:', error);
        }
    };


    return (
        <div className="blog-content min-h-screen bg-[#f5f4f0]">
            {/* Hero Section */}
            <header className="relative h-[400px] mb-12">
                <LazyLoadedImage
                    name="checkIn"
                    alt="AI concierge assisting Airbnb hosts"
                    className="absolute inset-0 w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="text-center text-white px-4">
                        <h1 className="text-4xl md:text-6xl font-bold mb-4">
                            AI for Airbnb Hosts: Revolutionizing Guest Experience and Property Management
                        </h1>
                        <p className="text-lg md:text-xl max-w-3xl mx-auto">
                            Discover how AI is transforming the short-term rental industry, enhancing guest experiences, and streamlining operations for Airbnb hosts.
                        </p>
                    </div>
                </div>
            </header>

            <main className="container mx-auto px-4 md:px-0 flex flex-col md:flex-row">
                <article className="md:w-2/3 md:pr-8">
                    <div className="flex items-center mb-8">
                        <LazyLoadedImage
                            name="texting"
                            alt="Author Avatar"
                            className="w-12 h-12 rounded-full mr-4"
                        />
                        <div>
                            <h2 className="font-semibold">Jack Piro</h2>
                            <p className="text-sm text-gray-600">Published 8 Aug 2024</p>
                        </div>
                    </div>

                    <section ref={introRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Introduction</h2>
                        <p className="mb-4">
                            The short-term rental industry, particularly for Airbnb hosts, is experiencing rapid evolution. As competition intensifies and guest expectations soar, hosts are increasingly turning to innovative solutions to maintain their edge. One of the most promising developments in this space is the integration of Artificial Intelligence (AI) into property management.
                        </p>
                        <p>
                            This post explores how AI is transforming the Airbnb hosting experience, benefiting both hosts and guests alike, and introduces GuestLink AI as a cutting-edge solution to these challenges.
                        </p>
                    </section>

                    <section ref={riseOfAIRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">1. The Rise of AI in Short-Term Rentals</h2>
                        <p className="mb-4">
                            The short-term rental market has seen exponential growth in recent years, with Airbnb at the forefront. As the industry matures, hosts face mounting challenges:
                        </p>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Managing multiple properties efficiently</li>
                            <li>Providing 24/7 guest support</li>
                            <li>Maintaining high occupancy rates</li>
                            <li>Ensuring consistent guest satisfaction</li>
                            <li>Balancing competitive pricing with profitability</li>
                        </ul>
                        <p>
                            AI technology offers solutions to these challenges, allowing hosts to automate routine tasks, enhance guest communication, and optimize their operations. According to a survey by Hostfully, hosts who use automated tools for short-term rentals save an average of 20 hours per month, highlighting the significant time-saving potential of AI integration.
                        </p>
                    </section>

                    <LazyLoadedImage
                        name="greenBeach"
                        alt="Infographic showing AI benefits for Airbnb hosts"
                        className="w-full h-64 object-cover rounded-lg mb-8"
                    />

                    <section ref={benefitsRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">2. How AI Benefits Airbnb Hosts</h2>
                        <h3 className="text-xl font-semibold mb-2">a) Automated Guest Communication</h3>
                        <p className="mb-4">
                            One of the most time-consuming aspects of hosting is responding to guest inquiries. AI-powered chatbots can handle a significant portion of guest communication, providing instant responses to common questions about check-in procedures, amenities, and local attractions. This not only saves time but also ensures consistency in responses, reducing the risk of miscommunication or human error.
                        </p>
                        <h3 className="text-xl font-semibold mb-2">b) 24/7 Availability</h3>
                        <p className="mb-4">
                            Unlike human hosts, AI assistants are available round-the-clock. This ensures that guests can get the information they need at any time, enhancing their experience and reducing the burden on hosts. A study by Booking.com found that properties with 24/7 availability have a 30% higher booking rate compared to those that do not, underscoring the importance of constant accessibility.
                        </p>
                        <h3 className="text-xl font-semibold mb-2">c) Personalized Recommendations</h3>
                        <p className="mb-4">
                            AI can analyze guest preferences and behavior to offer personalized recommendations for local attractions, restaurants, and activities. This adds value to the guest experience without requiring additional effort from the host, potentially leading to better reviews and increased bookings.
                        </p>
                        <h3 className="text-xl font-semibold mb-2">d) Efficient Property Management</h3>
                        <p>
                            AI tools can help hosts manage multiple properties more efficiently by automating tasks such as pricing optimization, cleaning schedules, and maintenance alerts. This level of automation can lead to significant cost savings, with a report by VRMB indicating that hosts who use automation save an average of 15% on operational expenses.
                        </p>
                    </section>

                    <section ref={guestExperienceRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">3. Enhancing Guest Experience with AI</h2>
                        <p className="mb-4">
                            The guest experience is paramount in the short-term rental industry. AI can significantly improve this aspect by:
                        </p>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Providing instant, accurate information about the property and local area</li>
                            <li>Offering a seamless check-in and check-out process</li>
                            <li>Addressing guest concerns promptly</li>
                            <li>Creating a personalized experience that makes guests feel valued</li>
                        </ul>
                        <p className="mb-4">
                            Data from AirDNA shows that properties with high guest satisfaction rates see a 15% increase in repeat bookings, highlighting the importance of a superior guest experience.
                        </p>
                        <p>
                            By leveraging AI, hosts can ensure that their guests receive top-notch service from the moment they book until after they check out. This level of care and attention can lead to better reviews, increased bookings, and a stronger reputation in the competitive short-term rental market.
                        </p>
                    </section>

                    <LazyLoadedImage
                        name="texting"
                        alt="Happy guests using AI concierge service"
                        className="w-full h-64 object-cover rounded-lg mb-8"
                    />

                    <section ref={futureOfAIRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">4. The Future of AI in Airbnb Hosting</h2>
                        <p className="mb-4">
                            As AI technology continues to advance, we can expect to see even more innovative applications in the short-term rental industry. Some exciting developments on the horizon include:
                        </p>
                        <ul className="list-disc pl-6 mb-4">
                            <li>
                                <strong>Predictive maintenance:</strong> AI algorithms can analyze patterns and predict when maintenance issues are likely to occur, allowing hosts to address problems before they impact guests.
                            </li>
                            <li>
                                <strong>Advanced dynamic pricing models:</strong> AI-powered pricing tools will become more sophisticated, taking into account an ever-increasing number of factors to maximize occupancy and revenue.
                            </li>
                            <li>
                                <strong>Virtual property tours:</strong> AI combined with augmented reality (AR) technology could offer potential guests immersive virtual tours, enhancing the booking experience.
                            </li>
                            <li>
                                <strong>Smart home integration:</strong> AI assistants will seamlessly integrate with smart home devices, allowing guests to control various aspects of their stay through voice commands or smartphone apps.
                            </li>
                            <li>
                                <strong>Personalized upselling:</strong> AI will analyze guest preferences and behavior to offer tailored add-on services or experiences, increasing revenue for hosts.
                            </li>
                        </ul>
                        <p className="mb-4">
                            These advancements promise to further streamline operations for hosts while simultaneously elevating the guest experience. Early adopters of these technologies are likely to gain a significant competitive advantage in the market.
                        </p>
                        <p>
                            As we look towards this AI-driven future, it's clear that the role of the host will evolve. Rather than being bogged down by routine tasks, hosts will be free to focus on strategic decisions, property improvements, and creating unique experiences that set their rentals apart.
                        </p>
                    </section>

                    <section ref={guestLinkRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">5. Introducing GuestLink: Your AI Hosting Assistant</h2>
                        <p className="mb-4">
                            While the benefits of AI for Airbnb hosts are clear, implementing this technology can seem daunting. This is where GuestLink comes in. GuestLink is a cutting-edge AI concierge service designed specifically for short-term rental hosts.
                        </p>
                        <p className="mb-4">
                            With GuestLink, hosts can:
                        </p>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Provide 24/7 AI support for guests via SMS or QR code</li>
                            <li>Automate responses to common guest inquiries</li>
                            <li>Collect valuable guest feedback</li>
                            <li>Access detailed analytics on guest interactions</li>
                            <li>Mass message opted-in renters with special offers or direct booking opportunities</li>
                        </ul>
                        <p>
                            Setting up GuestLink is simple: upload your renter guidebook, and the AI assistant is generated automatically. At just $30 per month per property, it's an affordable way to enhance your hosting game and save countless hours.
                        </p>
                    </section>

                    <LazyLoadedImage
                        name="computer"
                        alt="Happy guests using AI concierge service"
                        className="w-full h-64 object-cover rounded-lg mb-8"
                    />

                    <section ref={conclusionRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Conclusion</h2>
                        <p className="mb-4">
                            AI for Airbnb hosts is not just a futuristic concept - it's a present reality that's transforming the short-term rental industry. By leveraging AI technology like GuestLink, hosts can provide superior guest experiences, streamline their operations, and stay ahead in an increasingly competitive market.
                        </p>
                        <p>
                            The future of property management lies in automation and AI. These technologies offer a way to balance the personal touch that guests crave with the efficiency that hosts need. By embracing tools like GuestLink AI, you're not just keeping up with the industry - you're positioning yourself at the forefront of the short-term rental revolution.
                        </p>
                    </section>

                    <div className="mb-8">
                        <button onClick={handleGhost} className="adjustable-create-button">
                            Try GuestLink AI Today
                        </button>
                    </div>
                </article>

                <aside className="sidebar md:w-1/3 mt-12 md:mt-0">
                    <div className="p-6 rounded-lg">
                        <div className="mb-6">
                            <LazyLoadedImage name="texting" alt="Jack Piro" className="w-16 h-16 rounded-full mb-2" />
                            <h2 className="text-xl font-semibold">Jack Piro</h2>
                            <p className="text-sm text-gray-600">Published 8 Aug 2024</p>
                        </div>

                        <nav className="mb-8">
                            <h3 className="text-lg font-semibold mb-4">Table of Contents</h3>
                            <ul className="space-y-0">
                                {[
                                    { title: 'Introduction', ref: introRef },
                                    { title: 'The Rise of AI in Short-Term Rentals', ref: riseOfAIRef },
                                    { title: 'How AI Benefits Airbnb Hosts', ref: benefitsRef },
                                    { title: 'Enhancing Guest Experience with AI', ref: guestExperienceRef },
                                    { title: 'The Future of AI in Airbnb Hosting', ref: futureOfAIRef },
                                    { title: 'Introducing GuestLink', ref: guestLinkRef },
                                    { title: 'Conclusion', ref: conclusionRef }
                                ].map((item, index) => (
                                    <li
                                        key={index}
                                        className={`hover:bg-[#f4ccc9] px-2 flex items-center justify-between border-black border-b-2 ${index === 0 ? 'border-t-2' : ''} py-3 cursor-pointer`}
                                        onClick={() => scrollToSection(item.ref)}
                                    >
                                        <span>{item.title}</span>
                                        <MoveDown />
                                    </li>
                                ))}
                            </ul>
                        </nav>

                        <div className="newsletter-signup">
                            <h3 className="text-xl font-semibold mb-2">Stay Updated</h3>
                            <p className="text-sm text-gray-600 mb-4">Get the latest tips and insights on AI for short-term rentals directly in your inbox.</p>
                            <form className="space-y-4 flex flex-col justify-center">
                                <div className="relative flex items-center w-full mb-4">
                                    <HiOutlineMail size={18} className="absolute ml-5" />
                                    <input
                                        className="pl-12 bg-white appearance-none rounded-2xl h-12 w-full leading-tight ring-2 ring-black focus:ring-2 focus:ring-[#f6656c] focus:ring-opacity-50 focus:outline-none"
                                        id="email"
                                        type="email"
                                        placeholder="Your Email"
                                        value={email}
                                        onChange={e => { setEmail(e.target.value) }}
                                    />
                                </div>
                                <div className='flex items-center justify-center w-full px-5'>
                                    <button
                                        type="submit"
                                        className="adjustable-create-button w-full"
                                    >
                                        Subscribe
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </aside>

            </main>

            {/* Footer */}
            <footer className='flex flex-col w-full h-[400px] justify-center px-16 py-10 space-y-7 bg-white'>
                <div className='flex justify-between items-center h-full'>
                    <a href="/" className="logo flex items-center space-x-2 px-4 h-16 mt-3 mb-5">
                        <span className="text-black flex items-center text-2xl font-bold space-x-3 ">
                            <LazyLoadedImage name="logo" alt="GuestLink Logo" className='h-12 w-12 -ml-8' />
                            <p className='mb-2 -ml-4 text-3xl'>GuestLink</p>
                        </span>
                    </a>
                    <p className='text-2xl font-bold w-96 text-end'>Streamline your AirBNB Management Today</p>
                </div>
                <div className='flex justify-between pb-10 border-neutral-500 border-b-2 h-full'>
                    <div className='flex items-center space-x-10 font-light'>
                        <div className='space-y-2'>
                            <p>Email</p>
                            <p>hello@guestlinkai.com</p>
                        </div>
                        <div className='space-y-2'>
                            <p>Phone</p>
                            <p>+1 (408) 256-2545</p>
                        </div>
                    </div>
                    <div className='flex space-x-10 items-center'>
                        <button onClick={handleGhost} className='landing-glow-button'>Try It Now</button>
                        <Link to={"/register"} className='border-2 border-black px-4 py-2 rounded-full'>
                            Login or Sign Up
                        </Link>
                    </div>
                </div>
                <div className='flex justify-between font-light h-full text-sm '>
                    <div className='flex items-center space-x-5'>
                        <p>© 2023 GuestLink LLC.</p>
                        <p>All Rights Reserved</p>
                    </div>
                    <div className='flex space-x-8 items-center'>
                        <a
                            href='https://docs.google.com/document/d/e/2PACX-1vSxC7ZSjTLYfp_ZHB9Rna7kzz-10u2wl74pjZxZqbslaNAZKbxkP22UoJEVeOrQvalbF1HkAzZI0Ah3/pub'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy Policy
                        </a>
                        <a
                            href='https://docs.google.com/document/d/e/2PACX-1vR-N20f116EB2zHCuKWhBC6GpwFzpj-rZyUe5Rtp3MZaEnrmudBGStzB-JqZIK0kiPhw1obUGu08xyu/pub'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Terms of Service
                        </a>
                        <a
                            href='https://docs.google.com/document/d/e/2PACX-1vQnitjHjZ6fhtCcRGZxKyHWOOl0hiU9lhYhSpSke26MQ5Wmpf0jmJHbqGNP6iTnflrYD09gLdXaSWqT/pub'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Our Twilio Opt-in Disclosures
                        </a>
                    </div>
                </div>
            </footer>

            <div className='w-full h-[15px] self-center bg-[#FF5A5F]'>
                <p> </p>
            </div>
        </div>
    );
};

export default Blog1;