import React, { useState } from 'react';
import '../css/random.css';

const faqData = [
    {
        question: 'How do I set up GuestLink for my properties?',
        answer: 'Setting up GuestLink is easy. Sign up on our website, upload your renter guidebook, and our system will generate a custom AI concierge for your property. You will receive a unique phone number and QR code to share with your renters.',
    },
    // {
    //     question: 'Can I manage multiple properties with GuestLink?',
    //     answer: 'Yes, you can manage multiple properties with GuestLink. You can create and manage multiple AI assistants from your dashboard, adding or deleting assistants as needed.',
    // },
    {
        question: 'How much does GuestLink cost?',
        answer: 'Each AI assistant costs $30 per month. This includes 24/7 support for your guests, access to analytics, and easy cancellation with no additional fees.',
    },
    {
        question: 'How does GuestLink help improve my property\'s reviews?',
        answer: 'GuestLink prompts satisfied guests to leave positive reviews, increasing your property\'s visibility and bookings. Additionally, it collects feedback before guests leave public reviews, allowing you to address any issues promptly.',
    },
    {
        question: 'What kind of analytics does GuestLink provide?',
        answer: 'GuestLink provides detailed analytics on guest interactions with your AI assistant. You can see what questions are being asked, what renters are saying about your property, and any unanswered questions, helping you improve your service.',
    },
    // {
    //     question: 'Is there a free trial available?',
    //     answer: 'Yes, you can try GuestLink for free with your property information without giving any billing info out. You may also create an account and enjoy two weeks of free service before any payments are necessary.',
    // },
    {
        question: 'Can I cancel GuestLink anytime?',
        answer: 'Yes, you can cancel your GuestLink subscription at any time without any additional fees. You can easily manage your subscription from your dashboard.',
    },
    {
        question: 'How do renters access the AI assistant?',
        answer: 'Renters can access the AI assistant by texting the provided SMS phone number or scanning the QR code. This allows them to get instant answers to their questions about your property and the local area.',
    },
    {
        question: 'What if the AI assistant can\'t answer a question?',
        answer: 'If the AI assistant cannot answer a question, it will provide the renters with your contact information and direct them to you for a reply. The unanswered question is returned to your assistant analytics page so you can add it to the assistants knowledge for the next renters. This ensures that all guest inquiries are addressed promptly and that your 24/7 support keeps getting better.',
    },
];


// {
// question: 'How does GuestLink work?',
//     answer: 'GuestLink is an AI-powered concierge that provides 24/7 support to your renters. Simply upload your renter guidebook, and GuestLink will create a custom AI assistant accessible via SMS phone number and QR code. Renters can text their questions and receive instant answers about your property and the local area.',
//     },



const FAQItem = ({ question, answer, isOpen, onClick }) => (
    <div className=" overflow-hidden m-2 rounded-md">
        <button
            onClick={onClick}
            className="w-full text-left rounded-md p-4 focus:outline-none flex justify-between items-center bg-[#f5f4f0] hover:bg-[#e3e1da] transition-colors duration-200"
        >
            <span>{question}</span>
            <span>{isOpen ? '-' : '+'}</span>
        </button>
        <div
            className={`faq-content mt-2 rounded-md transition-[opacity] duration-200 ease-in-out ${isOpen ? 'opacity-100 max-h-screen' : 'opacity-0 max-h-0'
                }`}
        >
            <div className={`transition rounded-md ease-out opacity-0 scale-100 mb-4 ${isOpen ? 'opacity-100 scale-100' : ''} p-4 bg-[#e3e1da] text-gray-800`}>
                <p>{answer}</p>
            </div >
        </div>
    </div>
);

const FAQSection = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const handleClick = (index) => {
        setOpenIndex(index === openIndex ? null : index);
    };

    return (
        <div className="max-w-4xl mx-auto rounded-lg mb-20">
            <h2 className="text-2xl font-bold text-center py-4">Frequently Asked Questions</h2>
            <p className="text-center pb-8 text-gray-600">Have questions? We're here to help.</p>
            <div className="space-y-2">
                {faqData.map((item, index) => (
                    <FAQItem
                        key={index}
                        question={item.question}
                        answer={item.answer}
                        isOpen={index === openIndex}
                        onClick={() => handleClick(index)}
                    />
                ))}
            </div>
        </div>
    );
};

export default FAQSection;



