import React, {useState, useEffect} from 'react'
import SideBar from '../components/SideBar'
import HeaderMain from '../components/HeaderMain'
import StatsCard from '../components/StatsCard';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import '../css/random.css'
import { jwtDecode } from 'jwt-decode';


import { HiOutlineMail } from "react-icons/hi";
import { SlScreenSmartphone } from "react-icons/sl";
import { FaUserAstronaut } from "react-icons/fa6";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { RiRobot3Line } from "react-icons/ri";




import { loadStripe } from '@stripe/stripe-js';
import { stripePublicKey } from '../config/stripe';

const stripePromise = loadStripe(stripePublicKey);



const Modal = ({ show, handleClose, handleDelete }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    if (!show) return null;

    const onDelete = async () => {
        setIsDeleting(true);
        const MINIMUM_SPINNER_TIME = 3000; // Set minimum spinner time to 2 seconds

        const spinnerTimeout = new Promise(resolve => setTimeout(resolve, MINIMUM_SPINNER_TIME));
        const deletePromise = handleDelete();

        try {
            await Promise.all([spinnerTimeout, deletePromise]);
            setIsDeleted(true);
        } catch (error) {
            console.error('Error during delete:', error);
            setIsDeleting(false);
        }
    };

    return (
        <div className="fixed inset-0 z-10 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="flex flex-col w-[600px] items-center justify-center bg-[#eeece7] px-4 py-8 rounded-3xl shadow-lg">
                <div className='flex justify-between w-full mb-5 -mt-3'>
                    <div className='rounded-full p-2 bg-[#f4ccc9]'>
                        <RiRobot3Line size={24} />
                    </div>
                    {
                        isDeleted ?
                        <button onClick={handleClose}>
                            <IoIosClose size={36} />
                        </button>
                        : null
                    }
                </div>
                {!isDeleted ? (
                    <>
                        <p className='font-semibold text-lg text-center'>
                            Careful! This action <span className='font-extrabold'>*cannot*</span> be undone...
                        </p>
                        <div className='flex space-x-3 items-center mb-8 text-center'>
                            <p>Upon deletion your work will be permanently lost and phone number inaccessible immediately.</p>
                        </div>
                        {isDeleting ?
                            // <div class="spinner">
                            //     <div class="dot1"></div>
                            //     <div class="dot2"></div>
                            // </div>
                            <div className='mb-10'>
                                <div className='bounce'></div>
                            </div>
                            : <div className='w-full space-y-4 flex flex-col'>
                                <button onClick={handleClose} className='rounded-md ring-2 ring-slate-700 bg-[#f4ccc9] p-2 w-full'>
                                    Cancel
                                </button>
                                <button
                                    onClick={onDelete}
                                    className='rounded-md ring-2 ring-slate-700 hover:bg-red-300 p-2 w-full'
                                    disabled={isDeleting}
                                >
                                    Delete Permenantly
                                </button>
                            </div>}
                    </>
                ) : (
                    <div className='flex flex-col space-y-3 mb-5'>
                        <p className='font-semibold text-lg text-center text-green-600 mb-3'>
                            Assistant deleted successfully!
                        </p>
                        <p className=' font-medium w-96 text-center'>
                            Thank you, your subscription has been updated to reflect these changes :)
                        </p>
                    </div>
                    
                )}
            </div>
        </div>
    );
};


export default function Settings() {
    const [showAssistants, setShowAssistants] = useState(false)
    const location = useLocation()
    const [assistants, setAssistants] = useState([]);
    const [show, setShow] = useState(false);
    const [triedDeleting, setTriedDeleting] = useState(false);
    const [selectedAssistant, setSelectedAssistant] = useState(null);
    const [user, setUser] = useState({
        name: '',
        accountEmail: '',
        phone: ''
    });
    const [errors, setErrors] = useState({
        accountEmail: '',
        phone: ''
    });

    /*If you use useLocation() directly in multiple places within your component, you are technically 
    calling the function multiple times. Each call could potentially generate a new object, depending 
    on how the hook is implemented internally in react-router-dom. By calling it once and storing its result,
    you ensure that the location data is retrieved just once per component render, and you then work with 
    the same data snapshot everywhere in your component. */

    const toggleAssistants = () => {
        setShowAssistants(!showAssistants)
    };

    const handleClose = () => {
        setShow(false)
        console.log('setting show false')
    };

    const handleShow = (assistant) => {
        setShow(true);
        setSelectedAssistant(assistant);
    };


    useEffect(() => {
        const getAssistants = async () => {
            const token = localStorage.getItem('userToken');
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/get-not-locked`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                })
                setAssistants(response.data)
            } catch (error) {
                console.error("error getting assistant", error)
            }
        }

        const getUser = async () => {
            const token = localStorage.getItem('userToken');
            let userId = null;
            
            try {
                if (token) {
                    try {
                        const decodedToken = jwtDecode(token);
                        userId = decodedToken.id;
                        console.log(decodedToken.id)
                    } catch (error) {
                        console.error('Failed to decode token:', error);
                    }
                }
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/get-user/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                })
                setUser(response.data)
            } catch (error) {
                console.error("error getting assistant", error)
            }
        }
        getAssistants();
        getUser();
        
    }, [])

    const handleDelete = async () => {
        const token = localStorage.getItem('userToken');
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.id;
        
        try {
            console.log("deleting assistant now from handle delete!!!!!")
            console.log('selectedAssistant:', selectedAssistant);
            //added to fetch subscription id for the update subscription controller upon deletion
            const userResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/get-user/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            // Check if the user details are successfully retrieved
            if (userResponse.status !== 200) {
                console.error('Failed to fetch user details');
                return;
            }

            const user = userResponse.data;
            console.log('here is the user:', user);
            const subscriptionId = user.stripeSubscriptionId; // Ensure this field exists in your User model

            if (!subscriptionId) {
                console.error('No subscriptionId found for user');
                return;
            }


            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/delete/${selectedAssistant._id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                setAssistants(assistants.filter(a => a._id !== selectedAssistant._id)); // Update list
            } else {
                console.error('Failed to delete assistant');
            }

            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/subtract-assistant`, {
                userId: userId,
                subscriptionId: subscriptionId
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        } catch (error) {
            console.error('Trouble deleting -StatsCard.js:', error);
        }
    };



    const handleBillingPortal = async () => {
        const token = localStorage.getItem('userToken');
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.id;
        
        const returnUrl = window.location.origin + '/settings';

        try {

            const userResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/get-user/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            console.log("userResponse:", userResponse)
            console.log("customerId:", userResponse.data.stripeCustomerId)

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/create-customer-portal-session`, {
                customerId: userResponse.data.stripeCustomerId,
                returnUrl
            });

            window.location.href = response.data.url; // Redirect to the Stripe customer portal
        } catch (error) {
            console.error('Failed to create customer portal session:', error);
        }
    };

    const handleUpdateContactDetails = async (e) => {
        e.preventDefault(); // Prevent default form submission

        const token = localStorage.getItem('userToken');
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.id;

        try {
            // Check if email or phone is already taken by another user
            const checkResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/check-email-phone`, {
                accountEmail: user.accountEmail,
                phone: user.phone,
                userId // Send userId to exclude current user from the check
            });

            console.log('Check Response:', checkResponse.data); // Debugging log

            if (checkResponse.data.emailTaken) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    accountEmail: 'Email is already taken',
                }));
                console.log('Email is taken'); // Debugging log
                return; // Prevent update if email is taken
            }

            if (checkResponse.data.phoneTaken) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    phone: 'Phone number is already taken',
                }));
                console.log('Phone is taken'); // Debugging log
                return; // Prevent update if phone is taken
            }

            // If no errors, proceed to update
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/users/update/${userId}`, {
                name: user.name,
                accountEmail: user.accountEmail,
                phone: user.phone
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('response:', response);
            setErrors({}); // Clear errors on successful update
        } catch (error) {
            console.error('Failed to update user:', error);
        }
    };


    return (
        <div className='flex bg-[#161616] h-screen'>
            {show && (
                <Modal
                    show={show}
                    handleClose={handleClose}
                    handleDelete={handleDelete}
                />
            )}
            <div>
                <SideBar />
            </div>
            <div className='flex flex-col w-full mt-3 -ml-3 overflow-hidden p-3 rounded-2xl bg-[#eeece7]'>
                <HeaderMain />
                <div className='flex flex-col h-full pb-20 mr-3 w-full p-4 overflow-auto rounded-2xl bg-[#eeece7]'>
                    <div className="w-full ">
                        <form className="mt-10 pb-5 mb-10 bg-[#f5f4f0] rounded-2xl px-4 pt-8 lg:mt-0">
                            <div className=''>
                                <p className='text-md font-semibold '>Contact Details</p>
                                <p className='text-sm mb-4'>This is for your assistants to provide to renters if necessary.</p>
                                <div className='mb-2 -mt-1'>
                                    {errors.accountEmail && <p className="text-red-500 text-xs mt-1">{errors.accountEmail}</p>}
                                    {errors.phone && <p className="text-red-500 text-xs mt-1">{errors.phone}</p>}
                                </div>
                                <div className='flex space-x-3'>
                                    <div className="relative flex items-center w-full">
                                        <FaUserAstronaut size={18} className="absolute ml-5" />
                                        <input
                                            className="pl-12 appearance-none rounded-2xl h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
                                            id="full-name"
                                            type="text"
                                            placeholder="Full Name"
                                            value={user.name}
                                            onChange={(e) => setUser({ ...user, name: e.target.value })}
                                        />
                                        
                                    </div>
                                    <div className="relative flex items-center w-full">
                                        <HiOutlineMail size={18} className="absolute ml-5" />
                                        <input
                                            className="pl-12 appearance-none rounded-2xl h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
                                            id="email"
                                            type="text"
                                            placeholder="Email "
                                            value={user.accountEmail}
                                            onChange={(e) => setUser({ ...user, accountEmail: e.target.value })}
                                        />
                                    </div>
                                    <div className="relative flex items-center w-full">
                                        <SlScreenSmartphone size={18} className="absolute ml-5" />
                                        <input
                                            className="pl-12 appearance-none rounded-2xl h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
                                            id="phone-number"
                                            type="text"
                                            placeholder="Phone Number "
                                            value={user.phone}
                                            onChange={(e) => setUser({ ...user, phone: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button type='submit' onClick={handleUpdateContactDetails} class=" mt-6 mb-2 rounded-full w-40 bg-gray-900 px-6 py-3 font-medium text-white">Update</button>
                        </form>
                    </div>


                    <div className="mt-10 pb-5 mb-10 bg-[#f5f4f0] rounded-2xl px-4 pt-8 lg:mt-0">
                        <div className=''>
                            <p className='text-md font-semibold '>Update Billing Details</p>
                            <p className='text-sm mb-4'>Manage your billing details through Stripe's customer portal.</p>
                            <div className='flex items-center '>
                                <button onClick={handleBillingPortal} className='rounded-full mb-2 w-40 bg-gray-900 px-6 py-3 font-medium text-white'>
                                    Update Billing
                                </button>
                            </div>
                        </div>
                        {/* <button class=" mt-6 mb-2 rounded-full w-40 bg-gray-900 px-6 py-3 font-medium text-white">Update</button> */}
                    </div>

                    {/* Payment Details */}
                    {/* <div class="w-full ">
                        <div class="mt-10 bg-[#f5f4f0] rounded-2xl px-4 pt-8 lg:mt-0">
                            <p class="text-xl font-medium">Payment Details</p>
                            <div class="">
                                <label for="email" class="mt-4 mb-2 block text-sm font-medium">Email</label>
                                <div class="relative">
                                    <input type="text" id="email" name="email" class="w-full rounded-2xl  px-4 py-3 pl-11 text-sm  outline-none focus:z-10 focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50" placeholder="your.email@gmail.com" />
                                    <div class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                                        </svg>
                                    </div>
                                </div>
                                <label for="card-holder" class="mt-4 mb-2 block text-sm font-medium">Card Holder</label>
                                <div class="relative">
                                    <input type="text" id="card-holder" name="card-holder" class="w-full rounded-2xl  px-4 py-3 pl-11 text-sm uppercase  outline-none focus:z-10 focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50" placeholder="Your full name here" />
                                    <div class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                                        </svg>
                                    </div>
                                </div>
                                <label for="card-no" class="mt-4 mb-2 block text-sm font-medium">Card Details</label>
                                <div class="flex space-x-3">
                                    <div class="relative w-7/12 flex-shrink-0">
                                        <input type="text" id="card-no" name="card-no" class="w-full rounded-2xl  px-2 py-3 pl-11 text-sm  outline-none focus:z-10 focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50" placeholder="xxxx-xxxx-xxxx-xxxx" />
                                        <div class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                                            <svg class="h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z" />
                                                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <input type="text" name="credit-expiry" class="w-full rounded-2xl  px-2 py-3 text-sm  outline-none focus:z-10 focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50" placeholder="MM/YY" />
                                    <input type="text" name="credit-cvc" class="w-1/6 flex-shrink-0 rounded-2xl  px-2 py-3 text-sm  outline-none focus:z-10 focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50" placeholder="CVC" />
                                </div>
                                <label for="billing-address" class="mt-4 mb-2 block text-sm font-medium">Billing Address</label>
                                <div class="flex flex-col sm:flex-row space-x-3">
                                    <div class="relative flex-shrink-0 sm:w-7/12">
                                        <input type="text" id="billing-address" name="billing-address" class="w-full rounded-2xl  px-4 py-3 pl-11 text-sm  outline-none focus:z-10 focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50" placeholder="Street Address" />
                                        <div class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                                            <img class="h-4 w-4 object-contain" src="https://flagpack.xyz/_nuxt/4c829b6c0131de7162790d2f897a90fd.svg" alt="" />
                                        </div>
                                    </div>
                                    <select type="text" name="billing-state" class="w-full rounded-2xl px-4 py-3 text-sm  outline-none focus:z-10 focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50">
                                        <option value="State">State</option>
                                    </select>
                                    <input type="text" name="billing-zip" class="flex-shrink-0 rounded-2xl  px-4 py-3 text-sm  outline-none sm:w-1/6 focus:z-10 focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50" placeholder="ZIP" />
                                </div>

                            </div>
                            <button class="mt-6 mb-6 rounded-full w-40 bg-gray-900 px-6 py-3 font-medium text-white">Update</button>
                        </div>
                    </div> */}

                    <button onClick={toggleAssistants} className="mt-6 mb-6 ml-auto rounded-full flex space-x-2 items-center w-fit bg-gray-900 px-6 py-3 font-medium text-white">
                        <p>Manage Assistants</p>
                        {!showAssistants ? <FaChevronDown /> : <FaChevronUp />}
                    </button>

                    {
                        !showAssistants ? null :
                        <div className='flex flex-col space-y-6'>
                                {assistants.map(assistant => (
                                    <StatsCard handleShow={handleShow} key={assistant._id} assistant={assistant} />
                                ))}
                        </div>
                        
                    }

                </div>
            </div>
        </div>
    );
}
