import React, { useEffect, useState, useRef } from 'react';
import { FiTrash2 } from "react-icons/fi";
import { IoIosDownload } from "react-icons/io";
import { MdOutlineQuestionAnswer } from "react-icons/md";
import { IoIosStar } from "react-icons/io";
import { CiCircleQuestion } from "react-icons/ci";
import { FiMessageSquare } from "react-icons/fi";
import { TbHomeStats } from "react-icons/tb";
import axios from 'axios';
import { storage } from '../config/firebase'; // Import your Firebase storage configuration
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import '../css/random.css';
import { IoCopyOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';

export default function AssistantDetails({ assistant, onShowQRCode, showModal }) {
    const statsRef = useRef();
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);
    const numOfReviews = assistant.reviewsOfProperty.length;
    const numUnanswered = assistant.unansweredQuestions.length;
    const [errors, setErrors] = useState()

    useEffect(() => {
        // Initialize the files state with assistant's knowledge base
        if (assistant.knowledgeBase) {
            setFiles(assistant.knowledgeBase);
        }
    }, [assistant]);

    // Handle file change
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileRef = ref(storage, `${assistant._id}/knowledge_base/${file.name}`);
            await uploadBytes(fileRef, file);
            const fileURL = await getDownloadURL(fileRef);

            const fileRecord = {
                fileName: file.name,
                fileSize: file.size,
                knowledgeFileURL: fileURL,
            };

            setFiles([...files, fileRecord]);
        }
    };

    const removeFile = (fileName) => {
        setFiles(files.filter(file => file.fileName !== fileName));
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    // Function to update knowledge base
    const updateKnowledgeBase = async () => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/assistants/firebase-update/${assistant._id}`;
        const payload = {
            knowledgeBaseFiles: files.map(file => ({
                fileName: file.fileName,
                knowledgeFileURL: file.knowledgeFileURL,
            })),
        };

        const token = localStorage.getItem('userToken');

        try {
            const response = await axios.put(url, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            console.log('Knowledge base updated:', response.data);
        } catch (error) {
            console.error('Failed to update knowledge base:', error.response?.data || error.message);
            setErrors(error.message)
        }
    };



    //cors issue
    // Function to handle downloading the QR Code
    const handleDownloadQRCode = async () => {
        console.log('Downloading QR Code from:', assistant.qrCodeURL); // Log the URL to verify

        try {
            const response = await fetch(assistant.qrCodeURL, {
                mode: 'cors',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = `${assistant.assistantName}_QRCode.png`;
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading QR Code:', error);
        }
    };

    //opens image in browser
    // const handleDownloadQRCode = () => {
    //     console.log('Downloading QR Code from:', assistant.qrCodeURL); // Log the URL to verify

    //     // Create a link and set the URL using the QR code URL directly
    //     const link = document.createElement('a');
    //     link.href = assistant.qrCodeURL;
    //     link.download = `${assistant.assistantName}_QRCode.png`;

    //     // Append the link to the document body and trigger a click
    //     document.body.appendChild(link);
    //     link.click();

    //     // Remove the link from the document
    //     document.body.removeChild(link);
    // };

    // same cors error
    // const handleDownloadQRCode = async () => {
    //     console.log('Downloading QR Code from:', assistant.qrCodeURL); // Log the URL to verify

    //     try {
    //         const response = await fetch(assistant.qrCodeURL, {
    //             mode: 'cors',
    //         });

    //         if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //         }

    //         const blob = await response.blob();
    //         const url = window.URL.createObjectURL(blob);

    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.download = `${assistant.assistantName}_QRCode.png`;
    //         document.body.appendChild(link);
    //         link.click();

    //         // Clean up
    //         document.body.removeChild(link);
    //         window.URL.revokeObjectURL(url);
    //     } catch (error) {
    //         console.error('Error downloading QR Code:', error);
    //     }
    // };

    // Function to handle viewing the CSV
    const handleViewCsv = () => {
        // Open the generated CSV file in a new tab
        window.open(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/${assistant._id}/view-unanswered-questions`, '_blank');
    };


    return (
        <div>
            <div className='pl-6 flex-col space-y-3'>
                <div className='mb-7 flex flex-col flex-wrap'>
                    <p className='mb-2 text-md font-semibold'>Knowledge</p>
                    <p className='text-sm mb-4'>Manage Your assistant's knowledge.</p>
                    <div className='flex space-x-3 items-center mb-4'>
                        <button
                            onClick={triggerFileInput}
                            className="w-40 rounded-2xl bg-white hover:bg-[#f4ccc9] text-sm font-semibold py-2 px-4"
                        >
                            Upload Files
                        </button>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            className="hidden"
                        />
                        <button
                            onClick={updateKnowledgeBase}
                            className="w-40 rounded-2xl bg-white hover:bg-[#f4ccc9] text-sm font-semibold py-2 px-4"
                        >
                            Save Changes
                        </button>
                        {errors ? <p className="text-red-500 mb-2 text-sm" >File cannot be empty</p> : null}
                    </div>
                    <div className='grid grid-cols-3 gap-4'>
                        {files.map(file => (
                            <div key={file.fileName} className="flex group relative items-center w-80 bg-white justify-between p-2 space-x-3 rounded-2xl hover:bg-[#f4ccc9]">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" fill="none" className="h-10 w-10 flex-shrink-0" width="36" height="36"><rect width="36" height="36" rx="6" fill="#FF5588"></rect><path d="M19.6663 9.66663H12.9997C12.5576 9.66663 12.1337 9.84222 11.8212 10.1548C11.5086 10.4673 11.333 10.8913 11.333 11.3333V24.6666C11.333 25.1087 11.5086 25.5326 11.8212 25.8451C12.1337 26.1577 12.5576 26.3333 12.9997 26.3333H22.9997C23.4417 26.3333 23.8656 26.1577 24.1782 25.8451C24.4907 25.5326 24.6663 25.1087 24.6663 24.6666V14.6666L19.6663 9.66663Z" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M19.667 9.66663V14.6666H24.667" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M21.3337 18.8334H14.667" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M21.3337 22.1666H14.667" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M16.3337 15.5H15.5003H14.667" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                <span className="flex-1 truncate">{file.fileName} - {Math.round(file.fileSize / 1024)} KB</span>
                                <button onClick={() => removeFile(file.fileName)} className="opacity-0 group-hover:opacity-100">
                                    <FiTrash2 />
                                </button>
                            </div>
                        ))}
                    </div>
                    
                </div>
            </div>

            <div className='flex flex-wrap  pl-6 pr-3 justify-between mb-7'>
                <div className='bg-[#f5f4f0] rounded-2xl p-5 mb-4'>
                    <p className='mb-2 text-md font-semibold'>Renter Feedback</p>
                    <div className='flex items-center space-x-3'>
                        <div className="relative flex items-center ">
                            <TbHomeStats size={24} className="absolute ml-5" />
                            <div className="bg-[#f4ccc9] flex items-center pl-12 truncate appearance-none rounded-2xl h-12 leading-tight ">
                                <p className=' pl-2 mr-5 font-bold text-2xl'>{numOfReviews}</p>
                            </div>
                        </div>
                        {/* <p className='p-1 rounded-2xl px-3 py-2 pt-3 bg-white h-12 inline-block hover:bg-[#f4ccc9]'>view →</p> */}
                        <div className='bg-white p-3 rounded-full hover:bg-[#f4ccc9] h-12 transition duration-100'>
                            <IoIosDownload size={24} />
                        </div>
                    </div>
                </div>

                <div className='bg-[#f5f4f0] rounded-2xl p-5 mb-4'>
                    <p className='mb-2 text-md font-semibold'>Unanswered Questions</p>
                    <div className='flex items-center space-x-3'>
                        <div className="relative flex items-center ">
                            <MdOutlineQuestionAnswer size={24} className="absolute ml-5" />
                            <div className="bg-[#f4ccc9] flex items-center pl-12 truncate appearance-none rounded-2xl h-12 leading-tight ">
                                <p className=' pl-2 mr-5 font-bold text-2xl'>{numUnanswered}</p>
                            </div>
                        </div>
                        {/* <button onClick={handleViewCsv} className='p-1 rounded-2xl px-3 py-2 pt-3 bg-white inline-block h-12 hover:bg-[#f4ccc9]'>view →</button> */}
                        <button onClick={handleViewCsv} className='bg-white p-3 rounded-full hover:bg-[#f4ccc9] h-12 transition duration-100'>
                            <IoIosDownload size={24} />
                        </button>
                    </div>
                </div>

                <div className='bg-[#f5f4f0] rounded-2xl p-5 mb-4'>
                    <p className='mb-2 text-md font-semibold'>Total Texts</p>
                    <div className='flex items-center space-x-3'>
                        <div className="relative flex items-center ">
                            <FiMessageSquare size={24} className="absolute ml-5" />
                            <div className="bg-[#f4ccc9] flex items-center pl-12 truncate appearance-none rounded-2xl h-12 leading-tight ">
                                <p className=' pl-2 mr-5 font-bold text-2xl'>{assistant.totalTextsSent}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='bg-[#f5f4f0] rounded-2xl p-5 mb-4'>
                    <p className='mb-2 text-md font-semibold'>Assistant Rating</p>
                    <div className='flex items-center space-x-3'>
                        <div className="relative flex items-center ">
                            <div className="bg-[#f4ccc9] flex items-center pl-5 truncate appearance-none rounded-2xl h-12 leading-tight ">
                                <p className='flex space-x-1 mr-5'>
                                    <IoIosStar />
                                    <IoIosStar />
                                    <IoIosStar />
                                    <IoIosStar />
                                    <IoIosStar />
                                </p>
                            </div>
                        </div>
                        {/* added z index to tooltip to fix depth issue. */}
                        <div className="tooltip-container z-20">
                            <span className="text"><CiCircleQuestion size={24} style={{ strokeWidth: .5 }} /></span>
                            <span className="tooltip z-20">This is the rating your renters are giving the assistant you have created for this property</span>
                        </div>
                    </div>
                </div>

                <div className='bg-[#f5f4f0] rounded-2xl p-5 mb-4'>
                    <p className='mb-2 text-md font-semibold'>QR Code</p>
                    <div className='flex items-center space-x-3'>
                        <button onClick={() => onShowQRCode(assistant.qrCodeURL)} className='p-1 rounded-2xl px-3 py-2 bg-white inline-block h-12 hover:bg-[#f4ccc9]'>view →</button>
                        <div onClick={handleDownloadQRCode} className='bg-white p-3 rounded-full hover:bg-[#f4ccc9] h-12 transition duration-100'>
                            <IoIosDownload size={24} />
                        </div>
                    </div>
                </div>

                <div className='bg-[#f5f4f0] rounded-2xl p-5 mb-4'>
                    <p className='mb-2 text-md font-semibold'>Deployment Phone Number</p>
                    <div className='flex items-center space-x-3'>
                        <div className='bg-[#f4ccc9] rounded-2xl p-3'>
                            <p className='text-[20px] font-bold'>{assistant.deploymentPhoneNumber}</p>
                            {/* {
                                showModal ? <p className='text-[20px] font-bold'>{assistant.deploymentPhoneNumber}</p>
                                : <p className='text-[20px] font-bold'>+1 (***) ****-****</p>
                            } */}
                            
                        </div>
                        <div className='bg-white rounded-lg hover:bg-[#f4ccc9] p-3 h-12 transition duration-100'>
                            <IoCopyOutline size={24} />
                        </div>
                        
                    </div>
                </div>

            </div>
        </div>
    );
}


