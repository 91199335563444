import React, { useRef, lazy, Suspense } from 'react';

import { Helmet } from 'react-helmet'; // Added for managing meta tags
import LandingHeader from '../components/LandingHeader';
import FAQSection from '../components/FAQSection';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../assets/logo.png';
import '../css/random.css';

import { LazyBackgroundImage, LazyLoadedImage } from '../components/LazyImages';






export default function LandingPage() {
    const navigate = useNavigate();

    const aboutRef = useRef(null);
    const pricesRef = useRef(null);
    const faqRef = useRef(null);

    const handleGhost = async () => {
        // e.preventDefault();
        console.log('running handler');
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/ghost`);
            console.log('ghost:', response);
            const { userId } = response.data;

            localStorage.setItem('userToken', response.data.userToken);
            console.log('Token stored:', localStorage.getItem('userToken'));

            sessionStorage.setItem('ghostId', userId);
            navigate("/create-assistant", { state: { from: '/landing-page' } });
        } catch (error) {
            console.error('error with ghost:', error);
        }
    };


    return (
        <>
            {/* Added Helmet for managing meta tags */}
            <Helmet>
                <title>GuestLink AI - Your Short Term Rental AI Concierge</title>
                <meta name="description" content="Enhance your guest's experience with GuestLink, a 24/7 AI concierge tailored to your property. Provide instant answers and save time managing your short-term rental." />
                <meta name="keywords" content="AI concierge, short-term rental, guest experience, property management" />
                <link rel="canonical" href="https://guestlinkai.com" />
            </Helmet>

            {/* Wrapped content in semantic HTML elements */}
            <header className='px-4'>
                <LandingHeader refs={{ aboutRef, pricesRef, faqRef }} />
            </header>

            <main className='flex flex-col px-4 pt-2 justify-center items-center overflow-auto '>
                {/* Hero Section */}
                <section className='relative rounded-[50px] w-full h-[550px] text-white mb-12'>
                    <LazyBackgroundImage
                        name="landingHouse"
                        className="p-6 flex flex-col rounded-[50px] w-full h-[550px] text-white"
                    >
                        <h1 className='text-[65px] leading-[70px] font-bold w-96 z-10'>
                            GuestLink
                        </h1>
                        <p className='text-[50px] leading-[60px] font-bold w-96 mb-4 z-10'>
                            Your Short Term Rental AI
                        </p>
                        <p className='text-sm font-medium w-96 z-10'>
                            Enhance your guest's experience and take your time back with your own 24/7 AI concierge tailored to your property. Your guest's personal guide to your property and the surrounding area, providing instant answers to all their questions.
                        </p>
                        <div className="flex mt-auto justify-end w-full items-center">
                            <p className="flex flex-col z-10 ">
                                <div className='flex items-center justify-around -mb-3'>
                                    <LazyLoadedImage name="airbnb" alt="Airbnb" style={{ width: '100px', height: '65px', color: 'white' }} />
                                    <LazyLoadedImage name="vrbo" alt="Vrbo" style={{ width: '100px', height: '65px', color: 'white' }} />
                                </div>
                            </p>
                        </div>
                    </LazyBackgroundImage>
                    <div className='absolute inset-0 bg-black opacity-15 object-cover rounded-[50px] w-full h-[550px]'></div>
                </section>

                {/* Features Section */}
                <section className='flex px-6 items-center justify-between mb-6 w-full'>
                    <div>
                        <h2 className='font-medium text-4xl w-96'>How GuestLink Works For You</h2>
                    </div>
                    <p className='w-[600px]'>
                        GuestLink ensures peace of mind with our 24/7 AI support line.
                        Upload your renter guidebook to create an AI concierge, accessible by SMS phone number.
                        Your Renters ask questions and receive instant answers about your property and local area,
                        enhancing their stay while freeing you from routine inquiries.
                    </p>
                </section>

                {/* Feature Cards */}
                <section className='flex justify-between p-5 mb-5 w-full'>
                    <article className='w-[400px]'>
                        <div className='w-[400px] h-[275px] rounded-[40px] overflow-hidden mb-4'>
                            <LazyLoadedImage
                                name="swimming"
                                alt="Woman relaxing in a swimming pool"
                                className='w-full h-full object-cover'
                            />
                        </div>
                        <h3 className='font-medium text-3xl mb-2'>Time-Saving Automation</h3>
                        <p>
                            Delegate routine inquiries to GuestLink and save valuable time. Instead of contacting you,
                            your renters text the AI concierge to get instant answers.
                            This allows you to focus on delivering an exceptional experience and managing your property with ease.
                        </p>
                    </article>

                    <article className='w-[400px]'>
                        <div className='w-[400px] h-[275px] rounded-[40px] overflow-hidden mb-4'>
                            <LazyLoadedImage
                                name="beachCar"
                                alt="Aerial view of a beach with a vehicle"
                                className='w-full h-full object-cover'
                            />
                        </div>
                        <h3 className='font-medium text-3xl mb-2'>A Better Travel Experience</h3>
                        <p>
                            Enhance the travel experience for your guests with GuestLink.
                            Our AI concierge provides quick and accurate information about your property and the local area,
                            ensuring a stress-free and enjoyable stay.
                            Make your property stand out by offering unparalleled support and convenience for your guests.
                        </p>
                    </article>

                    <article className='w-[400px]'>
                        <div className='w-[400px] h-[275px] rounded-[40px] overflow-hidden mb-4'>
                            <LazyLoadedImage
                                name="img3"
                                alt="Triangle forest house"
                                className='w-full h-full object-cover'
                            />
                        </div>
                        <h3 className='font-medium text-3xl mb-2'>Collect Renter Feedback</h3>
                        <p>
                            See what your renters are saying.
                            Your AI concierge can prompt your guests for feedback before they leave a public review.
                            This allows you to address any issues promptly and gain valuable insights into your guests' experiences,
                            helping you continuously enhance the quality of your property and service.
                        </p>
                    </article>
                </section>

                {/* About Section */}
                <section ref={aboutRef} className='flex px-6 justify-between mb-8 w-full'>
                    <div>
                        <h2 className='font-medium text-4xl w-96'>Simple and Straightforward Setup</h2>
                        <div className='flex space-x-10 items-center mt-8 ml-4'>
                            <button onClick={handleGhost} className='landing-glow-button'>Try It Now</button>
                            <Link to={"/register"} className='border-2 border-black px-4 py-2 rounded-full'>
                                Login or Sign Up
                            </Link>
                        </div>
                    </div>
                    <div className='w-[650px]'>
                        <div className='flex flex-wrap justify-between mb-5 items-start'>
                            <div className='flex flex-col items-center justify-center mb-3'>
                                <div className='w-[150px] h-[150px] rounded-full overflow-hidden mb-2'>
                                    <LazyLoadedImage name="luxuryAirbnb" alt="Luxury Airbnb" className='object-cover object-center w-full h-full' />
                                </div>
                                <p className='w-40 font-medium text-sm text-center'>1. Quickly create your GuestLink account from this page.</p>
                            </div>
                            <div className='flex flex-col items-center justify-center mb-3'>
                                <div className='w-[150px] h-[150px] rounded-full overflow-hidden mb-2'>
                                    <LazyLoadedImage name="computer" alt="Computer" className='object-cover object-center w-full h-full' />
                                </div>
                                <p className='w-40 font-medium text-sm text-center'>2. Upload your renter guidebook to easily generate your custom AI concierge.</p>
                            </div>
                            <div className='flex flex-col items-center justify-center mb-3'>
                                <div className='w-[150px] h-[150px] rounded-full overflow-hidden mb-2'>
                                    <LazyLoadedImage name="stretching" alt="Stretching" className='object-cover object-center w-full h-full' />
                                </div>
                                <p className='w-40 font-medium text-sm text-center'>3. Receive a unique phone number and QR code for your AI assistant.</p>
                            </div>
                        </div>

                        <div className='flex flex-wrap justify-between'>
                            <div className='flex flex-col items-center justify-center mb-3'>
                                <div className='w-[150px] h-[150px] rounded-full overflow-hidden mb-2'>
                                    <LazyLoadedImage name="checkIn" alt="Check-in" className='object-cover object-center w-full h-full' />
                                </div>
                                <p className='w-40 font-medium text-sm text-center'>4. Provide the phone number or QR code to your renters during check-in.</p>
                            </div>
                            <div className='flex flex-col items-center justify-center mb-3'>
                                <div className='w-[150px] h-[150px] rounded-full overflow-hidden mb-2'>
                                    <LazyLoadedImage name="texting" alt="Texting" className='object-cover object-center w-full h-full' />
                                </div>
                                <p className='w-40 font-medium text-sm text-center'>5. Renters text the number to ask questions and receive instant answers.</p>
                            </div>
                            <div className='flex flex-col items-center justify-center mb-3'>
                                <div className='w-[150px] h-[150px] rounded-full overflow-hidden mb-2'>
                                    <LazyLoadedImage name="pool" alt="Pool" className='object-cover object-center w-full h-full' />
                                </div>
                                <p className='w-40 font-medium text-sm text-center'>6. Use your dashboard to add or delete assistants and monitor their performance.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Pricing Section */}
                <section ref={pricesRef} className='flex flex-col px-6 mb-10 w-full'>
                    <div className='flex items-center justify-between mb-8 w-full'>
                        <div>
                            <h2 className='font-medium text-4xl w-96'>A 24/7 AI Support Line for your Guests</h2>
                        </div>
                        <p className='w-[600px] ml-40'>
                            Provide your guests with 24/7 AI support for your property,
                            ensuring they have instant access to information and assistance.
                        </p>
                    </div>

                    <div className='flex justify-between w-full'>
                        <div className='flex flex-col space-y-5 w-full h-full'>
                            <div className='space-y-1 w-full ring-1 ring-gray-300 p-4 rounded-2xl'>
                                <p className='font-semibold text-2xl'>Pricing</p>
                                <p className='text-lg'>$30.00/mo</p>
                                <p className='text-sm text-gray-500'>Create each AI assistant for just $30.00 per month</p>
                                <p className='text-sm text-gray-500'>Enjoy easy cancellation with no additional fees, and manage your assistants effortlessly from your dashboard.</p>
                            </div>

                            <div className='space-y-1 w-full ring-1 ring-gray-300 p-4 rounded-2xl'>
                                <p className='font-semibold text-2xl'>Benefits</p>
                                <p className='text-sm text-gray-500'>Each assistant is deployed to an SMS phone number and comes with a scannable QR code for easy accessibility by your renters.</p>
                                <p className='text-sm text-gray-500'>Access detailed analytics on each assistant to see what your renters are saying about your property and view any unanswered questions.</p>
                            </div>

                            <div className='space-y-1 w-full ring-1 ring-gray-300 p-4 rounded-2xl'>
                                <p className='font-semibold text-2xl'>Try It out First</p>
                                <p className='text-sm text-gray-500'>You can try creating your own assistant before giving out any billing information! Or <Link to={"/register"} className='hover:underline text-sky-500'>create your account</Link> today and get two weeks free!</p>
                            </div>

                            <button onClick={handleGhost} className="group group-hover:before:duration-500 group-hover:after:duration-500 after:duration-500 hover:border-rose-300 hover:before:[box-shadow:_20px_20px_20px_30px_#a21caf] duration-500 before:duration-500 hover:duration-500 underline underline-offset-2 hover:after:-right-8 hover:before:right-12 hover:before:-bottom-8 hover:before:blur hover:underline hover:underline-offset-4 origin-left hover:decoration-2 relative bg-white h-16 w-64 border border-gray-300 text-left p-3 text-gray-800 text-base font-bold rounded-lg overflow-hidden before:absolute before:w-12 before:h-12 before:content[''] before:right-1 before:top-1 before:z-10 before:bg-violet-500 before:rounded-full before:blur-lg after:absolute after:z-10 after:w-20 after:h-20 after:content[''] after:bg-rose-300 after:right-8 after:top-3 after:rounded-full after:blur-lg">
                                Try It Out!
                            </button>
                        </div>

                        <div className='relative rounded-[50px] w-[1900px] h-[470px] text-white m-5 -mr-5 -mt-1'>
                            <LazyLoadedImage
                                name="mexicoHouse"
                                alt="Beautiful vacation house"
                                className='w-full h-full object-cover  rounded-[50px]'
                            />
                            {/* <div className='absolute inset-0 bg-black opacity-15 object-cover rounded-[50px] w-full h-full'></div> */}
                        </div>
                    </div>
                </section>


                {/* FAQ Section */}
                <section ref={faqRef}>
                    <FAQSection />
                </section>
            </main>

            {/* Footer */}
            <footer className='flex flex-col w-full h-[400px] justify-center px-16 py-10 space-y-7 bg-[#f5f4f0]'>
                <div className='flex justify-between items-center h-full'>
                    <a href="/" className="logo flex items-center space-x-2 px-4 h-16 mt-3 mb-5">
                        <span className="text-black flex items-center text-2xl font-bold space-x-3 ">
                            <img src={logo} alt="GuestLink Logo" className='h-12 w-12 -ml-8' />
                            <p className='mb-2 -ml-4 text-3xl'>GuestLink</p>
                        </span>
                    </a>
                    <p className='text-2xl font-bold w-96 text-end'>Streamline your AirBNB Management Today</p>
                </div>
                <div className='flex justify-between pb-10 border-neutral-500 border-b-2 h-full'>
                    <div className='flex items-center space-x-10 font-light'>
                        <div className='space-y-2'>
                            <p>Email</p>
                            <p>hello@guestlinkai.com</p>
                        </div>
                        <div className='space-y-2'>
                            <p>Phone</p>
                            <p>+1 (408) 256-2545</p>
                        </div>
                    </div>
                    <div className='flex space-x-10 items-center'>
                        <button onClick={handleGhost} className='landing-glow-button'>Try It Now</button>
                        <Link to={"/register"} className='border-2 border-black px-4 py-2 rounded-full'>
                            Login or Sign Up
                        </Link>
                    </div>
                </div>
                <div className='flex justify-between font-light h-full text-sm '>
                    <div className='flex items-center space-x-5'>
                        <p>© 2023 GuestLink LLC.</p>
                        <p>All Rights Reserved</p>
                    </div>
                    <div className='flex space-x-8 items-center'>
                        <a
                            href='https://docs.google.com/document/d/e/2PACX-1vSxC7ZSjTLYfp_ZHB9Rna7kzz-10u2wl74pjZxZqbslaNAZKbxkP22UoJEVeOrQvalbF1HkAzZI0Ah3/pub'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privicy Policy
                        </a>
                        <a
                            href='https://docs.google.com/document/d/e/2PACX-1vR-N20f116EB2zHCuKWhBC6GpwFzpj-rZyUe5Rtp3MZaEnrmudBGStzB-JqZIK0kiPhw1obUGu08xyu/pub'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Terms of Service
                        </a>
                        <a
                            href='https://docs.google.com/document/d/e/2PACX-1vQnitjHjZ6fhtCcRGZxKyHWOOl0hiU9lhYhSpSke26MQ5Wmpf0jmJHbqGNP6iTnflrYD09gLdXaSWqT/pub'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Our Twilio Opt-in Disclosures
                        </a>
                    </div>
                </div>
            </footer>

            <div className='w-full h-[15px] self-center bg-[#FF5A5F]'>
                <p> </p>
            </div>
        </>
    )
}



























//OLD LANDING PAGE LAYOUT NOT OPTIMIZED FOR SEO REVERT IF NEEDED.

    // return (
    //     <div>
    //         <div className='flex flex-col px-4 pt-2 justify-center items center overflow-auto'>
    //             <LandingHeader refs={{ aboutRef, pricesRef, faqRef }} />
    //             <div className='relative rounded-[50px] w-full h-[550px] text-white mb-12 '>
    //                 <div className="bg-landing-image p-6 flex flex-col rounded-[50px] w-full h-[550px] text-white  object-cover">
    //                     <p className='text-[65px] leading-[70px] font-bold w-96 z-10'>
    //                         GuestLink
    //                     </p>
    //                     <p className='text-[50px] leading-[60px] font-bold w-96 mb-4 z-10'>
    //                         Your Short Term Rental AI
    //                     </p>
    //                     <p className='text-sm font-medium w-96 z-10'>
    //                         Enhance your guest's experience and take your time back with your own 24/7 AI concierge tailored to your property. Your guest's personal guide to your property and the surrounding area, providing instant answers to all their questions.
    //                     </p>
    //                     <div className="flex mt-auto justify-end w-full items-center">
    //                         <p className="flex flex-col z-10 ">
    //                             <div className='flex items-center justify-around -mb-3'>
    //                                 <img src={airbnb} alt="Airbnb" style={{ width: '100px', height: '65px', color: 'white' }} />
    //                                 <img src={vrbo} alt="Vrbo" style={{ width: '100px', height: '65px', color: 'white' }} />
    //                             </div>
    //                             {/* <button onClick={handleGhost} class="group group-hover:before:duration-500 group-hover:after:duration-500 after:duration-500 hover:border-rose-300 hover:before:[box-shadow:_20px_20px_20px_30px_#a21caf] duration-500 before:duration-500 hover:duration-500 underline underline-offset-2 hover:after:-right-8 hover:before:right-12 hover:before:-bottom-8 hover:before:blur hover:underline hover:underline-offset-4  origin-left hover:decoration-2 hover:text-rose-300 relative bg-neutral-800 h-16 w-64 border text-left p-3 text-gray-50 text-base font-bold rounded-lg  overflow-hidden  before:absolute before:w-12 before:h-12 before:content[''] before:right-1 before:top-1 before:z-10 before:bg-violet-500 before:rounded-full before:blur-lg  after:absolute after:z-10 after:w-20 after:h-20 after:content['']  after:bg-rose-300 after:right-8 after:top-3 after:rounded-full after:blur-lg">
    //                                 Try It!
    //                             </button> */}
    //                         </p>
    //                     </div>
    //                 </div>
    //                 <div className='absolute inset-0 bg-black opacity-15 object-cover rounded-[50px] w-full h-[550px]'></div>
    //             </div>
    //             <div className='flex px-6 items-center justify-between mb-6'>
    //                 <div>
    //                     <p className='text-gray-600'>Premium Features</p>
    //                     <p className='font-medium text-4xl w-96'>How GuestLink Works For You</p>
    //                 </div>
    //                 <p className='w-[600px] '>
    //                     GuestLink ensures peace of mind with our 24/7 AI support line. 
    //                     Upload your renter guidebook to create an AI concierge, accessible by SMS phone number. 
    //                     Your Renters ask questions and receive instant answers about your property and local area, 
    //                     enhancing their stay while freeing you from routine inquiries.
    //                 </p>
    //             </div>

    //             <div className='flex justify-between p-5 mb-5'>
    //                 <div className='w-[400px]'>
    //                     <div className='w-[400px] h-[275px] rounded-[40px] overflow-hidden mb-4'>
    //                         <img src={swimming} className='w-full h-full object-cover' />
    //                     </div>
    //                     <p className='font-medium text-3xl mb-2'>Time-Saving Automation</p>
    //                     <p>
    //                         Delegate routine inquiries to GuestLink and save valuable time. Instead of contacting you, 
    //                         your renters text the AI concierge to get instant answers. 
    //                         This allows you to focus on delivering an exceptional experience and managing your property with ease.
    //                     </p>
    //                 </div>
    //                 <div className='w-[400px]'>
    //                     <div className='w-[400px] h-[275px] rounded-[40px] overflow-hidden mb-4'>
    //                         <img src={beachCar} className='w-full h-full object-cover' />
    //                     </div>
    //                     <p className='font-medium text-3xl mb-2'>A Better Travel Experience</p>
    //                     <p>
    //                         Enhance the travel experience for your guests with GuestLink. 
    //                         Our AI concierge provides quick and accurate information about your property and the local area, 
    //                         ensuring a stress-free and enjoyable stay. 
    //                         Make your property stand out by offering unparalleled support and convenience for your guests.
    //                     </p>
    //                 </div>
    //                 <div className='w-[400px]'>
    //                     <div className='w-[400px] h-[275px] rounded-[40px] overflow-hidden mb-4'>
    //                         <img src={img3} className='w-full h-full object-cover' />
    //                     </div>
    //                     <p className='font-medium text-3xl mb-2'>Collect Renter Feedback</p>
    //                     {/*Your AI concierge can prompt your guests for feedback at checkout*/}
    //                     <p>
    //                         See what your renters are saying. 
    //                         Your AI concierge can prompt your guests for feedback before they leave a public review. 
    //                         This allows you to address any issues promptly and gain valuable insights into your guests' experiences, 
    //                         helping you continuously enhance the quality of your property and service.
    //                     </p>
    //                 </div>
    //             </div>
                
    //             <div ref={aboutRef} className='flex px-6 justify-between mb-8 '>
    //                 <div>
    //                     <p className='text-gray-600'>How to use it</p>
    //                     <p className='font-medium text-4xl w-96'>Simple and Straitforward Setup</p>
    //                     <div className='flex space-x-10 items-center mt-8 ml-4'>
    //                         <button onClick={handleGhost} className='landing-glow-button'>Try It Now</button>
    //                         <Link to={"/register"} className='border-2 border-black px-4 py-2 rounded-full'>
    //                             Login or Sign Up
    //                         </Link>
    //                     </div>
    //                 </div>
    //                 <div className='w-[650px]'>
    //                     <div className='flex flex-wrap justify-between mb-5 items-start'>
    //                         <div className='flex flex-col items-center justify-center mb-3'>
    //                             <div className='w-[150px] h-[150px] rounded-full overflow-hidden mb-2'>
    //                                 <img src={luxuryAirbnb} className='object-cover object-center w-full h-full' />
    //                             </div>
    //                             <p className='w-40 font-medium text-sm text-center'>1. Quickly create your GuestLink account from this page.</p>
    //                         </div>
    //                         <div className='flex flex-col items-center justify-center mb-3'>
    //                             <div className='w-[150px] h-[150px] rounded-full overflow-hidden mb-2'>
    //                                 <img src={computer} className='object-cover object-center w-full h-full' />
    //                             </div>
    //                             <p className='w-40 font-medium text-sm text-center'>2. Upload your renter guidebook to easily generate your custom AI concierge.</p>
    //                         </div>
    //                         <div className='flex flex-col items-center justify-center mb-3'>
    //                             <div className='w-[150px] h-[150px] rounded-full overflow-hidden mb-2'>
    //                                 <img src={stretching} className='object-cover object-center w-full h-full' />
    //                             </div>
    //                             <p className='w-40 font-medium text-sm text-center'>3. Receive a unique phone number and QR code for your AI assistant.</p>
    //                         </div>
    //                     </div>

    //                     <div className='flex flex-wrap justify-between'>
    //                         <div className='flex flex-col items-center justify-center mb-3'>
    //                             <div className='w-[150px] h-[150px] rounded-full overflow-hidden mb-2'>
    //                                 <img src={checkIn} className='object-cover object-center w-full h-full' />
    //                             </div>
    //                             <p className='w-40 font-medium text-sm text-center'>4. Provide the phone number or QR code to your renters during check-in.</p>
    //                         </div>
    //                         <div className='flex flex-col items-center justify-center mb-3'>
    //                             <div className='w-[150px] h-[150px] rounded-full overflow-hidden mb-2'>
    //                                 <img src={texting} className='object-cover object-center w-full h-full' />
    //                             </div>
    //                             <p className='w-40 font-medium text-sm text-center'>5. Renters text the number to ask questions and receive instant answers.</p>
    //                         </div>
    //                         <div className='flex flex-col items-center justify-center mb-3'>
    //                             <div className='w-[150px] h-[150px] rounded-full overflow-hidden mb-2'>
    //                                 <img src={pool} className='object-cover object-center w-full h-full' />
    //                             </div>
    //                             <p className='w-40 font-medium text-sm text-center'>6. Use your dashboard to add or delete assistants and monitor their performance.</p>
    //                         </div>

    //                     </div>
    //                 </div>
    //             </div>

    //             <div ref={pricesRef} className='flex px-6 items-center justify-betwee mb-8'>
    //                 <div>
    //                     <p className='text-gray-600'>Our Pricing</p>
    //                     <p className='font-medium text-4xl w-96'>A 24/7 AI Support Line for your Guests</p>
    //                 </div>
    //                 {/* do responsive version for pg positioning. */}
    //                 <p className='w-[600px] ml-40'>
    //                     Provide your guests with 24/7 AI support for your property, 
    //                     ensuring they have instant access to information and assistance.
    //                 </p>
    //             </div>

    //             <div className='flex px-6 justify-between mb-10'>
    //                 <div className='flex flex-col space-y-5 w-full h-full'>
    //                     <div className='space-y-1 w-full ring-1 ring-gray-300 p-4 rounded-2xl'>
    //                         <p className='font-semibold text-2xl'>Pricing</p>
    //                         <p className='text-lg'>$30.00/mo</p>
    //                         <p className='text-sm text-gray-500'>Create each AI assistant for just $30.00 per month</p>
    //                         <p className='text-sm text-gray-500'>Enjoy easy cancellation with no additional fees, and manage your assistants effortlessly from your dashboard.</p>
    //                     </div>

    //                     <div className='space-y-1 w-full ring-1 ring-gray-300 p-4 rounded-2xl'>
    //                         <p className='font-semibold text-2xl'>Benefits</p>
    //                         <p className='text-sm text-gray-500'>Each assistant is deployed to an SMS phone number and comes with a scannable QR code for easy accesibility by your renters.</p>
    //                         <p className='text-sm text-gray-500'>Access detailed analytics on each assistant to see what your renters are saying about your property and view any unanswered questions.</p>

    //                     </div>

    //                     <div className='space-y-1 w-full ring-1 ring-gray-300 p-4 rounded-2xl'>
    //                         <p className='font-semibold text-2xl'>Try It out First</p>
    //                         <p className='text-sm text-gray-500'>You can try creating your own assistant before giving out any billing information! Or <Link to={"/register"} className='hover:underline text-sky-500'>create your account</Link> today and get two weeks free!</p>
    //                     </div>

    //                     {/* <button class="group group-hover:before:duration-500 group-hover:after:duration-500 after:duration-500 hover:border-rose-300 hover:before:[box-shadow:_20px_20px_20px_30px_#a21caf] duration-500 before:duration-500 hover:duration-500 underline underline-offset-2 hover:after:-right-8 hover:before:right-12 hover:before:-bottom-8 hover:before:blur hover:underline hover:underline-offset-4  origin-left hover:decoration-2 hover:text-rose-300 relative bg-neutral-800 h-16 w-64 border text-left p-3 text-gray-50 text-base font-bold rounded-lg  overflow-hidden  before:absolute before:w-12 before:h-12 before:content[''] before:right-1 before:top-1 before:z-10 before:bg-violet-500 before:rounded-full before:blur-lg  after:absolute after:z-10 after:w-20 after:h-20 after:content['']  after:bg-rose-300 after:right-8 after:top-3 after:rounded-full after:blur-lg">
    //                         Try It!
    //                     </button> */}
    //                     <button onClick={handleGhost} class="group group-hover:before:duration-500 group-hover:after:duration-500 after:duration-500 hover:border-rose-300 hover:before:[box-shadow:_20px_20px_20px_30px_#a21caf] duration-500 before:duration-500 hover:duration-500 underline underline-offset-2 hover:after:-right-8 hover:before:right-12 hover:before:-bottom-8 hover:before:blur hover:underline hover:underline-offset-4 origin-left hover:decoration-2 relative bg-white h-16 w-64 border border-gray-300 text-left p-3 text-gray-800 text-base font-bold rounded-lg overflow-hidden before:absolute before:w-12 before:h-12 before:content[''] before:right-1 before:top-1 before:z-10 before:bg-violet-500 before:rounded-full before:blur-lg after:absolute after:z-10 after:w-20 after:h-20 after:content[''] after:bg-rose-300 after:right-8 after:top-3 after:rounded-full after:blur-lg">
    //                         Try It Out!
    //                     </button>

    //                 </div>

    //                 <div className='relative rounded-[50px] w-[1900px] h-[470px] text-white m-5 -mr-5 -mt-1'>
    //                     <div className="bg-house-image p-6 flex flex-col rounded-[50px] w-full h-[470px] text-white  object-cover">
    //                         {/* <p className='text-sm text-center font-medium w-96 z-10 '>
    //                             GuestLink helps you promote your listings by answering questions about your properties and providing valuable information to prospective buyers.
    //                         </p> */}
    //                     </div>
    //                     <div className='absolute inset-0 bg-black opacity-15 object-cover rounded-[50px] w-full h-full'></div>
    //                 </div>

    //             </div>
    //             <div ref={faqRef}>
    //                 <FAQSection />
    //             </div>
    //         </div>

    //         <div className='flex flex-col w-full h-[400px] justify-center px-16 py-10 space-y-7 bg-[#f5f4f0]'>
    //             <div className='flex justify-between items-center h-full'>
    //                 <a href="/" className="logo flex items-center space-x-2 px-4 h-16 mt-3 mb-5">
    //                     <span className="text-black flex items-center text-2xl font-bold space-x-3 ">
    //                         <img src={logo} alt="GuestLink Logo" className='h-12 w-12 -ml-8' />
    //                         <p className='mb-2 -ml-4 text-3xl'>GuestLink</p>
    //                     </span>
    //                 </a>
    //                 <p className='text-2xl font-bold w-96 text-end'>Streamline your AirBNB Management Today</p>
    //             </div>
    //             <div className='flex justify-between pb-10 border-neutral-500 border-b-2 h-full'>
    //                 <div className='flex items-center space-x-10 font-light'>
    //                     <div className='space-y-2'>
    //                         <p>Email</p>
    //                         <p>hello@guestlinkai.com</p>
    //                     </div>
    //                     <div className='space-y-2'>
    //                         <p>Phone</p>
    //                         <p>+1 (408) 256-2545</p>
    //                     </div>
    //                 </div>
    //                 <div className='flex space-x-10 items-center'>
    //                     <button onClick={handleGhost} className='landing-glow-button'>Try It Now</button>
    //                     <Link to={"/register"} className='border-2 border-black px-4 py-2 rounded-full'>
    //                         Login or Sign Up
    //                     </Link>
    //                 </div>
    //             </div>
    //             <div className='flex justify-between font-light h-full text-sm '>
    //                 <div className='flex items-center space-x-5'>
    //                     <p>© 2023 GuestLink LLC.</p>
    //                     <p>All Rights Reserved</p>
    //                 </div>
    //                 <div className='flex space-x-8 items-center'>
    //                     <a
    //                         href='https://docs.google.com/document/d/e/2PACX-1vSxC7ZSjTLYfp_ZHB9Rna7kzz-10u2wl74pjZxZqbslaNAZKbxkP22UoJEVeOrQvalbF1HkAzZI0Ah3/pub'
    //                         target="_blank"
    //                         rel="noopener noreferrer"
    //                     >
    //                         Privicy Policy
    //                     </a>
    //                     <a
    //                         href='https://docs.google.com/document/d/e/2PACX-1vR-N20f116EB2zHCuKWhBC6GpwFzpj-rZyUe5Rtp3MZaEnrmudBGStzB-JqZIK0kiPhw1obUGu08xyu/pub'
    //                         target="_blank"
    //                         rel="noopener noreferrer"
    //                     >
    //                         Terms of Service
    //                     </a>
    //                     <a
    //                         href='https://docs.google.com/document/d/e/2PACX-1vQnitjHjZ6fhtCcRGZxKyHWOOl0hiU9lhYhSpSke26MQ5Wmpf0jmJHbqGNP6iTnflrYD09gLdXaSWqT/pub'
    //                         target="_blank"
    //                         rel="noopener noreferrer"
    //                     >
    //                         Our Twilio Opt-in Disclosures
    //                     </a>
    //                 </div>
    //             </div>
    //         </div>

    //         <div className='w-full h-[15px] self-center bg-[#fb6a55]'>
    //             <p> </p>
    //         </div>

    //     </div>
    // )



























