import React, { useState, useRef, useEffect } from 'react';
import SideBar from '../components/SideBar';
import HeaderMain from '../components/HeaderMain';
import { IoCloudUploadOutline } from "react-icons/io5";
import { HiOutlineMail } from "react-icons/hi";
import { SlScreenSmartphone } from "react-icons/sl";
import { FaUserAstronaut } from "react-icons/fa6";
import { FaArrowCircleUp } from "react-icons/fa";
import { RiRobot3Line } from "react-icons/ri";
import { BsHouse } from "react-icons/bs";
import { FiTrash2 } from "react-icons/fi";
import axios from 'axios';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { IoCopyOutline } from "react-icons/io5";
import '../css/random.css'
import { isGhostUser } from '../services/ghostCheck';
import { IoIosClose } from "react-icons/io";
import '../css/random.css'
import { CiCircleCheck } from "react-icons/ci";
// Import Firebase storage functions
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../config/firebase"; // Adjust the path to your firebase.js
import '../css/random.css'
import { GiPartyPopper } from "react-icons/gi";
import ReactMarkdown from 'react-markdown';
import { Link } from 'lucide-react';
import { CiCircleQuestion } from "react-icons/ci";

// import { get } from 'mongoose'; //where tf is this from?

import "../css/random.css";

import { jwtDecode } from 'jwt-decode';


import { auth } from '../config/firebase';
import { authenticateWithFirebase } from '../services/firebaseAuth';


import { loadStripe } from '@stripe/stripe-js';
import { stripePublicKey } from '../config/stripe';

const stripePromise = loadStripe(stripePublicKey);

// Get the token from local storage
const userToken = localStorage.getItem('userToken');
console.log('User token from localStorage:', userToken);

let decodedToken = null;

if (userToken) {
    try {
        decodedToken = jwtDecode(userToken);
        console.log('Decoded token:', decodedToken);
    } catch (error) {
        console.error("Failed to decode token:", error);
    }
} else {
    console.error("No token found in localStorage.");
}


const Modal = ({ show, onClose, triedDeleting, setTriedDeleting, handleDelete, isGhost }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    if (!show) return null;

    const onDelete = async () => {
        setIsDeleting(true);
        const MINIMUM_SPINNER_TIME = 3000;

        const spinnerTimeout = new Promise(resolve => setTimeout(resolve, MINIMUM_SPINNER_TIME));
        const deletePromise = handleDelete();

        try {
            await Promise.all([spinnerTimeout, deletePromise]);
            setIsDeleted(true);
        } catch (error) {
            console.error('Error during delete:', error);
            setIsDeleting(false);
        }
    };

    return (
        <div className="fixed inset-0 z-20 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="flex flex-col w-[600px] items-center justify-center bg-[#eeece7] px-4 py-8 rounded-3xl shadow-lg">
                <div className='flex justify-between w-full mb-5 -mt-3'>
                    <div className='rounded-full p-2 bg-[#f4ccc9]'>
                        <RiRobot3Line size={24} />
                    </div>
                    {isDeleted && (
                        <button onClick={onClose}>
                            <IoIosClose size={36} />
                        </button>
                    )}
                </div>
                {!isDeleting && !isDeleted && (
                    <>
                        <p className='font-semibold text-lg'>Your virtual concierge has been successfully created!</p>
                        <div className='flex space-x-3 items-center my-2 p-3 rounded-3xl'>
                            <p className='text-[40px] font-bold'>+1 (408) 858 ****</p>
                            <div className='bg-[#eeece7] rounded-lg hover:bg-[#f4ccc9] p-4'>
                                <IoCopyOutline size={24} />
                            </div>
                        </div>
                    </>
                )}
                {!isDeleted ? (
                    <>
                        {isDeleting ? (
                            <div className='mb-10'>
                                <p className='font-semibold text-lg mb-5'>Deleting Your Assistant...</p>
                                <div className='bounce'></div>
                            </div>
                        ) : (
                            <>
                                {triedDeleting === false ? (
                                    <p className='text-sm text-center'>Your assistant can be accessed by texting this number and will be shown in your dashboard once you register for an account. If you would like to use our service, we ask you to compensate us for our work :)</p>
                                ) : (
                                    <p className='text-sm text-center'>Are you sure you want to delete? After deleting, your work is permanently lost and your phone number will be inaccessible. <span className='font-bold text-blue-400'>Try a two week free trial instead.</span> We will notify you before your card is charged.</p>
                                )}
                                <div className='flex items-center space-x-[350px]'>
                                    <button
                                        onClick={() => {
                                            if (!triedDeleting) {
                                                setTriedDeleting(true)
                                            } else {
                                                onDelete()
                                            }
                                        }}
                                        disabled={isDeleting}
                                        className="mt-4 w-[100px] p-2 bg-red-500 text-white rounded-full"
                                    >
                                        {triedDeleting ? `Yes Delete` : 'Delete'}
                                    </button>
                                    <NavLink
                                        to={'/reg-g'}
                                        className="mb-2 mt-6 text-md font-semibold create-button mr-3"
                                    >
                                        try it
                                    </NavLink>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <div className='flex flex-col space-y-3'>
                        <p className='font-semibold text-lg text-center text-green-600 mb-5'>
                            Assistant deleted successfully!
                        </p>
                        <p className='font-semibold text-center w-80 mb-6'>
                                Remember, you can allways try your assistants before you buy in the box on the right, feel free to play around. Come back soon!
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};




const Modal2 = ({ show, onClose, isGhost }) => {
    const location = useLocation();
    if ((location.state?.from === '/register' && show) || (location.state?.from === '/landing-page' && isGhost && show)) 

    return (
        <div className={`fixed inset-0 z-20 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-500 ${show ? 'opacity-100' : 'opacity-0'}`}>
            <div className="flex flex-col w-[600px] items-center justify-center bg-[#eeece7] px-4 py-8 rounded-3xl shadow-lg">
                <div className='flex justify-between w-full mb-5 -mt-3'>
                    <div className='rounded-full p-2 bg-[#f4ccc9]'>
                        <RiRobot3Line size={24} />
                    </div>
                    <button onClick={onClose} className=''>
                        <IoIosClose size={36} />
                    </button>
                </div>
                <div>
                    <p className='font-semibold mb-3 text-lg text-center'>
                        {/* Hello there... Welcome to GuestLink! */}
                        Hey there new friend... Welcome to GuestLink!
                    </p>
                    <div className='flex space-x-3 items-center text-center mb-8'>
                        <p>Here you can create a virtual assistant for your property to help your guests receive instant answers and get your time back...</p>
                    </div>
                    <div className='w-full space-y-4 text-sm flex flex-col mb-3'>
                        {/* <div className='flex'>
                            <p>{`Add any files with info on your property for the assistant to answer questions from. (Remember your assistant is only as good as what it knows, see how to improve your property documents here...)`}</p>
                        </div> */}
                        <div className='flex space-x-2 items-center'>
                            <CiCircleCheck size={24} style={{ strokeWidth: 1 }} />
                            <p>Create your AI assistant by filling out the feilds on this page.</p>
                        </div>
                        <div className='flex space-x-2 items-center'>
                            <CiCircleCheck size={24} style={{ strokeWidth: 1 }} />
                            <p>Add any files with info on your property for the assistant to answer questions from.</p>
                        </div>
                        <div className='flex space-x-2 items-center'>
                            <CiCircleCheck size={24} style={{ strokeWidth: 1 }} />
                            <p>Save changes and play with the interactive demo before you create.</p>
                        </div>
                        <div className='flex space-x-2 items-center'>
                            <CiCircleCheck size={24} style={{ strokeWidth: 1 }} />
                            <p>Publish and give your renters QR code and phone number to text your assistant and receive help.</p>
                        </div>
                    </div>
                    <button onClick={onClose} className='rounded-md ring-2 mt-6 ring-slate-700 bg-[#f4ccc9] p-2 w-full'>
                        Lets Go!
                    </button>
                </div>
            </div>
        </div>
    );
    else {
        return null
    }
};



const OutOfTwilioModal = ({ show, onClose, setTwilioList, twilioList, assistantHasTwilio}) => {

    if (show && assistantHasTwilio === false)

        return (
            <div className={`fixed inset-0 z-20 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-500 ${show ? 'opacity-100' : 'opacity-0'}`}>
                <div className="flex flex-col w-[600px] items-center justify-center bg-[#eeece7] px-4 py-8 rounded-3xl shadow-lg">
                    <div className='flex justify-between w-full mb-5 -mt-3'>
                        <div className='rounded-full p-2 bg-[#f4ccc9]'>
                            <RiRobot3Line size={24} />
                        </div>
                        <button onClick={onClose} className=''>
                            <IoIosClose size={36} />
                        </button>
                    </div>
                    <div>
                        <p className='font-semibold mb-3 text-lg text-center'>
                            {/* Hello there... Welcome to GuestLink! */}
                            So sorry, due to elevated demand we are out of available numbers at the moment.
                        </p>
                        <div className='flex flex-col space-y-3 items-center text-center mb-8'>
                            <p>We have added you to our que and will receive an email when we have phone numbers back in stock. Typical wait time is 24 hours.</p>
                            <p>Thank you for your patience.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    else {
        return null
    }
};


export default function CreateAssistant() {
    const fileInputRef = useRef(null);
    const coverPhotoInputRef = useRef(null);
    const [isGhost, setIsGhost] = useState(false);
    const [numberOfSaves, setNumberOfSaves] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [timer, setTimer] = useState(null);
    const [errors, setErrors] = useState({});
    const [twilioList, setTwilioList] = useState([]);
    const [showTwilioModal, setShowTwilioModal] = useState(false);
    
    const [files, setFiles] = useState([]);
    const [coverPhoto, setCoverPhoto] = useState('');
    const [name, setName] = useState('');
    const [assistantName, setAssistantName] = useState('');
    const [propertyAddress, setPropertyAddress] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [ical, setIcal] = useState('');


    const navigate = useNavigate();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const userToken = localStorage.getItem("userToken")
    const [triedDeleting, setTriedDeleting] = useState(false);
    const [createdAssistant, setCreatedAssistant] = useState();

    const [assistantId, setAssistantId] = useState('');
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [openAiAssistantId, setOpenAiAssistantId] = useState('');
    const [threadId, setThreadId] = useState(null);
    const [showCheckmark, setShowCheckmark] = useState(false);
    const [hasBilling, setHasBilling] = useState();
    const [isRunActive, setIsRunActive] = useState(false);

    const [assistantHasTwilio, setAssistantHasTwilio] = useState(false);
    const [selectedColor, setSelectedColor] = useState('');
    const defaultColors = ['#e5def6', '#ffdfe7', '#d9e2f7', '#f7d9d9'];

    const [hideImageUpload, setHideImageUpload] = useState(false)

    // helping to debug 403 error on different browsers after registration, adding multiple retries to authenticateWithFirebase
    useEffect(() => {
        console.log('CreateAssistant component mounted');
        const jwtToken = localStorage.getItem('userToken');
        console.log('JWT Token exists:', !!jwtToken);

        if (jwtToken && !auth.currentUser) {
            console.log('JWT exists but no Firebase user, re-authenticating');
            authenticateWithFirebase(jwtToken);
        } else if (auth.currentUser) {
            console.log('Firebase user already authenticated:', auth.currentUser.uid);
        } else {
            console.log('No JWT token and no Firebase user');
        }
    }, []);






    useEffect(() => {
        console.log('component is mounting');
        const checkIsGhost = () => {
            const ghostStatus = isGhostUser();
            setIsGhost(ghostStatus);
            console.log('isGhost set to:', ghostStatus);
        };
        checkIsGhost();
    }, []);


    //open modal if && !user
    useEffect(() => {
        if (location.state?.from === '/creating-assistant' && !isGhost && !triedDeleting) {
            setShowModal(true);
            console.log("Creating first assistant");
        }
    }, [location, user, triedDeleting]);

    useEffect(() => {
        // Delay setting showModal2 to ensure isGhost state has been updated
        setTimeout(() => {
            if (location.state?.from === '/register' || (location.state?.from === '/landing-page' && isGhost)) {
                console.log('Should show Modal2, are they a ghost:', isGhost, 'from:', location.state?.from);
                setShowModal2(true);
                setHideImageUpload(true)
                console.log('setting hide image upload true')
            }
        }, 100); // Adjust the delay as needed
    }, [location, isGhost]);



    const handleCloseModal2 = () => {
        setShowModal2(false);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    // const handleCoverPhotoChange = (event) => {
    //     event.preventDefault();
    //     const file = event.target.files[0];
    //     if (file) {
    //         setCoverPhoto(URL.createObjectURL(file));
    //     }
    // };

    const handleFileChange = (event) => {
        event.preventDefault();
        const selectedFiles = Array.from(event.target.files);
        setFiles([...files, ...selectedFiles]);
    };

    const removeFile = (fileName) => {
        setFiles(files.filter(file => file.name !== fileName));
        URL.revokeObjectURL(fileName);
    };

    const triggerFileInput = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    //delete functionality:
    const handleDelete = async () => {
        const token = localStorage.getItem('userToken');
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/delete/${createdAssistant._id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('response from delete after creation:', response)
        } catch (error) {
            console.error('Trouble deleting -createAssistant&DeleteModal:', error);
        }
    };

    //needed to pass id to stripe on first assistant create dont fuck with it its working
    useEffect(() => {
        const token = localStorage.getItem('userToken');
        let userId = null;

        if (token) {
            const decodedToken = jwtDecode(token);
            userId = decodedToken.id;
            console.log(decodedToken.id);
        }

        const getAssistants = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/get`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    }
                });
                setCreatedAssistant(response.data[0]);
            } catch (error) {
                console.error("error getting assistant", error);
            }
        };

        const getUser = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/get-user/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                console.log(response.data);
                setHasBilling(response.data.hasBillingDetails);
            } catch (error) {
                console.error("error getting user", error);
            }
        };

        if (userId) {
            getUser();
            }
        getAssistants();
    }, []);





    const handleUpdateWithFirebase = async (e, source) => {
        const token = localStorage.getItem('userToken');
        let coverImageURL = null;
        let responseFiles = [];
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/find-one/${assistantId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            console.log("found assistant to update by id:",response.data)
            coverImageURL = response.data.coverImageURL
            responseFiles = response.data.knowledgeBase
            console.log('responseFiles:', responseFiles)
        } catch (error) {
            console.error("error getting assistant", error)
        }

        const url = `${process.env.REACT_APP_BACKEND_URL}/api/assistants/firebase-update/${assistantId}`;

        const payload = {
            assistantName: assistantName,
            contactDetailsEmail: email,
            contactDetailsPhone: phone,
            coverImageURL: coverImageURL,
            propertyAddress: propertyAddress,
            contactDetailsName: name,
            knowledgeBaseFiles: responseFiles.map(file => ({
                fileName: file.fileName,
                knowledgeFileURL: file.knowledgeFileURL,
            })),
        };

        try {
            const response = await axios.put(url, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            console.log('Assistant updated:', response.data);

            // setNumberOfSaves(prev => prev + 1);
            // if (source === 'finalCreate') {
            //     navigate('/creating-assistant');
            // } else {
            //     //logic to trigger css loader display changes saved
            // }
            const updatedAssistant = response.data.assistantDetails;
            const hasTwilio = !!updatedAssistant.deploymentPhoneNumber;

            return hasTwilio;
        } catch (error) {
            console.error('Failed to update assistant:', error.response?.data || error.message);
        }
    };


    //new Create with firebase and image upload trying to remove file disk thing
    const handleFirebaseCreate = async (source, color) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/assistants/firebase`;
        console.log('running firebase create')
        console.log('handleFirebaseCreate called with source:', source, 'and color:', color);

        const payload = {
            assistantName: assistantName || 'GuestLink',
            iCalLink: ical || null,
            contactDetailsEmail: email,
            contactDetailsPhone: phone,
            propertyAddress,
            contactDetailsName: name,
            userId: null,
            coverImageURL: '',
            backgroundColor: color,
            knowledgeBaseFiles: []
        };

        const token = localStorage.getItem('userToken');

        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                payload.userId = decodedToken.id;
            } catch (error) {
                console.error('Failed to decode token:', error);
            }
        }

        try {
            // Upload cover photo to Firebase Storage
            if (coverPhoto) {
                const coverPhotoRef = ref(storage, `${payload.userId}/cover_photos/${coverPhoto.name}`);
                await uploadBytes(coverPhotoRef, coverPhoto);
                const coverPhotoURL = await getDownloadURL(coverPhotoRef);
                payload.coverImageURL = coverPhotoURL;
            }
            else if (color) {
                payload.backgroundColor = color;
            }

            // Upload knowledge base files to Firebase Storage
            const fileUploadPromises = Array.from(files).map(async file => {
                const fileRef = ref(storage, `${payload.userId}/${file.name}`);
                await uploadBytes(fileRef, file);
                const downloadURL = await getDownloadURL(fileRef);
                return { fileName: file.name, knowledgeFileURL: downloadURL };
            });

            const uploadedFiles = await Promise.all(fileUploadPromises);
            payload.knowledgeBaseFiles = uploadedFiles;

            // Debug logs to verify payload content
            console.log('Payload:', payload);

            const response = await axios.post(url, payload, {
                headers: token ? { Authorization: `Bearer ${token}` } : {}
            });

            console.log('Assistant created:', response.data);
            setAssistantId(response.data.assistantId);
            // console.log(response.data.assistantId);
            setOpenAiAssistantId(response.data.assistantDetails.openAiAssistantId);
            // console.log('openAiAssistantId:', response.data.assistantDetails.openAiAssistantId);
            sessionStorage.setItem('assistantId', response.data.assistantId);

            try {
                if(hasBilling) {
                    const decodedToken = jwtDecode(token);
                    const userId = decodedToken.id;
                    console.log('userId after has billing check, we are about to update subscription, userId:', userId)

                    const userResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/get-user/${userId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    console.log('we got the user here is the response object', userResponse)

                    const { stripeCustomerId, stripeSubscriptionId } = userResponse.data;
                    console.log('stripeCustomerId:', stripeCustomerId, 'stripeSubscriptionId:', stripeSubscriptionId)

                    //update stripe subscription
                    if (stripeSubscriptionId) {  // Check if stripeSubscriptionId is not undefined
                        // Update stripe subscription
                        const updateResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/add-assistant`, {
                            customerId: stripeCustomerId,
                            subscriptionId: stripeSubscriptionId,
                            userId: userId
                        }, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        });
                        console.log('Subscription updated successfully:', updateResponse.data);
                    } else {
                        console.error('Stripe subscription ID is undefined.');
                    }

                } else {
                    //added to update user on first assistant creation
                    console.log('user has no billing creating first assistant... Updating user details...')
                    const userResponse = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/users/update/${payload.userId}`, {
                        phone: payload.contactDetailsPhone,
                        name: payload.contactDetailsName
                    }, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    });
                    console.log('User updated successfully:', userResponse.data);
                }
            } catch (error) {
                console.error('Failed to update subscription:', error.response?.data || error.message);
            }
            
            const assistantsDeploymentNumber = response?.data?.assistantDetails?.deploymentPhoneNumber;
            console.log('Assistants deployment number:', assistantsDeploymentNumber);
            setAssistantHasTwilio(assistantsDeploymentNumber ? true : false);

            return assistantsDeploymentNumber ? true : false;
        } catch (error) {
            console.error('Failed to create assistant:', error.response?.data || error.message);
            return false; // Return false in case of error
        }
    };


    const handleCoverPhotoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setCoverPhoto(file); // Store the file object
        }
    };

    const handleClickPhoto = () => {
        coverPhotoInputRef.current.click();
    };


    const handleSubmit = async (e, source) => {
        console.log('handleSubmit called with source:', source);
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            console.log('Form validation errors:', formErrors);
            setErrors(formErrors);
            return;
        }
        setIsLoading(true);
        setShowCheckmark(false);

        let color = selectedColor;
        if (!coverPhoto && !color) {
            color = defaultColors[Math.floor(Math.random() * defaultColors.length)];
            setSelectedColor(color);
        }
        console.log('Selected color:', color);

        const MIN_LOADING_TIME = 2000;
        const start = Date.now();

        let hasTwilio = false;
        try {
            console.log('Number of saves:', numberOfSaves);
            if (numberOfSaves === 0) {
                console.log('Creating new assistant');
                hasTwilio = await handleFirebaseCreate(source, color);
            } else {
                console.log('Updating existing assistant');
                hasTwilio = await handleUpdateWithFirebase(source, color);
            }
            console.log('Operation completed. hasTwilio:', hasTwilio);

            setMessages([]);
            setThreadId(null);

            const elapsed = Date.now() - start;
            const remainingTime = MIN_LOADING_TIME - elapsed;

            if (remainingTime > 0) {
                console.log(`Waiting for ${remainingTime}ms to ensure minimum loading time`);
                await new Promise(resolve => setTimeout(resolve, remainingTime));
            }

            setIsLoading(false);
            setShowCheckmark(true);
            setTimeout(() => setShowCheckmark(false), 2000);

            setNumberOfSaves(prev => {
                console.log('Incrementing number of saves. Current:', prev, 'Next:', prev + 1);
                return prev + 1;
            });

            // Navigation logic
            if (source === 'finalCreate') {
                console.log('Attempting to navigate. hasTwilio:', hasTwilio);
                if (hasTwilio) {
                    console.log('Navigating to /creating-assistant');
                    navigate('/creating-assistant');
                } else {
                    console.log('Showing Twilio modal');
                    setShowTwilioModal(true);
                }
            } else {
                console.log('Not navigating because source is not finalCreate');
            }
        } catch (error) {
            console.error('Error in handleSubmit:', error);
            setIsLoading(false);
        }
    };

    // Cleanup timer if the component unmounts
    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timer]);


    const handleCloseTwilioModal = () => {
        setShowTwilioModal(false);
    };




    const sendMessage = async (e) => {
        e.preventDefault();

        // Check if openAiAssistantId is set
        if (!openAiAssistantId) {
            console.error('No assistant ID found');
            setMessages(prevMessages => [
                ...prevMessages,
                { role: 'assistant', content: 'Sorry, please reload the page and save the assistant changes before trying a new assistant' }
            ]);
            return;
        }

        // Display the user message in the chat
        setMessages(prevMessages => [
            ...prevMessages,
            { role: 'user', content: message }
        ]);

        setMessage(''); // Clear the input field

        // Add loading bubble
        setMessages(prevMessages => [
            ...prevMessages,
            { role: 'assistant', isLoading: true }
        ]);

        try {
            const token = localStorage.getItem('userToken');
            console.log('openAiAssistantId:', openAiAssistantId);

            setIsRunActive(true); // Disable the send button

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/message/`, {
                openAiAssistantId: openAiAssistantId,
                message,
                threadId // Pass the current thread ID
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            console.log('Server response:', response.data); // Log the server response

            // Log the full assistant message content
            const assistantMessage = response.data.message;
            console.log('Assistant message:', assistantMessage);

            // Check if response.data.message and response.data.message.content exist
            if (assistantMessage && assistantMessage.content) {
                const assistantMessageContent = assistantMessage.content.map(item => item.text.value).join(' ');
                setMessages(prevMessages => [
                    ...prevMessages.filter(msg => !msg.isLoading), // Remove loading bubble
                    { role: 'assistant', content: assistantMessageContent }
                ]);

                // If threadId was not set before, set it now
                if (!threadId) {
                    setThreadId(response.data.threadId);
                }
            } else {
                console.error('No content returned from assistant');
                setMessages(prevMessages => [
                    ...prevMessages.filter(msg => !msg.isLoading), // Remove loading bubble
                    { role: 'assistant', content: 'Error: No content returned from assistant' }
                ]);
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setMessages(prevMessages => [
                ...prevMessages.filter(msg => !msg.isLoading), // Remove loading bubble
                { role: 'assistant', content: 'Error: Unable to get a response from assistant' }
            ]);
        }

        setIsRunActive(false); // Enable the send button
        setMessage('');
    };




    useEffect(() => {
        console.log('numberOfSaves:', numberOfSaves);
    }, [numberOfSaves]);



    const validateForm = () => {
        let formErrors = {}
        if (files.length === 0) {
            formErrors.knowledgeBase = "Please add file(s) about your property for your assistant's knowledge (e.g., Renter handbook)";
        }

        // if (!coverPhoto) {
        //     formErrors.coverPhoto = "Cover photo is required";
        // }

        // if (!assistantName) formErrors.assistantName = "Assistant name is required";
        if (!name) formErrors.contactDetailsName = "Contact name is required";
        if (!email) formErrors.contactDetailsEmail = "Email is required";
        if (!phone) formErrors.contactDetailsPhone = "Phone number is required";
        if (!propertyAddress) formErrors.propertyAddress = "Property address is required";

        return formErrors;
    }


    return (
        <div className='flex bg-[#161616] h-screen overflow-hidden'>
            {/* Modal components remain unchanged */}
            {!showModal ? null :
                <Modal show={showModal}
                    onClose={handleCloseModal}
                    triedDeleting={triedDeleting}
                    setTriedDeleting={setTriedDeleting}
                    handleDelete={handleDelete}
                    isGhost={isGhost}
                />
            }
            {<Modal2 onClose={handleCloseModal2} isGhost={isGhost} show={showModal2} />}
            {<OutOfTwilioModal onClose={handleCloseTwilioModal} twilioList={twilioList} setTwilioList={setTwilioList} show={showTwilioModal} assistantHasTwilio={assistantHasTwilio} />}

            <div>
                <SideBar />
            </div>

            <div className='flex flex-col w-full overflow-hidden p-3 bg-[#eeece7] mt-3 -ml-3 rounded-t-2xl rounded-b-3xl pb-5'>

                <div className='flex flex-grow overflow-hidden'>
                    <div className='w-3/4 overflow-hidden relative mt-3'>
                    
                        {/* Scrollable form */}
                        <form method="post" encType="multipart/form-data" className='absolute inset-0 flex flex-col overflow-auto p-4 mr-4 bg-[#eeece7]'>
                            {/* Form content remains unchanged */}
                            <p className={`${ hideImageUpload ? 'mb-16 self-center text-lg' : 'mb-4' } font-bold`}>Create Your Assistant</p>
                            {
                                hideImageUpload === false && (
                                <div
                                    className="flex flex-col mb-7 items-center justify-center text-center min-w-40 min-h-40 max-w-40 max-h-40 rounded-full border-2 border-gray-300 border-dashed hover:border-gray-400"
                                    onClick={handleClickPhoto}
                                >
                                    {coverPhoto ? (
                                        <img
                                            src={URL.createObjectURL(coverPhoto)} // Create a URL for preview
                                            alt="Cover Preview"
                                            className="w-full h-full object-cover rounded-full"
                                        />
                                    ) : (
                                        <>
                                            <IoCloudUploadOutline />
                                            <p className="mb-2 text-sm text-gray-500">
                                                <span className="font-semibold">Upload Cover Photo</span>
                                            </p>
                                        </>
                                    )}
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="hidden"
                                        onChange={handleCoverPhotoChange}
                                        ref={coverPhotoInputRef}
                                    />

                                </div>
                                )
                            }

                            {/* {errors.coverPhoto && <p className="text-red-500 text-sm -mt-4 mb-4">{errors.coverPhoto}</p>} */}


                            <div className='mb-7'>
                                <p className='mb-2 text-md font-semibold'>Name</p>
                                {/* {errors.assistantName && <p className="text-red-500 mb-2 text-sm">{errors.assistantName}</p>} */}
                                <div className="relative w-56 flex items-center ">
                                    <RiRobot3Line size={18} className="absolute ml-5" />
                                    <input
                                        className="pl-12 appearance-none rounded-full h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
                                        id="assistant-name"
                                        type="text"
                                        placeholder="Name your assistant"
                                        value={assistantName}
                                        onChange={e => { setAssistantName(e.target.value) }}
                                    />
                                </div>
                            </div>

                            <div className='mb-7'>
                                <div className='flex space-x-3 items-center'>
                                    <p className='mb-2 text-md text-center font-semibold'>Sync Your Renter Calendar </p>
                                    <div className="tooltip-container z-10 mb-2">
                                        <NavLink to={'/ical-instructions'} className="hover:text-blue-400"><CiCircleQuestion size={20} style={{ strokeWidth: .5 }} /></NavLink>
                                        <span className="tooltip z-20">Click here for a quick video on how easily to sync your calendar, or skip for now.</span>
                                    </div>
                                </div>
                                {/* {errors.propertyAddress && <p className="text-red-500 text-sm" >{errors.propertyAddress}</p>} */}
                                <div className="relative w-96 flex items-center ">
                                    <Link size={18} className="absolute ml-5" />
                                    <input
                                        className="pl-12 truncate appearance-none rounded-full h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
                                        id="assistant-name"
                                        type="text"
                                        placeholder="Your Ical Link Here"
                                        value={ical}
                                        onChange={e => { setIcal(e.target.value) }}
                                    />
                                </div>
                            </div>

                            <div className='mb-7'>
                                <p className='text-md font-semibold '>Contact Details <span className='text-[#FF5A5F]'>*</span></p>
                                {(errors.contactDetailsName || errors.contactDetailsEmail || errors.contactDetailsPhone) && <p className="text-red-500 text-sm">Please fill in all feilds</p>}
                                <p className='text-sm mb-4'>This is for the assistant to provide to your renters if necessary.</p>
                                <div className='flex space-x-3'>
                                    <div className="relative flex items-center w-full">
                                        <FaUserAstronaut size={18} className="absolute ml-5" />
                                        <input
                                            className="pl-12 appearance-none rounded-full h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
                                            id="full-name"
                                            type="text"
                                            placeholder="Full Name"
                                            value={name}
                                            onChange={e => { setName(e.target.value) }}
                                        />
                                    </div>
                                    <div className="relative flex items-center w-full">
                                        <HiOutlineMail size={18} className="absolute ml-5" />
                                        <input
                                            className="pl-12 appearance-none rounded-full h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
                                            id="email"
                                            type="text"
                                            placeholder="Email "
                                            value={email}
                                            onChange={e => { setEmail(e.target.value) }}
                                        />
                                    </div>
                                    <div className="relative flex items-center w-full">
                                        <SlScreenSmartphone size={18} className="absolute ml-5" />
                                        <input
                                            className="pl-12 appearance-none rounded-full h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
                                            id="phone-number"
                                            type="text"
                                            placeholder="Phone Number "
                                            value={phone}
                                            onChange={e => { setPhone(e.target.value) }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='mb-7'>
                                <p className='mb-2 text-md font-semibold'>Property Address <span className='text-[#FF5A5F]'>*</span></p>
                                {errors.propertyAddress && <p className="text-red-500 text-sm" >{errors.propertyAddress}</p>}
                                <div className="relative w-96 flex items-center ">
                                    <BsHouse size={18} className="absolute ml-5" />
                                    <input
                                        className="pl-12 truncate appearance-none rounded-full h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
                                        id="assistant-name"
                                        type="text"
                                        placeholder="45 Berryessa Way, Hillsborough, CA"
                                        value={propertyAddress}
                                        onChange={e => { setPropertyAddress(e.target.value) }}
                                    />
                                </div>
                            </div>

                            

                            <div className='mb-24 flex flex-col flex-wrap'>
                                <div className='flex space-x-3 items-center'>
                                    <p className='mb-2 text-md font-semibold'>Knowledge <span className='text-[#FF5A5F]'>*</span></p>
                                    {errors.knowledgeBase && <p className="text-red-500 mb-2 text-sm" >{errors.knowledgeBase}</p>}
                                </div>
                                <p className='text-sm mb-4'>Upload your renter handbook or any info about your property for the assistant to answer questions from.</p>
                                <button
                                    onClick={triggerFileInput}
                                    className="mb-4 rounded-2xl bg-white hover:bg-[#f4ccc9] text-sm font-semibold py-2 px-4"
                                >
                                    Upload Files
                                </button>
                                <input
                                    type="file"
                                    multiple
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                    className="hidden"
                                />
                                <div className='grid grid-cols-2 gap-4'>
                                    {files.map(file => (
                                        <div key={file.name} className="flex group relative items-center w-80 bg-white justify-between p-2 space-x-3 rounded-2xl hover:bg-[#f4ccc9]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" fill="none" className="h-10 w-10 flex-shrink-0" width="36" height="36"><rect width="36" height="36" rx="6" fill="#FF5588"></rect><path d="M19.6663 9.66663H12.9997C12.5576 9.66663 12.1337 9.84222 11.8212 10.1548C11.5086 10.4673 11.333 10.8913 11.333 11.3333V24.6666C11.333 25.1087 11.5086 25.5326 11.8212 25.8451C12.1337 26.1577 12.5576 26.3333 12.9997 26.3333H22.9997C23.4417 26.3333 23.8656 26.1577 24.1782 25.8451C24.4907 25.5326 24.6663 25.1087 24.6663 24.6666V14.6666L19.6663 9.66663Z" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M19.667 9.66663V14.6666H24.667" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M21.3337 18.8334H14.667" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M21.3337 22.1666H14.667" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M16.3337 15.5H15.5003H14.667" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                            <span className="flex-1 truncate">{file.name} - {Math.round(file.size / 1024)} KB</span>
                                            <button onClick={() => removeFile(file.name)} className="opacity-0 group-hover:opacity-100">
                                                <FiTrash2 />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className='mb-7 flex justify-between m-x-3 items-center'>
                                <div className='flex items-center space-x-2 w-80'>
                                    <button
                                        type="button"
                                        className="mb-4 rounded-2xl bg-white hover:bg-[#f4ccc9] text-sm font-semibold py-2 px-4"
                                        onClick={(e) => handleSubmit(e, 'saveChanges')}
                                    >
                                        Save Changes
                                    </button>
                                    {isLoading &&
                                        <div className='flex items-center space-x-2 '>
                                            <span className='loader -mt-4 ml-3'></span>
                                        </div>
                                    }
                                    {showCheckmark &&
                                        <div className="wrapper -mt-4 ml-3">
                                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                            </svg>
                                        </div>
                                    }
                                </div>
                                {numberOfSaves === 0 ? (
                                    <div className="relative inline-block cursor-pointer group">
                                        <button
                                            type="button"
                                            onClick={(e) => handleSubmit(e, 'finalCreate')}
                                            className="mb-2 text-md font-semibold create-button mr-3"
                                        >
                                            Publish
                                        </button>
                                        <span className="text-xs invisible group-hover:visible bg-black text-white text-center rounded-lg py-2 px-4 w-40 absolute z-10 right-full mr-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 top-1/2 transform -translate-y-1/2">
                                            Before you publish, you can save your changes to try your assistant.
                                            <span className="absolute top-1/2 left-full transform -translate-y-1/2 ml-1 w-0 h-0 border-t-4 border-t-transparent border-b-4 border-b-transparent border-l-4 border-l-black"></span>
                                        </span>
                                    </div>
                                ) : (
                                    <button
                                        type="button"
                                        onClick={(e) => handleSubmit(e, 'finalCreate')}
                                        className="mb-2 text text-md font-semibold create-button mr-3"
                                    >
                                        Publish
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>

                    <div className='flex flex-col w-[340px]'>
                        <HeaderMain />
                        {/* Chat component */}
                        <div className="flex flex-col w-[340px] h-full bg-white border-2 border-gray-300 rounded-[40px] overflow-hidden">
                            <div className="flex-1 overflow-auto p-4">
                                {numberOfSaves === 0 ? (
                                    <div className='flex flex-col space-y-20 justify-center items-center mt-40'>
                                        <p className='glow-text text-center text-gray-500 max-w-64'>Fill out the form and save your changes to talk with your assistant.</p>
                                    </div>
                                ) : messages.length === 0 ? (
                                    <div className='flex flex-col space-y-20 justify-center items-center mt-40'>
                                        <p className='glow-text text-center text-gray-500'>Try Your Assistant!</p>
                                        <GiPartyPopper size={80} color='gray' />
                                    </div>
                                ) : (
                                    messages.map((msg, index) => (
                                        <div
                                            key={index}
                                            className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'} mb-2`}
                                        >
                                            <div
                                                className={`${msg.role === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-100 text-black'
                                                    } rounded-3xl p-3 max-w-xs lg:max-w-md`}
                                                style={{ wordBreak: 'break-word', maxWidth: '250px' }}
                                            >
                                                <ReactMarkdown>{msg.content}</ReactMarkdown>
                                                {msg.isLoading &&
                                                    <div className="typing">
                                                        <div className="typing__dot"></div>
                                                        <div className="typing__dot"></div>
                                                        <div className="typing__dot"></div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                            <div className="p-4 border-t-2 border-gray-200">
                                <form className="flex items-center" onSubmit={sendMessage}>
                                    <div className="relative flex-1 mx-2">
                                        <input
                                            type="text"
                                            className="border-2 text-sm border-gray-300 focus:border-transparent rounded-full p-2 w-full pl-4 pr-10 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                                            placeholder="Ask your assistant a question..."
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            disabled={isRunActive || numberOfSaves < 1}
                                        />
                                        <button type="submit" className="absolute inset-y-0 right-0 pr-3 flex items-center text-blue-500" disabled={isRunActive}>
                                            <FaArrowCircleUp size={20} />
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
























//fully working before firebase DONT FUCK WITH, VERY DELICATE, FILE DUPLICATION IN PLACE WITH MULTER WRITE TO DISK
// const handleCreate = async () => {
//     const url = `http://localhost:8000/api/assistants/`;

//     const formData = new FormData();
//     formData.append('assistantName', assistantName);
//     formData.append('contactDetailsEmail', email);
//     formData.append('contactDetailsPhone', phone);
//     formData.append('coverImageURL', coverPhoto);
//     formData.append('propertyAddress', propertyAddress);
//     formData.append('contactDetailsName', name);

//     console.log('State values before FormData:');
//     console.log({ assistantName, name, phone, email, propertyAddress, coverPhoto, files });

//     for (let i = 0; i < files.length; i++) {
//         formData.append('knowledgeBaseFiles', files[i]);
//         formData.append(`knowledgeBaseFileNames[${i}]`, files[i].name); // Append with unique key
//         formData.append(`knowledgeBaseFileSizes[${i}]`, files[i].size); // Append with unique key
//     }

//     // Log FormData
//     for (let pair of formData.entries()) {
//         console.log(pair[0], pair[1]);
//     }

//     const token = localStorage.getItem('userToken');
//     const ghostId = sessionStorage.getItem('ghostId');
//     let userId = null;

//     if (token) {
//         console.log('user token in storage', token)
//         try {
//             const decodedToken = jwtDecode(token);
//             userId = decodedToken.id;
//             console.log('Decoded userId:', userId); // Debug log to ensure userId is decoded correctly
//             formData.append('userId', userId); // Append userId to FormData if it exists
//         } catch (error) {
//             console.error('Failed to decode token:', error);
//         }
//     }

//     try {
//         const response = await axios.post(url, formData, {
//             headers: token ? { Authorization: `Bearer ${token}` } : {}
//         });
//         console.log('Assistant created:', response.data);
//         setAssistantId(response.data.assistantId);
//         sessionStorage.setItem('assistantId', response.data.assistantId)
//         console.log('session storage assistantId:', sessionStorage.getItem("assistantId"))
//         //changed from ghostAssistantId could mess with how the ghost user flow passes to the finish account then stripe page.
//         setNumberOfSaves(prev => prev + 1);
//         navigate('/creating-assistant');

//     } catch (error) {
//         console.log(formData);
//         console.error('Failed to create assistant:', error.response?.data || error.message);
//     }
// };


// {
//     numberOfSaves === 0 ? (
//         <div className='flex flex-col space-y-20 justify-center items-center mt-40'>
//             <p className='text-center text-gray-300'>Fill out the form and save your changes to talk with your assistant.</p>
//         </div>
//     ) : messages.length === 0 ? (
//         <div className='flex flex-col space-y-20 justify-center items-center mt-40'>
//             <p className='text-center text-gray-300'>Try Your Assistant!</p>
//             <GiPartyPopper size={80} color='grey' />
//         </div>
//     ) : (
//     messages.map((msg, index) => (
//         <div key={index} className={`flex items-${msg.role === 'user' ? 'end' : 'start'} justify-${msg.role === 'user' ? 'end' : 'start'} ${msg.role === 'user' ? 'w-64' : 'ml-10'} mb-2`}>
//             <div className={`bg-${msg.role === 'user' ? '[#007AFF]' : 'gray-100'} text-${msg.role === 'user' ? 'white' : 'black'} rounded-3xl p-3 max-w-xs lg:max-w-md`}>
//                 <p>{msg.content}</p>
//                 {/* <p className="text-right text-xs mt-2">09:15</p> Replace with dynamic time */}
//             </div>
//         </div>
//     ))
// )
// }