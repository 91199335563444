import React, { useState, useRef, useEffect } from 'react';
import SideBar from '../components/SideBar';
import HeaderMain from '../components/HeaderMain';
import { IoCloudUploadOutline } from "react-icons/io5";
import { HiOutlineMail } from "react-icons/hi";
import { SlScreenSmartphone } from "react-icons/sl";
import { FaUserAstronaut } from "react-icons/fa6";
import { FaArrowCircleUp } from "react-icons/fa";
import { RiRobot3Line } from "react-icons/ri";
import { BsHouse } from "react-icons/bs";
import { FiTrash2 } from "react-icons/fi";
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Import Firebase functions
import { GiPartyPopper } from "react-icons/gi";
import ReactMarkdown from 'react-markdown';
import { Link } from 'lucide-react';
import { CiCircleQuestion } from "react-icons/ci";
import '../css/random.css'




const storage = getStorage(); // Initialize Firebase storage

export default function UpdateAssistant({ match }) {

    const [files, setFiles] = useState([]);
    const [coverPhoto, setCoverPhoto] = useState('');
    const [name, setName] = useState('');
    const [assistantName, setAssistantName] = useState('');
    const [propertyAddress, setPropertyAddress] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [assistantId, setAssistantId] = useState('');
    const [ical, setIcal] = useState('');

    const navigate = useNavigate();

    const fileInputRef = useRef(null);
    const coverPhotoInputRef = useRef(null);

    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [openAiAssistantId, setOpenAiAssistantId] = useState('');
    const [threadId, setThreadId] = useState(null);
    const [showCheckmark, setShowCheckmark] = useState(false);
    const [numberOfSaves, setNumberOfSaves] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [timer, setTimer] = useState(null);
    const [errors, setErrors] = useState({});
    const [isRunActive, setIsRunActive] = useState(false);





    const handleFileChange = async (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        if (file) {
            const fileRef = ref(storage, `${assistantId}/${file.name}`);
            await uploadBytes(fileRef, file);
            const fileURL = await getDownloadURL(fileRef);
            const fileRecord = {
                name: file.name,
                size: file.size,
                type: file.type,
                url: fileURL,
            };
            setFiles([...files, fileRecord]);
        }
    };

    const removeFile = async (fileName) => {
        const updatedFiles = files.filter(file => file.name !== fileName);
        setFiles(updatedFiles);
    };

    const triggerFileInput = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    const handleCoverPhotoChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const coverPhotoRef = ref(storage, `${assistantId}/cover_photos/${file.name}`);
            await uploadBytes(coverPhotoRef, file);
            const coverPhotoURL = await getDownloadURL(coverPhotoRef);
            setCoverPhoto(coverPhotoURL); // Store the URL directly
        }
    };

    const handleClickPhoto = () => {
        coverPhotoInputRef.current.click();
    };

    const { id } = useParams();
    const [formData, setFormData] = useState({
        iCalLink: '',
        assistantName: '',
        contactDetailsName: '',
        contactDetailsEmail: '',
        contactDetailsPhone: '',
        coverImageURL: '',
        propertyAddress: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        const getAssistant = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/find-one/${id}`);
                const assistant = response.data;
                console.log(assistant);
                setFormData({
                    iCalLink: assistant.iCalLink,
                    assistantName: assistant.assistantName,
                    contactDetailsName: assistant.contactDetails.name,
                    contactDetailsEmail: assistant.contactDetails.email,
                    contactDetailsPhone: assistant.contactDetails.phone,
                    coverImageURL: assistant.coverImageURL,
                    propertyAddress: assistant.propertyAddress,
                });
                setCoverPhoto(assistant.coverImageURL);
                setFiles(assistant.knowledgeBase.map(file => ({
                    name: file.fileName,
                    size: file.fileSize,
                    url: file.knowledgeFileURL,
                })));
                setAssistantId(assistant._id); // Set assistant ID in state
                setOpenAiAssistantId(assistant.openAiAssistantId); // Set openAiAssistantId
                setThreadId(null); // Clear the threadId on load
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };
        getAssistant();
    }, [id]);

    const handleUpdateWithFirebase = async (color) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/assistants/firebase-update/${id}`;

        const payload = {
            iCalLink: formData.iCalLink,
            assistantName: formData.assistantName || 'GuestLink',
            contactDetailsEmail: formData.contactDetailsEmail,
            contactDetailsPhone: formData.contactDetailsPhone,
            coverImageURL: coverPhoto || null,
            backgroundColor: color,
            propertyAddress: formData.propertyAddress,
            contactDetailsName: formData.contactDetailsName,
            knowledgeBaseFiles: files.map(file => ({
                fileName: file.name,
                knowledgeFileURL: file.url,
            })),
        };

        const token = localStorage.getItem('userToken');

        try {
            const response = await axios.put(url, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            console.log('Assistant updated:', response.data);
        } catch (error) {
            console.error('Failed to update assistant:', error.response?.data || error.message);
        }
    };




    const sendMessage = async (e) => {
        e.preventDefault();

        // Check if openAiAssistantId is set
        if (!openAiAssistantId) {
            console.error('No assistant ID found');
            setMessages(prevMessages => [
                ...prevMessages,
                { role: 'assistant', content: 'Error: No assistant ID found. Please save the assistant first.' }
            ]);
            return;
        }

        // Display the user message in the chat
        setMessages(prevMessages => [
            ...prevMessages,
            { role: 'user', content: message }
        ]);

        setMessage(''); // Clear the input field

        // Add loading bubble
        setMessages(prevMessages => [
            ...prevMessages,
            { role: 'assistant', isLoading: true }
        ]);

        try {
            const token = localStorage.getItem('userToken');
            console.log('openAiAssistantId:', openAiAssistantId);

            setIsRunActive(true); // Disable the send button

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/message/`, {
                openAiAssistantId: openAiAssistantId,
                message,
                threadId // Pass the current thread ID
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            console.log('Server response:', response.data); // Log the server response

            // Log the full assistant message content
            const assistantMessage = response.data.message;
            console.log('Assistant message:', assistantMessage);

            // Check if response.data.message and response.data.message.content exist
            if (assistantMessage && assistantMessage.content) {
                const assistantMessageContent = assistantMessage.content.map(item => item.text.value).join(' ');
                setMessages(prevMessages => [
                    ...prevMessages.filter(msg => !msg.isLoading), // Remove loading bubble
                    { role: 'assistant', content: assistantMessageContent }
                ]);

                // If threadId was not set before, set it now
                if (!threadId) {
                    setThreadId(response.data.threadId);
                }
            } else {
                console.error('No content returned from assistant');
                setMessages(prevMessages => [
                    ...prevMessages.filter(msg => !msg.isLoading), // Remove loading bubble
                    { role: 'assistant', content: 'Error: No content returned from assistant' }
                ]);
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setMessages(prevMessages => [
                ...prevMessages.filter(msg => !msg.isLoading), // Remove loading bubble
                { role: 'assistant', content: 'Error: Unable to get a response from assistant' }
            ]);
        }

        setIsRunActive(false); // Enable the send button
        setMessage('');
    };



    const validateForm = () => {
        let formErrors = {};
        // if (!formData.assistantName) formErrors.assistantName = "Assistant name is required";
        if (!formData.contactDetailsName) formErrors.contactDetailsName = "Contact name is required";
        if (!formData.contactDetailsEmail) formErrors.contactDetailsEmail = "Email is required";
        if (!formData.contactDetailsPhone) formErrors.contactDetailsPhone = "Phone number is required";
        if (!formData.propertyAddress) formErrors.propertyAddress = "Property address is required";

        if (files.length === 0) {
            formErrors.knowledgeBase = "Please add file(s) about your property for your assistant's knowledge (e.g., Renter handbook)";
        }

        // Cover photo validation
        // if (!coverPhoto) {
        //     formErrors.coverPhoto = "Cover photo is required";
        // }

        return formErrors;
    };




    const handleSubmit = async (e, source) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }
        setErrors({});
        setIsLoading(true);
        setShowCheckmark(false);

        const MIN_LOADING_TIME = 2000; // Minimum loading time in milliseconds
        const start = Date.now();

        if (numberOfSaves === 0) {
            await handleUpdateWithFirebase(source);
        } else {
            console.log(openAiAssistantId)
            await handleUpdateWithFirebase(source);
            //refresh upon save changes
            setMessages([]);
            setThreadId(null);
        }

        const elapsed = Date.now() - start;
        const remainingTime = MIN_LOADING_TIME - elapsed;

        // Ensure minimum loading time
        if (remainingTime > 0) {
            setTimer(setTimeout(() => {
                setIsLoading(false);
                setShowCheckmark(true);
                setTimeout(() => setShowCheckmark(false), 2000); // Show checkmark for 2 seconds
                setTimer(null);
            }, remainingTime));
        } else {
            setIsLoading(false);
            setShowCheckmark(true);
            setTimeout(() => setShowCheckmark(false), 2000); // Show checkmark for 2 seconds
        }

        if (source === 'finalCreate') {
            navigate('/creating-assistant');
        } else {
            // Trigger CSS changes to indicate that changes have been saved
        }

        setNumberOfSaves(prev => prev + 1);
    };

    // Cleanup timer if the component unmounts
    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timer]);


    return (
        <div className='flex bg-[#161616] h-screen overflow-hidden'>
            <div>
                <SideBar />
            </div>
            <div className='flex flex-col w-full overflow-hidden p-3 bg-[#eeece7] mt-3 -ml-3 rounded-t-2xl rounded-b-3xl pb-5'>
                
                <div className='flex flex-grow overflow-hidden'>
                    <div className='w-3/4 overflow-hidden relative mt-3'>
                        {/* Scrollable form */}
                        <form method="post" encType="multipart/form-data" className='absolute inset-0 flex flex-col overflow-auto p-4 mr-4  bg-[#eeece7]'>
                            <p className='mb-4 font-bold '>Update Your Assistant</p>
                            <div
                                className="flex flex-col mb-7 items-center justify-center text-center min-w-40 min-h-40 max-w-40 max-h-40 rounded-full border-2 border-gray-300 border-dashed hover:border-gray-400"
                                onClick={handleClickPhoto}
                            >
                                {coverPhoto ? (
                                    <img
                                        src={coverPhoto.startsWith('blob:') ? URL.createObjectURL(coverPhoto) : coverPhoto} // Handle URL correctly
                                        alt="Cover Preview"
                                        className="w-full h-full object-cover rounded-full"
                                    />
                                ) : (
                                    <>
                                        <IoCloudUploadOutline />
                                        <p className="mb-2 text-sm text-gray-500">
                                            <span className="font-semibold">Upload Cover Photo</span>
                                        </p>
                                    </>
                                )}
                                <input
                                    type="file"
                                    id="fileInput"
                                    className="hidden"
                                    onChange={handleCoverPhotoChange}
                                    ref={coverPhotoInputRef}
                                    accept=".jpg,.jpeg,.png,.gif"
                                />
                                {/* {errors.coverPhoto && <p className="text-red-500 text-sm mt-2">{errors.coverPhoto}</p>} */}
                            </div>

                            <div className='mb-7'>
                                <p className='mb-2 text-md font-semibold'>Name</p>
                                {/* {errors.assistantName && <p className="text-red-500 text-sm mb-2">{errors.assistantName}</p>} */}
                                <div className="relative w-56 flex items-center ">
                                    <RiRobot3Line size={18} className="absolute ml-5" />
                                    <input
                                        className="pl-12 appearance-none rounded-full h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
                                        id="assistant-name"
                                        type="text"
                                        value={formData.assistantName}
                                        onChange={handleChange}
                                        name='assistantName'
                                    />
                                </div>
                            </div>

                            <div className='mb-7'>
                                <div className='flex space-x-3 items-center'>
                                    <p className='mb-2 text-md text-center font-semibold'>Sync Your Renter Calendar </p>
                                    <div className="tooltip-container z-20 mb-2">
                                        <NavLink to={'/ical-instructions'} className="hover:text-blue-400"><CiCircleQuestion size={20} style={{ strokeWidth: .5 }} /></NavLink>
                                        <span className="tooltip z-20">Click here for a quick video on how easily to sync your calendar.</span>
                                    </div>
                                </div>
                                <div className="relative w-96 flex items-center ">
                                    <Link size={18} className="absolute ml-5" />
                                    <input
                                        className="pl-12 truncate appearance-none rounded-full h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
                                        id="assistant-name"
                                        type="text"
                                        value={formData.iCalLink}
                                        onChange={handleChange}
                                        name='iCalLink'
                                    />
                                </div>
                            </div>

                            <div className='mb-7'>
                                <p className='text-md font-semibold '>Contact Details <span className='text-[#FF5A5F]'>*</span></p>
                                {(errors.contactDetailsName || errors.contactDetailsEmail || errors.contactDetailsPhone) && <p className="text-red-500 text-sm">Please fill in all feilds</p>}
                                <p className='text-sm mb-4'>This is for the assistant to provide to your renters if necessary.</p>
                                <div className='flex space-x-3'>
                                    <div className="relative flex items-center w-full">
                                        <FaUserAstronaut size={18} className="absolute ml-5" />
                                        <input
                                            className="pl-12 appearance-none rounded-full h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
                                            id="full-name"
                                            type="text"
                                            value={formData.contactDetailsName}
                                            onChange={handleChange}
                                            name='contactDetailsName'
                                        />
                                    </div>
                                    <div className="relative flex items-center w-full">
                                        <HiOutlineMail size={18} className="absolute ml-5" />
                                        <input
                                            className="pl-12 appearance-none rounded-full h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
                                            id="email"
                                            type="text"
                                            value={formData.contactDetailsEmail}
                                            onChange={handleChange}
                                            name='contactDetailsEmail'
                                        />
                                    </div>
                                    <div className="relative flex items-center w-full">
                                        <SlScreenSmartphone size={18} className="absolute ml-5" />
                                        <input
                                            className="pl-12 appearance-none rounded-full h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
                                            id="phone-number"
                                            type="text"
                                            value={formData.contactDetailsPhone}
                                            onChange={handleChange}
                                            name='contactDetailsPhone'
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='mb-7'>
                                <p className='mb-2 text-md font-semibold'>Property Address <span className='text-[#FF5A5F]'>*</span></p>
                                {errors.propertyAddress && <p className="text-red-500 text-sm" >{errors.propertyAddress}</p>}
                                <div className="relative w-96 flex items-center ">
                                    <BsHouse size={18} className="absolute ml-5" />
                                    <input
                                        className="pl-12 truncate appearance-none rounded-full h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
                                        id="assistant-name"
                                        type="text"
                                        value={formData.propertyAddress}
                                        onChange={handleChange}
                                        name='propertyAddress'
                                    />
                                </div>
                            </div>

                            <div className='mb-24 flex flex-col flex-wrap'>
                                <div className='flex space-x-3 items-center'>
                                    <p className='mb-2 text-md font-semibold'>Knowledge <span className='text-[#FF5A5F]'>*</span></p>
                                    {errors.knowledgeBase && <p className="text-red-500 mb-2 text-sm" >{errors.knowledgeBase}</p>}
                                </div>
                                <p className='text-sm mb-4'>Upload your renter handbook or any info about your property for the assistant to answer questions from.</p>
                                <button
                                    onClick={triggerFileInput}
                                    className="mb-4 rounded-2xl bg-white hover:bg-[#f4ccc9] text-sm font-semibold py-2 px-4"
                                >
                                    Upload Files
                                </button>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                    className="hidden"
                                    accept=".pdf,.doc,.docx,.ppt,.pptx,.txt"
                                />
                                <div className='grid grid-cols-2 gap-4'>
                                    <div className='grid grid-cols-2 gap-4'>
                                        {files.map(file => (
                                            <div key={file.name} className="flex group relative items-center w-80 bg-white justify-between p-2 space-x-3 rounded-2xl hover:bg-[#f4ccc9]">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" fill="none" className="h-10 w-10 flex-shrink-0" width="36" height="36">
                                                    <rect width="36" height="36" rx="6" fill="#FF5588"></rect>
                                                    <path d="M19.6663 9.66663H12.9997C12.5576 9.66663 12.1337 9.84222 11.8212 10.1548C11.5086 10.4673 11.333 10.8913 11.333 11.3333V24.6666C11.333 25.1087 11.5086 25.5326 11.8212 25.8451C12.1337 26.1577 12.5576 26.3333 12.9997 26.3333H22.9997C23.4417 26.3333 23.8656 26.1577 24.1782 25.8451C24.4907 25.5326 24.6663 25.1087 24.6663 24.6666V14.6666L19.6663 9.66663Z" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M19.667 9.66663V14.6666H24.667" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M21.3337 18.8334H14.667" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M21.3337 22.1666H14.667" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M16.3337 15.5H15.5003H14.667" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                                <span className="flex-1 truncate">{file.name} - {Math.round(file.size / 1024)} KB</span>
                                                <button onClick={() => removeFile(file.name)} className="opacity-0 group-hover:opacity-100">
                                                    <FiTrash2 />
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className='mb-7 flex justify-between m-x-3 items-center'>
                                <div className='flex items-center space-x-2 w-80'>
                                    <button
                                        type="button"
                                        className="mb-4 rounded-2xl bg-white hover:bg-[#f4ccc9] text-sm font-semibold py-2 px-4"
                                        onClick={(e) => handleSubmit(e, 'saveChanges')}
                                    >
                                        Save Changes
                                    </button>
                                    {isLoading &&
                                        <div className='flex items-center space-x-2 '>
                                            <span className='loader -mt-4 ml-3'></span>
                                        </div>
                                    }
                                    {showCheckmark &&
                                        <div className="wrapper -mt-4 ml-3">
                                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                            </svg>
                                        </div>
                                    }
                                </div>
                                <button type='button' onClick={(e) => handleSubmit(e, 'finalCreate')} className='mb-2 text-md font-semibold create-button mr-3'>
                                    Finalize
                                </button>
                            </div>
                        </form>
                    </div>

                    <div className='flex flex-col w-[350px]'>
                        <HeaderMain />

                        {/* Chat component */}
                        <div className="flex flex-col w-[350px] h-full bg-white border-2 border-gray-300 rounded-[40px] overflow-hidden">
                            <div className="flex-1 overflow-auto p-4">
                                {/* Chat messages */}
                                {messages.length === 0 ? (
                                    <div className='flex flex-col space-y-20 justify-center items-center mt-40'>
                                        <p className='text-center text-gray-500'>Try Your Assistant!</p>
                                        <GiPartyPopper size={80} color='grey' />
                                    </div>
                                ) : (
                                    messages.map((msg, index) => (
                                        <div
                                            key={index}
                                            className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'} mb-2`}
                                        >
                                            <div
                                                className={`${msg.role === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-100 text-black'
                                                    } rounded-3xl p-3 max-w-xs lg:max-w-md`}
                                                style={{ wordBreak: 'break-word', maxWidth: '250px' }}
                                            >
                                                <ReactMarkdown>{msg.content}</ReactMarkdown>
                                                {msg.isLoading &&
                                                    <div className="typing">
                                                        <div className="typing__dot"></div>
                                                        <div className="typing__dot"></div>
                                                        <div className="typing__dot"></div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                            <div className="p-4 border-t-2 border-gray-200">
                                <form className="flex items-center" onSubmit={sendMessage}>
                                    {/* Chat input */}
                                    <div className="relative flex-1 mx-2">
                                        <input
                                            type="text"
                                            className="border-2 text-sm border-gray-300 focus:border-transparent rounded-full p-2 w-full pl-4 pr-10 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                                            placeholder="Ask your assistant a question..."
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                        />
                                        <button type="submit" className="absolute inset-y-0 right-0 pr-3 flex items-center text-blue-500" disabled={isRunActive}>
                                            <FaArrowCircleUp size={20} />
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

// return (
//     <div className='flex bg-[#161616] h-screen overflow-hidden'>

//         <div>
//             <SideBar />
//         </div>

//         <div className='flex flex-col w-full h-screen overflow-hidden p-3 bg-[#eeece7]'>
//             <HeaderMain />
//             <div className='flex flex-grow overflow-hidden'>
//                 <div className='w-3/4 overflow-hidden relative -mt-16'>
//                     {/* Scrollable form */}
//                     <form method="post" encType="multipart/form-data" className='absolute inset-0 flex flex-col overflow-auto p-4 mr-4 bg-[#eeece7]'>
//                         <p className='mb-4 font-bold '>Create Your Assistant</p>
//                         <div
//                             className="flex flex-col mb-7 items-center justify-center text-center min-w-40 min-h-40 max-w-40 max-h-40 rounded-full border-2 border-gray-300 border-dashed hover:border-gray-400"
//                             onClick={handleClickPhoto}
//                         >
//                             {coverPhoto ? (
//                                 <img
//                                     src={coverPhoto.startsWith('blob:') ? URL.createObjectURL(coverPhoto) : coverPhoto} // Handle URL correctly
//                                     alt="Cover Preview"
//                                     className="w-full h-full object-cover rounded-full"
//                                 />
//                             ) : (
//                                 <>
//                                     <IoCloudUploadOutline />
//                                     <p className="mb-2 text-sm text-gray-500">
//                                         <span className="font-semibold">Upload Cover Photo</span>
//                                     </p>
//                                 </>
//                             )}
//                             <input
//                                 type="file"
//                                 id="fileInput"
//                                 className="hidden"
//                                 onChange={handleCoverPhotoChange}
//                                 ref={coverPhotoInputRef}
//                                 accept=".jpg,.jpeg,.png,.gif"
//                             />
//                             {errors.coverPhoto && <p className="text-red-500 text-sm mt-2">{errors.coverPhoto}</p>}
//                         </div>

//                         <div className='mb-7'>
//                             <p className='mb-2 text-md font-semibold'>Name</p>
//                             {errors.assistantName && <p className="text-red-500 text-sm mb-2">{errors.assistantName}</p>}
//                             <div className="relative w-56 flex items-center ">
//                                 <RiRobot3Line size={18} className="absolute ml-5" />
//                                 <input
//                                     className="pl-12 appearance-none rounded-full h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
//                                     id="assistant-name"
//                                     type="text"
//                                     value={formData.assistantName}
//                                     onChange={handleChange}
//                                     name='assistantName'
//                                 />
//                             </div>
//                         </div>

//                         <div className='mb-7'>
//                             <p className='text-md font-semibold '>Contact Details</p>
//                             {(errors.contactDetailsName || errors.contactDetailsEmail || errors.contactDetailsPhone) && <p className="text-red-500 text-sm">Please fill in all feilds</p>}
//                             <p className='text-sm mb-4'>This is for the assistant to provide to your renters if necessary.</p>
//                             <div className='flex space-x-3'>
//                                 <div className="relative flex items-center w-full">
//                                     <FaUserAstronaut size={18} className="absolute ml-5" />
//                                     <input
//                                         className="pl-12 appearance-none rounded-full h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
//                                         id="full-name"
//                                         type="text"
//                                         value={formData.contactDetailsName}
//                                         onChange={handleChange}
//                                         name='contactDetailsName'
//                                     />
//                                 </div>
//                                 <div className="relative flex items-center w-full">
//                                     <HiOutlineMail size={18} className="absolute ml-5" />
//                                     <input
//                                         className="pl-12 appearance-none rounded-full h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
//                                         id="email"
//                                         type="text"
//                                         value={formData.contactDetailsEmail}
//                                         onChange={handleChange}
//                                         name='contactDetailsEmail'
//                                     />
//                                 </div>
//                                 <div className="relative flex items-center w-full">
//                                     <SlScreenSmartphone size={18} className="absolute ml-5" />
//                                     <input
//                                         className="pl-12 appearance-none rounded-full h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
//                                         id="phone-number"
//                                         type="text"
//                                         value={formData.contactDetailsPhone}
//                                         onChange={handleChange}
//                                         name='contactDetailsPhone'
//                                     />
//                                 </div>
//                             </div>
//                         </div>

//                         <div className='mb-7'>
//                             <p className='mb-2 text-md font-semibold'>Property Address</p>
//                             {errors.propertyAddress && <p className="text-red-500 text-sm" >{errors.propertyAddress}</p>}
//                             <div className="relative w-96 flex items-center ">
//                                 <BsHouse size={18} className="absolute ml-5" />
//                                 <input
//                                     className="pl-12 truncate appearance-none rounded-full h-12 w-full  leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
//                                     id="assistant-name"
//                                     type="text"
//                                     value={formData.propertyAddress}
//                                     onChange={handleChange}
//                                     name='propertyAddress'
//                                 />
//                             </div>
//                         </div>

//                         <div className='mb-24 flex flex-col flex-wrap'>
//                             <div className='flex space-x-3 items-center'>
//                                 <p className='mb-2 text-md font-semibold'>Knowledge</p>
//                                 {errors.knowledgeBase && <p className="text-red-500 mb-2 text-sm" >{errors.knowledgeBase}</p>}
//                             </div>
//                             <p className='text-sm mb-4'>Upload your renter handbook or any info about your property for the assistant to answer questions from.</p>
//                             <button
//                                 onClick={triggerFileInput}
//                                 className="mb-4 rounded-2xl bg-white hover:bg-[#f4ccc9] text-sm font-semibold py-2 px-4"
//                             >
//                                 Upload Files
//                             </button>
//                             <input
//                                 type="file"
//                                 ref={fileInputRef}
//                                 onChange={handleFileChange}
//                                 className="hidden"
//                                 accept=".pdf,.doc,.docx,.ppt,.pptx,.txt"
//                             />
//                             <div className='grid grid-cols-2 gap-4'>
//                                 <div className='grid grid-cols-2 gap-4'>
//                                     {files.map(file => (
//                                         <div key={file.name} className="flex group relative items-center w-80 bg-white justify-between p-2 space-x-3 rounded-2xl hover:bg-[#f4ccc9]">
//                                             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" fill="none" className="h-10 w-10 flex-shrink-0" width="36" height="36">
//                                                 <rect width="36" height="36" rx="6" fill="#FF5588"></rect>
//                                                 <path d="M19.6663 9.66663H12.9997C12.5576 9.66663 12.1337 9.84222 11.8212 10.1548C11.5086 10.4673 11.333 10.8913 11.333 11.3333V24.6666C11.333 25.1087 11.5086 25.5326 11.8212 25.8451C12.1337 26.1577 12.5576 26.3333 12.9997 26.3333H22.9997C23.4417 26.3333 23.8656 26.1577 24.1782 25.8451C24.4907 25.5326 24.6663 25.1087 24.6663 24.6666V14.6666L19.6663 9.66663Z" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path>
//                                                 <path d="M19.667 9.66663V14.6666H24.667" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path>
//                                                 <path d="M21.3337 18.8334H14.667" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path>
//                                                 <path d="M21.3337 22.1666H14.667" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path>
//                                                 <path d="M16.3337 15.5H15.5003H14.667" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path>
//                                             </svg>
//                                             <span className="flex-1 truncate">{file.name} - {Math.round(file.size / 1024)} KB</span>
//                                             <button onClick={() => removeFile(file.name)} className="opacity-0 group-hover:opacity-100">
//                                                 <FiTrash2 />
//                                             </button>
//                                         </div>
//                                     ))}
//                                 </div>
//                             </div>
//                         </div>

//                         <div className='mb-7 flex justify-between m-x-3 items-center'>
//                             <div className='flex items-center space-x-2 w-80'>
//                                 <button
//                                     type="button"
//                                     className="mb-4 rounded-2xl bg-white hover:bg-[#f4ccc9] text-sm font-semibold py-2 px-4"
//                                     onClick={(e) => handleSubmit(e, 'saveChanges')}
//                                 >
//                                     Save Changes
//                                 </button>
//                                 {isLoading &&
//                                     <div className='flex items-center space-x-2 '>
//                                         <span className='loader -mt-4 ml-3'></span>
//                                     </div>
//                                 }
//                                 {showCheckmark &&
//                                     <div className="wrapper -mt-4 ml-3">
//                                         <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
//                                             <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
//                                             <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
//                                         </svg>
//                                     </div>
//                                 }
//                             </div>
//                             <button type='button' onClick={(e) => handleSubmit(e, 'finalCreate')} className='mb-2 text-md font-semibold create-button mr-3'>
//                                 Finalize
//                             </button>
//                         </div>
//                     </form>
//                 </div>

//                 {/* Chat component */}
//                 <div className="flex flex-col w-1/4 h-full bg-white border-2 border-gray-300 rounded-[40px] overflow-hidden">
//                     <div className="flex-1 overflow-auto p-4">
//                         {/* Chat messages */}
//                         {messages.length === 0 ? (
//                             <div className='flex flex-col space-y-20 justify-center items-center mt-40'>
//                                 <p className='text-center text-gray-500'>Try Your Assistant!</p>
//                                 <GiPartyPopper size={80} color='grey' />
//                             </div>
//                         ) : (
//                             messages.map((msg, index) => (
//                                 <div
//                                     key={index}
//                                     className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'} mb-2`}
//                                 >
//                                     <div
//                                         className={`${msg.role === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-100 text-black'
//                                             } rounded-3xl p-3 max-w-xs lg:max-w-md`}
//                                         style={{ wordBreak: 'break-word', maxWidth: '250px' }}
//                                     >
//                                         <ReactMarkdown>{msg.content}</ReactMarkdown>
//                                         {msg.isLoading &&
//                                             <div className="typing">
//                                                 <div className="typing__dot"></div>
//                                                 <div className="typing__dot"></div>
//                                                 <div className="typing__dot"></div>
//                                             </div>
//                                         }
//                                     </div>
//                                 </div>
//                             ))
//                         )}
//                     </div>
//                     <div className="p-4 border-t-2 border-gray-200">
//                         <form className="flex items-center" onSubmit={sendMessage}>
//                             {/* Chat input */}
//                             <div className="relative flex-1 mx-2">
//                                 <input
//                                     type="text"
//                                     className="border-2 text-sm border-gray-300 focus:border-transparent rounded-full p-2 w-full pl-4 pr-10 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-50"
//                                     placeholder="Ask your assistant a question..."
//                                     value={message}
//                                     onChange={(e) => setMessage(e.target.value)}
//                                     disabled={isRunActive || numberOfSaves < 1}
//                                 />
//                                 <button type="submit" className="absolute inset-y-0 right-0 pr-3 flex items-center text-blue-500" disabled={isRunActive}>
//                                     <FaArrowCircleUp size={20} />
//                                 </button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
// )









//UPDATE WITHOUT FIREBASE, NOT WORKING
// const handleUpdateAssistant = async (e, shouldNavigate) => {
//     e.preventDefault();
//     const url = `http://localhost:8000/api/assistants/update/${id}`;

//     const formDataObj = new FormData();
//     formDataObj.append('assistantName', formData.assistantName);
//     formDataObj.append('contactDetailsEmail', formData.contactDetailsEmail);
//     formDataObj.append('contactDetailsPhone', formData.contactDetailsPhone);
//     formDataObj.append('coverImageURL', formData.coverImageURL);
//     formDataObj.append('propertyAddress', formData.propertyAddress);
//     formDataObj.append('contactDetailsName', formData.contactDetailsName);

//     // Append files
//     for (let i = 0; i < files.length; i++) {
//         formDataObj.append('knowledgeBaseFiles', files[i]);
//         formDataObj.append(`knowledgeBaseFileNames[${i}]`, files[i].name);
//         formDataObj.append(`knowledgeBaseFileSizes[${i}]`, files[i].size);
//     }

//     // Log FormData
//     for (let pair of formDataObj.entries()) {
//         console.log(pair[0], pair[1]);
//     }

//     const token = localStorage.getItem('userToken');

//     try {
//         const response = await axios.put(url, formDataObj, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//                 'Content-Type': 'multipart/form-data'
//             }
//         });
//         console.log('Assistant updated:', response.data);
//         if (shouldNavigate) {
//             navigate('/creating-assistant');
//         }
//     } catch (error) {
//         console.error('Failed to update assistant:', error.response?.data || error.message);
//     }
// };



// const handleSaveChanges = async () => {
//     const url = `http://localhost:8000/api/assistants/`;
//     const payload = {
//         coverImageURL: coverPhoto,
//         assistantName,
//         contactDetails: {
//             name,
//             phone,
//             email,
//         },
//         propertyAddress,
//         knowledgeBaseUrls: files.map(file => ({ knowledgeFileURL: file.url }))
//     };
//     try {
//         console.log(files)
//         const response = await axios.post(url, payload);
//         console.log('Assistant created:', response.data);
//         setAssistantId(response.data.assistantId);  // Store the assistant ID in state
//         setNumberOfSaves(prev => prev + 1);  // Increment the number of saves
//         navigate('/creating-assistant');
//     } catch (error) {
//         console.error('Failed to create assistant:', error.response?.data || error.message);
//     } finally {
//         console.log('Assistant created and finished, published to number');
//     }
// };

// const handleSaveChanges = (e) => {
//     e.preventDefault();
//     if (numberOfSaves === prev numberOfSaves;) {
//         handleUpdateAssistant();
//     } else {
//         handleUpdateAssistant();
//         navigate('/creating-assistant')
//     }
// };

// Example of using handleSubmit in a button:

