import React, { useState, useEffect } from 'react';
import loader from '../../assets/Box Spin Loader.gif';
import { useNavigate, useLocation, UNSAFE_NavigationContext } from 'react-router-dom';
import backgroundImage from '../../assets/snow-house.jpeg'; // Ensure this path is correct
import useBlockNavigation from '../../hooks/useBlockNavigation';

const LoginLoading = () => {
    const [showText, setShowText] = useState(false);
    const navigate = useNavigate();
    const [greeting, setGreeting] = useState('');
    const location = useLocation();
    const fromReg = 'Welcome In';
    const fromLogin = "Welcome Back :)";

    // useBlockNavigation();


    useEffect(() => {
        const textTimeout = setTimeout(() => {
            setShowText(true);
            console.log("Show text is true now");
        }, 3000);

        const redirectTimeout = setTimeout(() => {
            if (location.state?.from === '/register') {
                navigate("/create-assistant", { state: { from: '/register' } });
            } else if (location.state?.from === '/login') {
                navigate("/assistants", { state: { from: '/login' } });
            }
        }, 5000);

        return () => {
            clearTimeout(textTimeout);
            clearTimeout(redirectTimeout);
        };
    }, [navigate]);

    useEffect(() => {
        const lastEntry = location.state?.from?.pathname;
        console.log("Last entry path:", lastEntry);

        if (location.state?.from === '/login') {
            setGreeting(fromLogin);
            console.log("Set greeting to:", fromLogin);
        } else if (location.state?.from === '/register') {
            setGreeting(fromReg);
            console.log("Set greeting to:", fromReg);
        } else {
            setGreeting("Welcome!");
            console.log("Set greeting to default Welcome!");
        }
    }, [location]);

    // Inline styles for the background image and blur overlay
    const backgroundStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };

    return (
        <div style={backgroundStyle} className="relative h-screen">
            {/* Overlay with blur */}
            <div className="absolute inset-0 bg-slate-500/10 backdrop-blur-md"></div>

            {/* Centered content */}
            <div className="relative flex flex-col items-center justify-center h-screen p-40">
                <div className='text-white rounded-3xl shadow-xl flex items-center justify-center p-10 flex-col space-y-4 z-10'>
                    {showText ? (
                        <h1 className="font-bold text-4xl">{greeting}</h1>
                    ) : (
                        <h1 className="font-bold text-4xl">Logging you in...</h1>
                    )}
                    <div className='w-80 h-80 flex items-center justify-center'>
                        <img src={loader} alt="Loading..." className='w-full h-full object-cover' />
                    </div>
                    <p>Get excited...</p>
                </div>
            </div>
        </div>
    );
};

export default LoginLoading;
