import React, { useEffect, useState } from 'react';
import SideBar from '../../components/SideBar';
import HeaderMain from '../../components/HeaderMain';
import { useLocation } from 'react-router-dom';
import RegisterForm from './RegisterForm';


export default function GhostRegister() {
    return (
        <div className='flex bg-[#161616] h-screen'>
            <div>
                <SideBar />
            </div>
            <div className='flex w-full rounded-2xl mt-3 -ml-3 overflow-hidden'>
                {/* go to reg to edit conditional */}
                <RegisterForm />
            </div>
        </div>
    );
}
