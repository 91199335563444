import React, { useEffect, useState } from 'react';
import SideBar from '../components/SideBar';
import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const stripePromise = loadStripe(process.env.STRIPE_TEST_PUBLIC_KEY); // Replace with your Stripe public key

const CheckoutPage = () => {




    const handleClick = async () => {
        try {
            const token = localStorage.getItem('userToken');
            const decodedToken = jwtDecode(token);
            const userId = decodedToken.id;

            const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/create-checkout-session`, {
                customerId: userId, // You need to handle customer creation and retrieve customer ID
                numberOfAssistants: 1, // Replace with the actual number of assistants
            });

            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({
                sessionId: data.id,
            });

            if (error) {
                console.error(error);
            }
        } catch (error) {
            console.error("Error creating checkout session:", error);
        }
    };

    return (
        <div className='flex bg-[#161616] h-screen'>
            <div>
                <SideBar />
            </div>
            <div className='flex w-full rounded-2xl mt-3 -ml-3 bg-[#eeece7] overflow-hidden p-4'>
                <div className="w-full">
                    <div className="mt-10 bg-[#f5f4f0] rounded-2xl px-4 pt-8 lg:mt-0">
                        <p className="text-xl font-medium">Payment Details</p>
                        <div className="">
                            
                            <label htmlFor="card-holder" className="mt-4 mb-2 block text-sm font-medium">Card Holder</label>
                            <div className="relative">
                                <input type="text" id="card-holder" name="card-holder" className="w-full rounded-2xl px-4 py-3 pl-11 text-sm uppercase outline-none focus:z-10 focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50" placeholder="Your full name here" />
                                <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                                    </svg>
                                </div>
                            </div>
                            <label htmlFor="card-no" className="mt-4 mb-2 block text-sm font-medium">Card Details</label>
                            <div className="flex space-x-3">
                                <div className="relative w-7/12 flex-shrink-0">
                                    <input type="text" id="card-no" name="card-no" className="w-full rounded-2xl px-2 py-3 pl-11 text-sm outline-none focus:z-10 focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50" placeholder="xxxx-xxxx-xxxx-xxxx" />
                                    <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                                        <svg className="h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                            <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z" />
                                            <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z" />
                                        </svg>
                                    </div>
                                </div>
                                <input type="text" name="credit-expiry" className="w-full rounded-2xl px-2 py-3 text-sm outline-none focus:z-10 focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50" placeholder="MM/YY" />
                                <input type="text" name="credit-cvc" className="w-1/6 flex-shrink-0 rounded-2xl px-2 py-3 text-sm outline-none focus:z-10 focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50" placeholder="CVC" />
                            </div>
                            <label htmlFor="billing-address" className="mt-4 mb-2 block text-sm font-medium">Billing Address</label>
                            <div className="flex flex-col sm:flex-row space-x-3">
                                <div className="relative flex-shrink-0 sm:w-7/12">
                                    <input type="text" id="billing-address" name="billing-address" className="w-full rounded-2xl px-4 py-3 pl-11 text-sm outline-none focus:z-10 focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50" placeholder="Street Address" />
                                    <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                                        <img className="h-4 w-4 object-contain" src="https://flagpack.xyz/_nuxt/4c829b6c0131de7162790d2f897a90fd.svg" alt="" />
                                    </div>
                                </div>
                                <select type="text" name="billing-state" className="w-full rounded-2xl px-4 py-3 text-sm outline-none focus:z-10 focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50">
                                    <option value="State">State</option>
                                </select>
                                <input type="text" name="billing-zip" className="flex-shrink-0 rounded-2xl px-4 py-3 text-sm outline-none sm:w-1/6 focus:z-10 focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50" placeholder="ZIP" />
                            </div>
                        </div>
                        <button className="mt-6 mb-6 rounded-full w-40 bg-gray-900 px-6 py-3 font-medium text-white" onClick={handleClick}>Update</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckoutPage;



