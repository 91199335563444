import { jwtDecode } from 'jwt-decode';

export const isGhostUser = () => {
    const token = localStorage.getItem('userToken');
    if (token) {
        try {
            const decodedToken = jwtDecode(token);
            return decodedToken.ghost === true;
        } catch (error) {
            console.error('Failed to decode token:', error);
            return false;
        }
    }
    return false;
};
