import React, { useEffect, useState } from 'react'
import SideBar from '../components/SideBar'
import HeaderMain from '../components/HeaderMain'
import StatsCard from '../components/StatsCard'
import axios from 'axios'
import { Link } from 'react-router-dom'

export default function Analytics() {
    const [assistants, setAssistants] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const getAssistants = async () => {
            const token = localStorage.getItem('userToken');
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/get-not-locked`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                setAssistants(response.data);
            } catch (error) {
                console.error("error getting assistant", error);
            } finally {
                setLoading(false); // Set loading to false after data is fetched
            }
        };
        getAssistants();
    }, []);

    return (
        <div className='flex bg-[#161616] h-screen'>
            <div>
                <SideBar />
            </div>
            <div className='flex flex-col w-full mt-3 -ml-3 overflow-hidden p-3 rounded-2xl bg-[#eeece7]'>
                <HeaderMain />
                <div className='flex h-full '>
                    {/* Scrollable div here */}
                    <div className='flex flex-col space-y-6 pb-56 mr-3 w-full p-4 overflow-auto rounded-2xl bg-[#eeece7]'>
                        {loading ? (
                            <div className='flex flex-col mt-10 ml-3 space-y-4 items-center justify-center justify-self-center place-items-center'>
                                {/* <div className='bounce'></div> */}
                            </div>
                        ) : (
                            assistants.length === 0 ? (
                                <div className='flex flex-col mt-10 ml-3 space-y-4 items-center justify-center justify-self-center place-items-center'>
                                    <p className='text-grey-400 font-bold text-3xl'>Create your first assistant</p>
                                    <p>Nothing here yet sorry...</p>
                                    <Link to={'/create-assistant'} className='mb-5 p-1 rounded-2xl px-3 py-2 bg-[#f4ccc9] inline-block hover:bg-white'>Create Assistant</Link>
                                </div>
                            ) : (
                                <div>
                                    <p className='mb-2 font-bold '>All Assistants</p>
                                    {assistants.map(assistant => (
                                        <div key={assistant._id} className='mb-4'>
                                            <StatsCard assistant={assistant} />
                                        </div>
                                    ))}
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
