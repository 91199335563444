import React, { useEffect, useState } from 'react';
import SideBar from '../components/SideBar';
import SampleCard from '../components/SampleCard';
import HeaderMain from '../components/HeaderMain';
import { useLocation } from 'react-router-dom';
import { IoCopyOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { jwtDecode } from 'jwt-decode';
// import useAuthCheck from '../components/useAuthCheck';
import axios from 'axios';
import AssistantDetails from '../components/AssistantDetails';
import { isGhostUser } from '../services/ghostCheck';
import { useNavigate } from 'react-router-dom';
import { IoIosClose } from "react-icons/io";
import { RiRobot3Line } from "react-icons/ri";

// import { loadStripe } from '@stripe/stripe-js';
// const stripePromise = loadStripe("pk_test_51O8APtAVry7Dxu3xUUthjxI0j8lrxyUFrwwkGse2mGeUfGA3rg1AE1rjQCNH8zzfONBaE5vmPxn0j2iyffTKOkXu00Zsm26ot3");


import { loadStripe } from '@stripe/stripe-js';
import { stripePublicKey } from '../config/stripe';
// console.log('Stripe Key:', process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const stripePromise = loadStripe(stripePublicKey);


const Modal = ({ show, onClose, hasBilling, assistantId, token, isGhost, mostRecentAssistant, assistants, setAssistants, setSelectedAssistant }) => {
    const [triedDeleting, setTriedDeleting] = useState(false);
    const navigate = useNavigate(); // Hook for navigation
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    // const [selectedAssistant, setSelectedAssistant] = useState(null);

    // Function to handle deleting the assistant
    // const handleDeleteClick = async () => {
    //     setIsDeleting(true); // Set deleting state to true to show loading indicator
    //     const MINIMUM_SPINNER_TIME = 3000; // Minimum spinner time of 3 seconds
    //     const spinnerTimeout = new Promise(resolve => setTimeout(resolve, MINIMUM_SPINNER_TIME)); // Promise for minimum spinner time
    //     const token = localStorage.getItem("userToken");

    //     try {
    //         const deletePromise = axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/delete/${assistantId}`, {
    //             headers: { Authorization: `Bearer ${token}` }
    //         });

    //         await Promise.all([spinnerTimeout, deletePromise]); // Ensure spinner shows for minimum time
    //         setIsDeleted(true); // Set deleted state to true
    //         setAssistants(assistants.filter(a => a._id !== assistantId)); // Update assistants list
    //         setSelectedAssistant(null); // Clear selected assistant

    //         if (typeof onClose === 'function') {
    //             onClose(); // Close the modal
    //         } else {
    //             console.error('onClose is not a function');
    //         }
    //     } catch (error) {
    //         console.error('Failed to delete assistant:', error);
    //         setIsDeleting(false); // Reset deleting state if error occurs
    //     }
    // };

    const handleDeleteClick = async () => {
        setIsDeleting(true);
        const MINIMUM_SPINNER_TIME = 3000;
        const spinnerTimeout = new Promise(resolve => setTimeout(resolve, MINIMUM_SPINNER_TIME));
        const token = localStorage.getItem("userToken");

        try {
            const deletePromise = axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/delete/${assistantId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            await Promise.all([spinnerTimeout, deletePromise]);
            setIsDeleted(true);
            setAssistants(assistants.filter(a => a._id !== assistantId));
            setSelectedAssistant(null);
        } catch (error) {
            console.error('Failed to delete assistant:', error);
            setIsDeleting(false);
        }
    };



    // METHOD TAKING GHOST LOGIC AND PLACING IT HERE FOR REG USER SHOULD WORK BUT REPLACING THIS WITH THE WEBHOOK METHOD.
    const handleTryItClick = async () => {
        // Decode token to get user's ID
        const token = localStorage.getItem('userToken');
        let decodedToken = null;
        let userId = null;

        if (token) {
            try {
                decodedToken = jwtDecode(token);
                userId = decodedToken.id;  // Ensure your token contains the user ID field
            } catch (error) {
                console.error('Failed to decode token:', error);
            }
        }

        if (!userId) {
            console.error('User ID is not set, aborting Stripe setup.');
            return; // Exit early if userId is not set
        }

        let email = null;
        try {
            const userResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/get-user/${userId}`);
            email = userResponse.data.accountEmail;
            console.log("Fetched email from backend:", email);
        } catch (error) {
            console.error('Failed to fetch user email:', error);
            return; // Exit early if fetching email fails
        }

        if (!email) {
            console.error('Email is not set, aborting Stripe setup.');
            return; // Exit early if email is not set
        }

        console.log("Email from state! email:", email);

        if (!hasBilling) { // Proceed only if user does not have billing details
            try {
                // Create customer setup intent

                // Get the Rewardful referral information
                let referral = null;
                window.rewardful('ready', function () {
                    referral = window.Rewardful.referral;
                });
                console.log('Rewardful referral:', referral);

                const stripeResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/create-customer-setup-intent`, {
                    email,
                    userId,
                    referral //added for rewardful
                });
                const { customerId, setupIntentClientSecret } = stripeResponse.data;
                localStorage.setItem('customerId', customerId);
                console.log('Created customer id and set up intent, Customer ID set in local storage:', customerId);

                // Check if payment methods are saved to customer
                try {
                    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/check-payment-methods`, {
                        customerId,
                    });
                    console.log('Customer payment methods:', response.data);
                } catch (error) {
                    console.error('Failed to check payment methods:', error);
                }

                // Create a Checkout session
                const checkoutResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/billing-details`, {
                    customerId,
                    assistantId,
                    referral //added for rewardful
                });

                console.log('assistantId again:', assistantId);

                const stripe = await stripePromise;
                const { error } = await stripe.redirectToCheckout({
                    sessionId: checkoutResponse.data.id
                });

                if (error) {
                    console.error('Stripe redirect error:', error);
                } else {
                    // Add event listener for success handling
                    window.addEventListener('load', async () => {
                        const urlParams = new URLSearchParams(window.location.search);
                        const sessionId = urlParams.get('session_id');

                        if (sessionId) {
                            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/handle-payment-success`, {
                                sessionId,
                                userId
                            });
                        }
                    });
                }

            } catch (error) {
                console.error('Error redirecting to Stripe:', error);
            }
        } else {
            navigate('/assistants');
        }
    };



    if (!show) return null; // Do not render the modal if show is false


    return (
        <div className="fixed inset-0 z-10 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="flex flex-col w-[600px] items-center justify-center bg-[#eeece7] p-8 rounded-2xl shadow-lg">
                <div className='flex justify-between w-full mb-5 -mt-3'>
                    <div className='rounded-full p-2 bg-[#f4ccc9]'>
                        <RiRobot3Line size={24} />
                    </div>
                    {(hasBilling || isDeleted) && (
                        <button onClick={onClose} className=''>
                            <IoIosClose size={36} />
                        </button>
                    )}
                </div>
                {!isDeleting && !isDeleted ? (
                    <>
                        <p className='font-semibold text-lg'>Your virtual concierge has been successfully created!</p>
                        <div className='flex space-x-3 items-center'>
                            {hasBilling ? (
                                <p className='text-[40px] font-bold'>{mostRecentAssistant?.deploymentPhoneNumber}</p>
                            ) : (
                                <p className='text-[40px] font-bold'>+1 (408) 858 ****</p>
                            )}
                            <div className='bg-[#eeece7] rounded-lg hover:bg-[#f4ccc9] p-4'>
                                <IoCopyOutline size={24} />
                            </div>
                        </div>
                        {hasBilling ? (
                            <p className='text-sm text-center'>
                                Your assistant has been successfully created. Your renters can access it by texting this number. Your subscription has been updated to reflect these changes. You can manage this in settings.
                            </p>
                        ) : (
                            triedDeleting === false ? (
                                <p className='text-sm text-center'>
                                    {isGhost ? (
                                        <span>Your assistant can be accessed by texting this number and will be shown in your dashboard once you register for an account.</span>
                                    ) : (
                                        <span>Your assistant has been created successfully! To access, please proceed to billing and claim <span className='font-bold'>two weeks free...</span></span>
                                    )}
                                </p>
                            ) : (
                                <p className='text-sm text-center'>
                                    Are you sure you want to delete? After deleting, your work is permanently lost, and your phone number will be inaccessible. <span className='font-bold text-blue-400'>Try a two week free trial instead.</span> We will notify you before your card is charged.
                                </p>
                            )
                        )}
                        {!hasBilling ? (
                            <div className='flex items-center space-x-[350px]'>
                                <button onClick={() => {
                                    if (!triedDeleting) {
                                        setTriedDeleting(true);
                                    } else {
                                        handleDeleteClick();
                                    }
                                }} className="mt-4 w-[100px] p-2 bg-red-500 text-white rounded-full">
                                    Delete
                                </button>
                                <button onClick={handleTryItClick} className="mb-2 mt-6 text-md font-semibold create-button mr-3">
                                    try it
                                </button>
                            </div>
                        ) : (
                            <button onClick={onClose} className='rounded-md ring-2 mt-6 ring-slate-700 bg-[#f4ccc9] p-2 w-full'>
                                Lets Go!
                            </button>
                        )}
                    </>
                ) : isDeleting && !isDeleted ? (
                    <div className='mb-10'>
                        <p className='font-semibold text-lg mb-5'>Deleting Your Assistant...</p>
                        <div className='bounce'></div>
                    </div>
                ) : (
                    <div className='flex flex-col space-y-3 mb-5'>
                        <p className='font-semibold text-lg text-center text-green-600 mb-3'>
                            Assistant deleted successfully!
                        </p>
                        <p className='font-medium w-96 text-center mb-4'>
                            Remember, you can allways try your assistants before you buy on the create page. Come back soon!
                        </p>
                        <button onClick={onClose} className='rounded-md ring-2 mt-6 ring-slate-700 bg-[#f4ccc9] p-2 w-full'>
                            Close
                        </button>
                    </div>
                )}
            </div>
        </div>
    );

    // return (
    //     <div className="fixed inset-0 z-10 bg-black bg-opacity-50 flex justify-center items-center">
    //         <div className="flex flex-col w-[600px] items-center justify-center bg-[#eeece7] p-8 rounded-2xl shadow-lg">
    //             <div className='flex justify-between w-full mb-5 -mt-3'>
    //                 <div className='rounded-full p-2 bg-[#f4ccc9]'>
    //                     <RiRobot3Line size={24} />
    //                 </div>
    //                 {!hasBilling ? '' : (
    //                     <button onClick={onClose} className=''>
    //                         <IoIosClose size={36} />
    //                     </button>
    //                 )}
    //             </div>
    //             <p className='font-semibold text-lg'>Your virtual concierge has been successfully created!</p>
    //             <div className='flex space-x-3 items-center'>
    //                 {hasBilling ? (
    //                     <p className='text-[40px] font-bold'>{mostRecentAssistant?.deploymentPhoneNumber}</p>
    //                 ) : (
    //                     <p className='text-[40px] font-bold'>+1 (408) 858 ****</p>
    //                 )}
    //                 <div className='bg-[#eeece7] rounded-lg hover:bg-[#f4ccc9] p-4'>
    //                     <IoCopyOutline size={24} />
    //                 </div>
    //             </div>
    //             {hasBilling ? (
    //                 <p className='text-sm text-center'>
    //                     Your assistant has been successfully created. Your renters can access it by texting this number. Your subscription has been updated to reflect these changes. You can manage this in settings.
    //                 </p>
    //             ) : (
    //                 triedDeleting === false ? (
    //                     <p className='text-sm text-center'>
    //                         {isGhost ? (
    //                             <span>Your assistant can be accessed by texting this number and will be shown in your dashboard once you register for an account.</span>
    //                         ) : (
    //                                     <span>Your assistant has been created successfully! To access, please proceed to billing and claim <span className='font-bold'>two weeks free...</span></span>
    //                         )}
    //                     </p>
    //                 ) : (
    //                     <p className='text-sm text-center'>
    //                         Are you sure you want to delete? After deleting, your work is permanently lost, and your phone number will be inaccessible. <span className='font-bold text-blue-400'>Try a two week free trial instead.</span> We will notify you before your card is charged.
    //                     </p>
    //                 )
    //             )}
    //             {!hasBilling ? (
    //                 <div className='flex items-center space-x-[350px]'>
    //                     <button onClick={() => {
    //                         if (!triedDeleting) {
    //                             setTriedDeleting(true);
    //                         } else {
    //                             handleDeleteClick();
    //                         }
    //                     }} className="mt-4 w-[100px] p-2 bg-red-500 text-white rounded-full">
    //                         Delete
    //                     </button>
    //                     <button onClick={handleTryItClick} className="mb-2 mt-6 text-md font-semibold create-button mr-3">
    //                         try it
    //                     </button>
    //                 </div>
    //             ) : (
    //                 <button onClick={onClose} className='rounded-md ring-2 mt-6 ring-slate-700 bg-[#f4ccc9] p-2 w-full'>
    //                     Lets Go!
    //                 </button>
    //             )}
    //         </div>
    //     </div>
    // );
};


const QRCodeModal = ({ show, onClose, qrCodeURL }) => {
    if (!show) return null;
    return (
        <div className="fixed inset-0 z-10 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="flex flex-col w-[600px] items-center justify-center bg-[#eeece7] p-8 rounded-3xl shadow-lg">
                <div className='flex justify-between w-full mb-5 -mt-3'>
                    <div className='rounded-full p-2 bg-[#f4ccc9]'>
                        <RiRobot3Line size={24} />
                    </div>
                    <button onClick={onClose} className=''>
                        <IoIosClose size={36} />
                    </button>
                </div>
                <img src={qrCodeURL} alt="QR Code" className='mb-10'/>
            </div>
        </div>
    )
}

const Assistants = () => {
    // const isCheckingAuth = useAuthCheck()
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);


    const [showQRCode, setShowQRCode] = useState(false);
    const [qrCodeURL, setQrCodeURL] = useState('');


    const handleShowQRCode = () => {
        setQrCodeURL(selectedAssistant.qrCodeURL);
        setShowQRCode(true);
    };

    const handleCloseQRCode = () => {
        setShowQRCode(false);
    };

    const [files, setFiles] = useState([])
    const fileInputRef = useRef(null);
    const [showMenu, setShowMenu] = useState(false);
    const [showStats, setShowStats] = useState(false)
    const menuRef = useRef();
    const statsRef = useRef();
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const [assistants, setAssistants] = useState([]);
    const [selectedAssistant, setSelectedAssistant] = useState(null);
    const userToken = localStorage.getItem("userToken")
    const [loading, setLoading] = useState(true)
    const [hasBilling, setHasBilling] = useState(true)
    const [assistantId, setAssistantId] = useState('')
    const [email, setEmail] = useState('')

    const assistantsInLocal = localStorage.getItem('numAssistants')

    const interactiveRef = useRef(null);
    const [isGhost, setIsGhost] = useState(false);
    const [showText, setShowText] = useState(false);
    const navigate = useNavigate();
    const [greeting, setGreeting] = useState();
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [mostRecentAssistant, setMostRecentAssistant] = useState();

    const token = localStorage.getItem("userToken")

    const [lockedAssistantToShow, setLockedAssistantToShow] = useState(null);

    


    useEffect(() => {
        setIsGhost(isGhostUser())
        console.log('setting is ghost:', isGhostUser())
    }, [])



    useEffect(() => {
        const verifyPaymentSuccess = async () => {
            const searchParams = new URLSearchParams(location.search);
            const sessionId = searchParams.get('session_id');
            console.log('Session ID from URL:', sessionId);

            if (sessionId) {
                try {
                    // Verify the session ID with your backend
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/verify-session/${sessionId}`);
                    console.log('Response from verify session:', response.data);

                    if (response.data.success) {
                        const userId = jwtDecode(token).id;
                        console.log('Payment verified, updating billing details for user ID:', userId);

                        // Update user's billing details
                        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/users/update/${userId}`, {
                            hasBillingDetails: true
                        }, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        console.log('Billing details updated successfully');

                        // Handle payment success and create subscription
                        const subscriptionCreateResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/handle-payment-success`, {
                            sessionId,
                            userId
                        });
                        console.log('Subscription create response:', subscriptionCreateResponse.data);

                        // Get the customer ID and payment method ID
                        const { paymentMethodId } = subscriptionCreateResponse.data;
                        const userResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/get-user/${userId}`);
                        const customerId = userResponse.data.stripeCustomerId;

                        // Check payment methods after payment completion
                        if (paymentMethodId) {
                            const paymentMethodsResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/check-payment-methods`, {
                                customerId,
                            });
                            console.log('Customer payment methods:', paymentMethodsResponse.data);
                        } else {
                            console.error('No payment method ID found.');
                        }

                        // Reload the assistant list and delete old assistants
                        await reloadAndCleanAssistantList(userId, token);

                        // Navigate to the dashboard or desired page
                        setHasBilling(true);
                        navigate("/assistants", { state: { from: '/creating-first-assistant' } });
                    } else {
                        console.error('Payment verification failed:', response.data);
                    }
                } catch (error) {
                    console.error('Failed to verify payment session:', error);
                }
            }
        };

        const reloadAndCleanAssistantList = async (userId, token) => {
            try {
                console.log('Fetching assistants...');
                const assistantsResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/get`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const assistants = assistantsResponse.data;
                console.log('Fetched assistants:', assistants);

                // Sort assistants by creation date to find the most recent one
                assistants.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                console.log('Sorted assistants:', assistants);

                // Keep the most recent assistant and delete the rest
                const [mostRecentAssistant, ...oldAssistants] = assistants;
                console.log('Most recent assistant:', mostRecentAssistant);
                setMostRecentAssistant(mostRecentAssistant);
                console.log('Old assistants to delete:', oldAssistants);

                for (const assistant of oldAssistants) {
                    console.log(`Deleting assistant with ID: ${assistant._id}`);
                    await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/delete/${assistant._id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    console.log(`Deleted assistant with ID: ${assistant._id}`);
                }

                console.log('Fetching updated list of assistants...');
                const updatedAssistantsResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/get`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log('Updated assistants:', updatedAssistantsResponse.data);
                setAssistants(updatedAssistantsResponse.data); // Update the state with the new list
            } catch (error) {
                console.error('Failed to reload and clean assistant list:', error);
            }
        };

        verifyPaymentSuccess();
    }, [location.search, token, navigate]);







    //open modal if && !user
    useEffect(() => {
        const checkBilling = async () => {
            if (token && !isGhost) {
                console.log("RUNNING CHECKBILLING... going to set the email and hasBilling")
                console.log('Token found:', token);
                const decodedToken = jwtDecode(token);
                const userId = decodedToken.id;
                console.log('Decoded user ID:', userId);

                try {
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/get-user/${userId}`);
                    console.log('Response from get user:', response);

                    const { hasBillingDetails, accountEmail } = response.data;
                    console.log("accountEmail:", accountEmail)

                    setHasBilling(hasBillingDetails);
                    setEmail(accountEmail);
                    setAssistantId(sessionStorage.getItem('assistantId'));
                } catch (error) {
                    console.error('Failed to check billing status:', error);
                }
            }
        };

        checkBilling();

        if (location.state?.from === '/creating-assistant') {
            setShowModal(true);
        } else if (location.state?.from === '/creating-first-assistant') {
            setShowModal(true);
        }
    }, [location, token, isGhost]);

    const handleCloseModal = () => {
        setShowModal(false);
    }

    // Handle clicking outside of the menu to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    const toggleStats = () => {
        setShowStats(!showStats)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (statsRef.current && !statsRef.current.contains(event.target)) {
                setShowStats(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [statsRef])

    useEffect(() => {
        const getAssistants = async () => {
            const token = localStorage.getItem('userToken');
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/get-not-locked`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                const assistantsData = response.data;
                setAssistants(assistantsData);

                // Set mostRecentAssistant
                setMostRecentAssistant(assistantsData.length > 0 ? assistantsData[assistantsData.length - 1] : null);
            } catch (error) {
                console.error("error getting assistant", error);
            } finally {
                setLoading(false);
            }
        };
        getAssistants();
    }, []);



    useEffect(() => {
        const getMostRecentLockedAssistant = async () => {
            const token = localStorage.getItem('userToken');
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/get`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                const lockedAssistants = response.data;
                const mostRecentLockedAssistant = lockedAssistants.length > 0 ? lockedAssistants[lockedAssistants.length - 1] : null;
                setLockedAssistantToShow(mostRecentLockedAssistant);
                console.log('Locked assistants:', lockedAssistants);
                console.log('Most recent locked assistant:', mostRecentLockedAssistant);

                if (showModal && mostRecentLockedAssistant) {
                    setSelectedAssistant(mostRecentLockedAssistant);
                    console.log("selected assistant:", mostRecentLockedAssistant)
                }
            } catch (error) {
                console.error('Failed to get locked assistants:', error);
            }
        };

        if (showModal) {
            getMostRecentLockedAssistant();
        }
    }, [showModal]);  // Add showModal to the dependency array


    useEffect(() => {
        if (assistants.length > 0) {
            setSelectedAssistant(assistants[0]);
            console.log(`you have ${assistants.length} assistants`)
        }
    }, [assistants]);

    const handleCardClick = (assistant) => {
        setSelectedAssistant(assistant);
        //added this remove if necissary
        // setQrCodeURL(selectedAssistant.qrCodeURL);
    };

    const [showDefaultMessage, setShowDefaultMessage] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (assistants.length === 0) {
                setShowDefaultMessage(true);
            } else {
                setShowDefaultMessage(false)
            }
        }, 1000); // 1 second delay

        return () => clearTimeout(timeout); // Clean up the timeout on component unmount
    }, []);


    return (
        <div className='flex bg-[#161616] h-screen'>
            {!showModal ? null : <Modal show={showModal} onClose={handleCloseModal} hasBilling={hasBilling} email={email} assistantId={assistantId} token={token} isGhost={isGhost} mostRecentAssistant={mostRecentAssistant} assistants={assistants} setAssistants={setAssistants} setSelectedAssistant={setSelectedAssistant} />}
            {!showQRCode ? null : <QRCodeModal show={showQRCode} onClose={handleCloseQRCode} qrCodeURL={selectedAssistant.qrCodeURL} />}
            <div>
                <SideBar />
            </div>
            <div className='flex flex-col w-full mt-3 -ml-3 overflow-hidden p-3 rounded-2xl bg-[#eeece7]'>
                <HeaderMain />
                {/* Scrollable div here */}
                <div className='flex flex-col pb-20 mr-3 w-full p-4 overflow-auto rounded-2xl bg-[#eeece7]'>
                    <div className='p-5'>
                        {loading ? (
                            <div className='flex flex-col mt-10 ml-3 space-y-4 items-center justify-center justify-self-center place-items-center'>
                                {/* <div className='bounce'></div> */}
                            </div>
                        ) : showModal && lockedAssistantToShow ? (
                            <div>
                                {/* Content to display when modal is showing and there is a locked assistant */}
                                <div className='flex space-x-5 overflow-x-auto pr-1'>
                                    <SampleCard
                                        key={lockedAssistantToShow._id}
                                        assistant={lockedAssistantToShow}
                                        onClick={() => handleCardClick(lockedAssistantToShow)}
                                        isActive={selectedAssistant === lockedAssistantToShow}
                                        className={selectedAssistant === lockedAssistantToShow ? 'ring ring-[#f6656c] ring-opacity-50' : ''}
                                    />
                                </div>
                                {selectedAssistant && (
                                    <AssistantDetails assistant={selectedAssistant} onShowQRCode={handleShowQRCode} showModal={showModal}/>
                                )}
                            </div>
                        ) : (
                            <>
                                {assistants.length === 0 ? (
                                    <div className='flex flex-col mt-5 space-y-4 items-center justify-center justify-self-center place-items-center'>
                                        <p className='text-grey-400 font-bold text-3xl'>Create your first assistant</p>
                                        <p>Nothing here yet sorry...</p>
                                        <Link to={'/create-assistant'} className='mb-5 p-1 rounded-2xl px-3 py-2 bg-[#f4ccc9] inline-block hover:bg-white'>Create Assistant</Link>
                                    </div>
                                ) : (
                                    <div className='mb-3 ml-5'>
                                        <Link to={'/analytics'} className='mb-5 p-1 rounded-2xl px-3 py-2 bg-white inline-block hover:bg-[#f4ccc9]'>see all →</Link>
                                        <div className='flex space-x-5 overflow-x-auto pr-1'>
                                            {assistants.map(assistant => (
                                                <SampleCard
                                                    key={assistant._id}
                                                    assistant={assistant}
                                                    onClick={() => handleCardClick(assistant)}
                                                    isActive={selectedAssistant === assistant}
                                                    className={selectedAssistant === assistant ? 'ring ring-[#f6656c] ring-opacity-50' : ''}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {selectedAssistant && (
                                    <AssistantDetails assistant={selectedAssistant} onShowQRCode={handleShowQRCode} />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Assistants;


