import React, { useState, useEffect, memo } from 'react';

// Import all your images
import landingHouse from '../assets/landing-house.png';
import mexicoHouse from '../assets/mexico-house.jpeg'
import img3 from '../assets/triangle-forrest-house.jpeg';
import airbnb from '../assets/Airbnb-Logo.wine.png';
import vrbo from '../assets/Vrbo-Logo.wine.svg';
import checkIn from '../assets/Woman on City Street.jpg';
import luxuryAirbnb from '../assets/Luxury Airbnb Rental.jpeg';
import stretching from '../assets/Luxury Hotel with People.jpg';
import swimming from '../assets/Woman Soaking Swimming Pool.jpg';
import beachCar from '../assets/Beach Vehicle Aerial.jpg';
import texting from '../assets/Texting Photos Jeshoots.jpg';
import computer from '../assets/Vacation Home People.jpg';
import pool from '../assets/Luxury Hotel Couple Beach.jpg';
import logo from '../assets/logo.png'
import snowHouse from '../assets/snow-house.jpeg'
import greenBeach from '../assets/green-beach.jpg'

import astheticVacationHouse from '../assets/Aesthetic Vacation House.jpg'
import astheticVacationPhoto from '../assets/Aesthetic Vacation Photo.jpg'
import hotelBellReception from '../assets/Antique Hotel Bell Reception.jpg'
import brownWoodenHouse from '../assets/Brown Wooden House with Pool.jpg'

import islandAerialView from '../assets/Aerial Mountain Pool.jpg'
import islandAerialBoats from '../assets/Aerial Boats.jpg'
import islandAerialPool from '../assets/Aerial Beach Pool.jpg'
import islandAerialHotel from '../assets/Island Aerial View.jpg'

import mountainLakeView from '../assets/Mountain Lake View.jpg'
import relaxingVacationElliot from '../assets/Relaxing Vacation Taryn Elliott.jpg'
import tropicalResortVilla from '../assets/Tropical Resort Villa.jpg'
import villasNearBeach from '../assets/Villas Near Beach Photo.jpg'
import swanOnWater from '../assets/White Swan on Water.jpg'




// Create an object to store all imported images
const images = {
    logo,
    snowHouse,
    landingHouse,
    swimming,
    beachCar,
    img3,
    airbnb,
    vrbo,
    pool,
    computer,
    texting,
    stretching,
    luxuryAirbnb,
    checkIn,
    mexicoHouse,
    greenBeach,

    astheticVacationHouse,
    astheticVacationPhoto,
    hotelBellReception,
    brownWoodenHouse,
    islandAerialView,
    islandAerialBoats,
    islandAerialPool,
    islandAerialHotel,
    mountainLakeView,
    relaxingVacationElliot,
    tropicalResortVilla,
    villasNearBeach,
    swanOnWater,

};

// LazyBackgroundImage component
const LazyBackgroundImage = ({ name, className, children }) => {
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        const img = new Image();
        img.src = images[name];
        img.onload = () => setImageSrc(images[name]);
    }, [name]);

    return (
        <div
            className={className}
            style={{
                backgroundImage: imageSrc ? `url(${imageSrc})` : 'none',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            {children}
        </div>
    );
};

// LazyImage component (previously in a separate file)
const LazyImage = memo(({ src, alt, className, style }) => {
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => setImageSrc(src);
    }, [src]);

    if (!imageSrc) {
        return <div>Loading...</div>;
    }

    return (
        <img
            src={imageSrc}
            alt={alt}
            className={className}
            style={style}
        />
    );
});

// LazyLoadedImage component
const LazyLoadedImage = ({ name, alt, className, style }) => {
    return (
        <LazyImage
            src={images[name]}
            alt={alt}
            className={className}
            style={style}
        />
    );
};

// Export components for use in other files
export { LazyBackgroundImage, LazyLoadedImage };