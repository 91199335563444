import React, { useEffect } from 'react'
import logo from '../assets/logo.png'
import { Link, NavLink } from 'react-router-dom'
import { BsCalendar2EventFill } from "react-icons/bs";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { PopupButton } from 'react-calendly';
import { LazyLoadedImage } from '../components/LazyImages';
import { useLocation } from 'react-router-dom';
import { TbArrowBackUp } from "react-icons/tb";



export default function LandingHeader({ refs }) {
    const navigate = useNavigate();
    const location = useLocation();

    const scrollToSection = (sectionRef) => {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleGhost = async () => {
        // e.preventDefault();
        console.log('running handler');
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/ghost`);
            console.log('ghost:', response);
            const { userId } = response.data;

            localStorage.setItem('userToken', response.data.userToken);
            console.log('Token stored:', localStorage.getItem('userToken'));

            sessionStorage.setItem('ghostId', userId);
            navigate("/create-assistant", { state: { from: '/landing-page' } });
        } catch (error) {
            console.error('error with ghost:', error);
        }
    };

    useEffect(() => {
        // Dynamically load the Calendly script if needed
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // Clean up the script when the component unmounts
            document.body.removeChild(script);
        };
    }, []);

    const handleCalendlyPopup = (e) => {
        e.preventDefault();
        if (window.Calendly) {
            window.Calendly.initPopupWidget({
                url: 'https://calendly.com/guestlink/find-out-more?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=f6656c',
            });
        } else {
            console.error('Calendly script is not loaded yet.');
        }
    };



    return (
        <header className='flex justify-between h-16 items-center mb-4 mt-3 '>
            <div className="logo">
                <Link to="/" className="flex items-center space-x-2 px-4 h-16 mt-3 mb-5">
                    <LazyLoadedImage name="logo" alt="GuestLink Logo" className='h-12 w-12' />
                    <h1 className=' -ml-4 text-3xl font-bold'>GuestLink</h1>
                </Link>
            </div>
            <nav className='flex items-center text-lg font-medium space-x-2 text-black'>
                    {
                        (location.pathname === '/blog-directory') ? 
                        // <Link to={'/'} className='bg-white p-3 rounded-full ml-auto hover:bg-[#f4ccc9] transition duration-100'>
                        //     <TbArrowBackUp size={24} />
                        // </Link>
                        <ul className='flex space-x-12 text-lg font-medium mr-6'>
                            <li><NavLink to={'/'}>About</NavLink></li>
                            <li><NavLink to={'/blog-directory'}>Blogs</NavLink></li>
                            {/* <li><button onClick={handleGhost} className='try-it'>Try It!</button></li> */}
                            <li><NavLink to={'/'}>Prices</NavLink></li>
                            <li><NavLink to={'/'}>FAQ's</NavLink></li>
                        </ul>
                        : 
                        <ul className='flex space-x-12 text-lg font-medium mr-6'>
                            <li><button onClick={() => scrollToSection(refs.aboutRef)}>About</button></li>
                            <li><NavLink to={'/blog-directory'}>Blogs</NavLink></li>
                            <li><button onClick={handleGhost} className='try-it'>Try It!</button></li>
                            <li><button onClick={() => scrollToSection(refs.pricesRef)}>Prices</button></li>
                            <li><button onClick={() => scrollToSection(refs.faqRef)}>FAQ's</button></li>
                        </ul>
                    }
                <Link to="/register" className='border-2 border-black px-4 py-2 rounded-full'>
                    Login or Sign Up
                </Link>
                <div className='flex items-center space-x-2 bg-black text-white px-4 py-2 rounded-full'>
                    <PopupButton
                        url='https://calendly.com/guestlink/find-out-more?hide_gdpr_banner=1&primary_color=f6656c'
                        rootElement={document.getElementById('root')}
                        text='Book for Enterprise'
                        textColor='#ffffff'
                        color='#00a2ff'
                    />
                    <BsCalendar2EventFill aria-hidden="true" />
                </div>
            </nav>
        </header>
    );
}
