import React, { useState, useRef } from 'react';
import { LazyLoadedImage } from '../../components/LazyImages';
import { Link } from 'react-router-dom';
import { MoveDown } from 'lucide-react';
import { HiOutlineMail } from "react-icons/hi";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Blog2 = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('')


    const introRef = useRef(null);
    const powerOfReturnRef = useRef(null);
    const introducingGuestLinkRef = useRef(null);
    const automatedOptInRef = useRef(null);
    const nurturingListRef = useRef(null);
    const directBookingsRef = useRef(null);
    const strategiesRef = useRef(null);
    const bestPracticesRef = useRef(null);
    const measuringSuccessRef = useRef(null);
    const embracingFutureRef = useRef(null);

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleGhost = async () => {
        // e.preventDefault();
        console.log('running handler');
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/ghost`);
            console.log('ghost:', response);
            const { userId } = response.data;

            localStorage.setItem('userToken', response.data.userToken);
            console.log('Token stored:', localStorage.getItem('userToken'));

            sessionStorage.setItem('ghostId', userId);
            navigate("/create-assistant", { state: { from: '/landing-page' } });
        } catch (error) {
            console.error('error with ghost:', error);
        }
    };


    return (
        <div className="blog-content min-h-screen bg-[#f5f4f0]">
            {/* Hero Section */}
            <header className="relative h-[400px] mb-12">
                <LazyLoadedImage
                    name="computer"
                    alt="Host using GuestLink AI to manage their short-term rental"
                    className="absolute inset-0 w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="text-center text-white px-4">
                        <h1 className="text-4xl md:text-6xl font-bold mb-4">
                            Self Manage your Short Term Rental: How GuestLink AI Helps Grow Your Return Renter List and Boost Direct Bookings
                        </h1>
                        <p className="text-lg md:text-xl max-w-3xl mx-auto">
                            Discover how GuestLink AI revolutionizes short-term rental management, helping you build loyalty and increase profitability.
                        </p>
                    </div>
                </div>
            </header>

            <main className="container mx-auto px-4 md:px-0 flex flex-col md:flex-row">
                <article className="md:w-2/3 md:pr-8">
                    <div className="flex items-center mb-8">
                        <LazyLoadedImage
                            name="texting"
                            alt="Author Avatar"
                            className="w-12 h-12 rounded-full mr-4"
                        />
                        <div>
                            <h2 className="font-semibold">Peyton Allworth</h2>
                            <p className="text-sm text-gray-600">Published 3 Aug 2024</p>
                        </div>
                    </div>

                    <section ref={introRef} className="mb-8">
                        <p className="mb-4">
                            In the ever-evolving world of short-term rentals, self-management has become an increasingly popular option for property owners. While it offers greater control and potentially higher profits, it also comes with its own set of challenges. One of the biggest hurdles? Building and maintaining a loyal base of return renters while encouraging direct bookings. Enter GuestLink AI, a game-changing tool that's revolutionizing how hosts manage their properties and interact with guests.
                        </p>
                    </section>

                    <section ref={powerOfReturnRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">The Power of Return Renters and Direct Bookings</h2>
                        <p className="mb-4">
                            Before we dive into how GuestLink AI works its magic, let's talk about why return renters and direct bookings are so crucial for self-managing hosts. Think about it - when you have guests coming back time and time again, you're not just filling your calendar. You're creating a stable, predictable income stream. These familiar faces require less hand-holding, they know your property, and they're more likely to treat it with respect. Plus, they're your best advertisers, often recommending your place to friends and family.
                        </p>
                        <p className="mb-4">
                            But the real golden ticket? Direct bookings. When guests book directly with you, you're cutting out the middleman - and their fees. This means more money in your pocket and often, a better deal for your guests too. It's a win-win situation that can significantly boost your profitability.
                        </p>
                        <p>
                            However, building this loyal customer base and encouraging direct bookings isn't always easy. It takes time, effort, and often, a lot of manual work. That's where GuestLink AI comes in, turning this challenging task into a streamlined, automated process.
                        </p>
                    </section>

                    <LazyLoadedImage
                        name="swimming"
                        alt="GuestLink AI dashboard screenshot"
                        className="w-full h-64 object-cover rounded-lg mb-8"
                    />

                    <section ref={introducingGuestLinkRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Introducing GuestLink AI: Your Self-Management Sidekick</h2>
                        <p className="mb-4">
                            GuestLink AI is more than just another property management tool. It's like having a tireless assistant who's always on the clock, working to grow your return renter list and boost your direct bookings. But how does it work? Let's break it down.
                        </p>
                        <h3 className="text-xl font-semibold mb-2">Setting Up for Success</h3>
                        <p className="mb-4">
                            Getting started with GuestLink AI is a breeze. After you sign up, you'll create your AI assistant by uploading your property's guidebook. This is where the magic begins - your AI assistant gets to know your property inside and out, ready to answer guest queries at any time.
                        </p>
                        <p className="mb-4">
                            The next step is syncing your rental calendar. Whether you're using Airbnb, VRBO, or any other platform, GuestLink AI can pull in your booking information. This synchronization is crucial for what comes next. <a href="https://guestlinkai.com/calendar-sync" className="text-blue-600 hover:text-blue-800">Learn how to sync your calendar here</a>.
                        </p>
                    </section>

                    <section ref={automatedOptInRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">The Automated Opt-In</h2>
                        <p className="mb-4">
                            Here's where GuestLink AI really shines. Remember those tedious post-stay follow-ups you used to do (or maybe forgot to do)? GuestLink AI handles all of that automatically. When a guest checks out, they receive a message that does three key things:
                        </p>
                        <ol className="list-decimal pl-6 mb-4">
                            <li>It congratulates them on their stay, leaving a positive last impression.</li>
                            <li>It provides clear check-out instructions, ensuring a smooth departure.</li>
                            <li>Most importantly, it invites them to opt-in for future updates and discounts.</li>
                        </ol>
                        <p className="mb-4">
                            This opt-in feature is the cornerstone of building your return renter list. It's non-intrusive, value-adding, and incredibly effective. Guests who choose to opt-in are automatically added to your list, viewable right from your GuestLink AI dashboard.
                        </p>
                    </section>

                    <section ref={nurturingListRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Nurturing Your Return Renter List</h2>
                        <p className="mb-4">
                            Once you've started building your list, GuestLink AI makes it easy to keep in touch with your past guests. From your dashboard, you can send push notifications to your opted-in guests. This is your chance to offer exclusive discounts, alert them to upcoming availability, or simply keep your property top-of-mind.
                        </p>
                        <p className="mb-4">
                            The best part? GuestLink AI automatically adds an opt-out message to every communication. This ensures your guests never feel trapped or spammed, maintaining a positive relationship even if they choose to unsubscribe.
                        </p>
                    </section>

                    <LazyLoadedImage
                        name="beachCar"
                        alt="GuestLink AI dashboard screenshot"
                        className="w-full h-64 object-cover rounded-lg mb-8"
                    />

                    <section ref={directBookingsRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Direct Bookings Made Easy</h2>
                        <p className="mb-4">
                            With your return renter list in place, encouraging direct bookings becomes much simpler. You can use GuestLink AI to send targeted promotions, highlighting the benefits of booking directly. By bypassing platform fees, you can offer competitive rates while still increasing your profit margin. It's a compelling proposition for guests who already know and love your property.
                        </p>
                    </section>

                    <section ref={strategiesRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Strategies for Success</h2>
                        <p className="mb-4">
                            While GuestLink AI provides the tools, it's up to you to craft the strategy. Here are a few ideas to get you started:
                        </p>
                        <ol className="list-decimal pl-6 mb-4">
                            <li><strong>Personalized Discounts:</strong> Use the data from previous stays to offer tailored discounts. For instance, guests who've stayed during peak season might be enticed by an off-season special rate.</li>
                            <li><strong>Last-Minute Deals:</strong> Have an unexpected opening? Use GuestLink AI to quickly notify your return renters of the availability and any special last-minute rates.</li>
                            <li><strong>Loyalty Programs:</strong> Consider implementing a simple loyalty program. For example, offer a discount after a certain number of stays. GuestLink AI can help you track and communicate these offers.</li>
                            <li><strong>Referral Incentives:</strong> Encourage your happy return renters to spread the word. Offer a discount for successful referrals, using GuestLink AI to manage the process.</li>
                        </ol>
                    </section>

                    <section ref={bestPracticesRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Best Practices for Communication</h2>
                        <p className="mb-4">
                            While GuestLink AI makes it easy to stay in touch with your return renters, it's important to use this tool wisely. Here are a few tips:
                        </p>
                        <ul className="list-disc pl-6 mb-4">
                            <li><strong>Quality Over Quantity:</strong> Don't bombard your list with constant messages. Use GuestLink AI to space out your communications, focusing on quality, valuable content.</li>
                            <li><strong>Mix It Up:</strong> Alternate between promotional messages and useful information about your property or local area. This keeps your communications interesting and valuable to your guests.</li>
                            <li><strong>Timing is Everything:</strong> Pay attention to booking patterns and seasonal trends. GuestLink AI can help you identify the best times to reach out to your list.</li>
                            <li><strong>Be Transparent:</strong> Always be clear about the benefits of direct booking. Use GuestLink AI to highlight the savings for both you and the guest.</li>
                        </ul>
                    </section>

                    <section ref={measuringSuccessRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Measuring Your Success</h2>
                        <p className="mb-4">
                            One of the best features of GuestLink AI is its ability to provide valuable insights into your return renter strategy. You can easily track metrics like your opt-in rate, direct booking conversion rate, and the frequency of return renter stays. This data is invaluable for refining your approach and maximizing your return on investment.
                        </p>
                    </section>

                    <section ref={embracingFutureRef} className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Embracing the Future of Self-Management</h2>
                        <p className="mb-4">
                            In today's competitive short-term rental market, tools like GuestLink AI aren't just nice to have - they're essential for hosts looking to stay ahead of the curve. By automating the process of building and nurturing your return renter list, and by making it easy to encourage direct bookings, GuestLink AI allows you to focus on what really matters: providing an exceptional experience for your guests.
                        </p>
                        <p className="mb-4">
                            The future of self-management in the short-term rental space is here, and it's powered by AI. With GuestLink AI, you're not just keeping up with the industry - you're positioning yourself at the forefront of it. You're building stronger relationships with your guests, increasing your profitability, and doing it all with less manual effort.
                        </p>
                        <p>
                            Ready to revolutionize your short-term rental management? It's time to give GuestLink AI a try. Start building your return renter list, boost your direct bookings, and watch your business thrive. The future of self-management is calling - are you ready to answer?
                        </p>
                    </section>

                    <div className="mb-8">
                        <button onClick={handleGhost} className="adjustable-create-button">
                            Try GuestLink AI Today
                        </button>
                    </div>
                </article>

                <aside className="sidebar md:w-1/3 mt-12 md:mt-0">
                    <div className="p-6 rounded-lg">
                        <div className="mb-6">
                            <LazyLoadedImage name="texting" alt="Alex Thompson" className="w-16 h-16 rounded-full mb-2" />
                            <h2 className="text-xl font-semibold">Peyton Allworth</h2>
                            <p className="text-sm text-gray-600">Published 3 Aug 2024</p>
                        </div>

                        <nav className="mb-8">
                            <h3 className="text-lg font-semibold mb-4">Table of Contents</h3>
                            <ul className="space-y-0">
                                {[
                                    { title: 'Introduction', ref: introRef },
                                    { title: 'The Power of Return Renters and Direct Bookings', ref: powerOfReturnRef },
                                    { title: 'Introducing GuestLink AI', ref: introducingGuestLinkRef },
                                    { title: 'The Automated Opt-In', ref: automatedOptInRef },
                                    { title: 'Nurturing Your Return Renter List', ref: nurturingListRef },
                                    { title: 'Direct Bookings Made Easy', ref: directBookingsRef },
                                    { title: 'Strategies for Success', ref: strategiesRef },
                                    { title: 'Best Practices for Communication', ref: bestPracticesRef },
                                    { title: 'Measuring Your Success', ref: measuringSuccessRef },
                                    { title: 'Embracing the Future of Self-Management', ref: embracingFutureRef }
                                ].map((item, index) => (
                                    <li
                                        key={index}
                                        className={`hover:bg-[#f4ccc9] px-2 flex items-center justify-between border-black border-b-2 ${index === 0 ? 'border-t-2' : ''} py-3 cursor-pointer`}
                                        onClick={() => scrollToSection(item.ref)}
                                    >
                                        <span>{item.title}</span>
                                        <MoveDown />
                                    </li>
                                ))}
                            </ul>
                        </nav>

                        <div className="newsletter-signup">
                            <h3 className="text-xl font-semibold mb-2">Stay Updated</h3>
                            <p className="text-sm text-gray-600 mb-4">Get the latest tips on self-managing your short-term rental property with AI.</p>
                            <form className="space-y-4 flex flex-col justify-center">
                                <div className="relative flex items-center w-full mb-4">
                                    <HiOutlineMail size={18} className="absolute ml-5" />
                                    <input
                                        className="pl-12 bg-white appearance-none rounded-2xl h-12 w-full leading-tight ring-2 ring-black focus:ring-2 focus:ring-[#f6656c] focus:ring-opacity-50 focus:outline-none"
                                        id="email"
                                        type="email"
                                        placeholder="Your Email"
                                        value={email}
                                        onChange={e => { setEmail(e.target.value) }}
                                    />
                                </div>
                                <div className='flex items-center justify-center w-full px-5'>
                                    <button
                                        type="submit"
                                        className="adjustable-create-button w-full"
                                    >
                                        Subscribe
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </aside>
            </main>

            {/* Footer */}
            <footer className='flex flex-col w-full h-[400px] justify-center px-16 py-10 space-y-7 bg-white'>
                <div className='flex justify-between items-center h-full'>
                    <a href="/" className="logo flex items-center space-x-2 px-4 h-16 mt-3 mb-5">
                        <span className="text-black flex items-center text-2xl font-bold space-x-3 ">
                            <LazyLoadedImage name="logo" alt="GuestLink Logo" className='h-12 w-12 -ml-8' />
                            <p className='mb-2 -ml-4 text-3xl'>GuestLink</p>
                        </span>
                    </a>
                    <p className='text-2xl font-bold w-96 text-end'>Streamline your AirBNB Management Today</p>
                </div>
                <div className='flex justify-between pb-10 border-neutral-500 border-b-2 h-full'>
                    <div className='flex items-center space-x-10 font-light'>
                        <div className='space-y-2'>
                            <p>Email</p>
                            <p>hello@guestlinkai.com</p>
                        </div>
                        <div className='space-y-2'>
                            <p>Phone</p>
                            <p>+1 (408) 256-2545</p>
                        </div>
                    </div>
                    <div className='flex space-x-10 items-center'>
                        <button onClick={handleGhost} className='landing-glow-button'>Try It Now</button>
                        <Link to={"/register"} className='border-2 border-black px-4 py-2 rounded-full'>
                            Login or Sign Up
                        </Link>
                    </div>
                </div>
                <div className='flex justify-between font-light h-full text-sm '>
                    <div className='flex items-center space-x-5'>
                        <p>© 2023 GuestLink LLC.</p>
                        <p>All Rights Reserved</p>
                    </div>
                    <div className='flex space-x-8 items-center'>
                        <a
                            href='https://docs.google.com/document/d/e/2PACX-1vSxC7ZSjTLYfp_ZHB9Rna7kzz-10u2wl74pjZxZqbslaNAZKbxkP22UoJEVeOrQvalbF1HkAzZI0Ah3/pub'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy Policy
                        </a>
                        <a
                            href='https://docs.google.com/document/d/e/2PACX-1vR-N20f116EB2zHCuKWhBC6GpwFzpj-rZyUe5Rtp3MZaEnrmudBGStzB-JqZIK0kiPhw1obUGu08xyu/pub'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Terms of Service
                        </a>
                        <a
                            href='https://docs.google.com/document/d/e/2PACX-1vQnitjHjZ6fhtCcRGZxKyHWOOl0hiU9lhYhSpSke26MQ5Wmpf0jmJHbqGNP6iTnflrYD09gLdXaSWqT/pub'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Our Twilio Opt-in Disclosures
                        </a>
                    </div>
                </div>
            </footer>

            <div className='w-full h-[15px] self-center bg-[#FF5A5F]'>
                <p> </p>
            </div>
        </div>
    );
};

export default Blog2;