import React, { useState, useEffect } from 'react'
import '../../assets/BgMountain.jpg'
import { FaUser } from "react-icons/fa6";
import { FaLock } from "react-icons/fa";
import { MdVpnLock } from "react-icons/md";
import { Link } from 'react-router-dom';
import { FaAt } from "react-icons/fa";
import logo from '../../../src/assets/logo.png'
import authService from '../../services/authService'
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
// import { loadStripe } from '@stripe/stripe-js';
import { LazyBackgroundImage } from '../../components/LazyImages';



// const stripePromise = loadStripe("pk_test_51O8APtAVry7Dxu3xUUthjxI0j8lrxyUFrwwkGse2mGeUfGA3rg1AE1rjQCNH8zzfONBaE5vmPxn0j2iyffTKOkXu00Zsm26ot3");

import { loadStripe } from '@stripe/stripe-js';
import { stripePublicKey } from '../../config/stripe';

const stripePromise = loadStripe(stripePublicKey);




const RegisterForm = () => {
    const navigate = useNavigate();
    const [accountEmail, setAccountEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();
    const isRegForGhost = location.pathname === '/reg-g';
    const [assistants, setAssistants] = useState([])
    // let assistantId = null
    const [assistantId, setAssistantId] = useState(null);  // Use state to hold assistantId

    useEffect(() => {
        const getAssistants = async () => {
            const token = localStorage.getItem('userToken');
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/get`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                })
                setAssistants(response.data)
                setAssistantId(response.data[0]._id)
                console.log('assistantId:', assistantId, 'response.data[0]:', response.data[0])
            } catch (error) {
                console.error("error getting assistant", error)
            }
        }
        getAssistants();
    }, [])


    //trying to get billing portal payments attached with set up intent when they register.
    const newHandleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage('');

        try {
            let response = null;
            const token = localStorage.getItem('userToken');
            let userId = null;

            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                    userId = decodedToken.id;
                } catch (error) {
                    console.error('Failed to decode token:', error);
                }
            }

            if (isRegForGhost && userId) {

                response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/users/update/${userId}`, {
                    accountEmail,
                    password,
                    ghost: false
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.status === 200 || response.status === 201) {
                    // Update user token to reflect ghost: false, pass userId to be stored in token
                    const updatedTokenResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/update-token`, { userId });
                    const updatedToken = updatedTokenResponse.data.userToken;
                    localStorage.setItem('userToken', updatedToken);
                    const decodedToken = jwtDecode(updatedToken);
                    console.log('userId:', decodedToken.id, "ghost?", decodedToken.ghost)
                    console.log(decodedToken)

                    //added this for rewardful
                    let referral = null;
                    window.rewardful('ready', function () {
                        referral = window.Rewardful.referral;
                    });
                    console.log('Rewardful referral:', referral);

                    const stripeResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/create-customer-setup-intent`, {
                        email: accountEmail,
                        userId,
                        referral //added this for rewardful
                    });

                    const { customerId, setupIntentClientSecret } = stripeResponse.data;
                    localStorage.setItem('customerId', customerId);




                    //added to check if payment methods were saving to customer
                    try {
                        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/check-payment-methods`, {
                            customerId,
                        });
                        console.log('Customer payment methods:', response.data);
                    } catch (error) {
                        console.error('Failed to check payment methods:', error);
                    }


                    
                    

                    // Create a Checkout session and pass assistant id for possible delete
                    const checkoutResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/billing-details`, {
                        customerId,
                        assistantId,
                        referral //added for rewardful
                    });
                    console.log('assistantId again:', assistantId)

                    const stripe = await stripePromise;
                    const { error } = await stripe.redirectToCheckout({
                        sessionId: checkoutResponse.data.id
                    });

                    if (error) {
                        console.error(error);
                    } 
                    //added from chat remove if not working and revert to old code.
                    else {
                        // Add event listener for success handling
                        window.addEventListener('load', async () => {
                            const urlParams = new URLSearchParams(window.location.search);
                            const sessionId = urlParams.get('session_id');

                            if (sessionId) {
                                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/handle-payment-success`, {
                                    sessionId,
                                    userId
                                });
                            }
                        });
                    }
                    //end of added code
                }
            } else {
                response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/register`, {
                    accountEmail,
                    password
                }, {
                    withCredentials: true
                });
                console.log('logged in and registered moving to rest of customer auth')

                if (response.status === 200 || response.status === 201) {


                    console.log('running cutomer auth')
                    if (!isRegForGhost) {
                        localStorage.setItem('userToken', response.data.userToken);
                    }
                    const userToken = response.data.userToken;
                    const decodedToken = jwtDecode(userToken);
                    userId = decodedToken.id;

                    console.log('userId from reg form reg user:', userId)
                    console.log("account email:", accountEmail)
                    // const stripeResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/create-customer-setup-intent`, {
                    //     email: accountEmail,
                    //     userId
                    // });

                    // const { customerId, setupIntentClientSecret } = stripeResponse.data;
                    // localStorage.setItem('customerId', customerId);




                    // //added to check if payment methods were saving to customer
                    // try {
                    //     const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/check-payment-methods`, {
                    //         customerId,
                    //     });
                    //     console.log('Customer payment methods:', response.data);
                    // } catch (error) {
                    //     console.error('Failed to check payment methods:', error);
                    // }



                    // //could this be not needed? isnt this done on the asistant page? from the modal?
                    // // Create a Checkout session and pass assistant id for possible delete
                    // try {
                    // console.log('creating subscription id for user that is not a ghost',)
                    // // Add event listener for success handling
                    // window.addEventListener('load', async () => {
                    //     const urlParams = new URLSearchParams(window.location.search);
                    //     const sessionId = urlParams.get('session_id');

                    //     if (sessionId) {
                    //         const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/handle-payment-success`, {
                    //             sessionId,
                    //             userId
                    //         });
                    //         console.log('response from handle payment success:', response.data)
                    //     }
                    // });
                    // } catch (error) {
                    //     console.error('Error handling payment success route to create subscription id for user:', error);
                    // }


                    if (!isRegForGhost) {
                        navigate("/login-loading", { state: { from: '/register' } });
                    }
                }
            }
        } catch (error) {
            console.error('Registration error:', error);
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('Network error. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };




    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage('');
        try {
            let response = null;
            const token = localStorage.getItem('userToken');
            let userId = null;

            if (token) {
                console.log('User token in storage:', token);
                try {
                    const decodedToken = jwtDecode(token);
                    userId = decodedToken.id;
                    console.log('Decoded userId:', userId); // Debug log to ensure userId is decoded correctly
                } catch (error) {
                    console.error('Failed to decode token:', error);
                }
            }

            if (isRegForGhost && userId) {
                response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/users/update/${userId}`, {
                    // userId,  // Include userId in the request payload
                    accountEmail,
                    password,
                    ghost: false  // Update the ghost field to false
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`  // Include the token in the headers
                    }
                });
            } else {
                response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/register`, {
                    accountEmail,
                    password
                }, {
                    withCredentials: true  // Needed if your server is using cookies
                });
            }

            if ((response.status === 200 || response.status === 201) && response.data.msg === 'success!') {
                // Perform actions on success, such as saving the token or redirecting
                console.log("Registration successful: ", response.data);
                if (!isRegForGhost) { 
                    localStorage.setItem('userToken', response.data.userToken);
                }
                console.log('Token stored:', localStorage.getItem('userToken'));

                isRegForGhost ? navigate("/payment", { state: { from: '/register-ghost' } }) : navigate("/login-loading", { state: { from: '/register' } });
            } else {
                // Handle cases where the server explicitly sends a non-success message within a successful HTTP response
                setErrorMessage('Registration was not successful. Please check your details.');
            }
        } catch (error) {
            // Handle errors here
            console.error('Registration error:', error);
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('Network error. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };



    if (isRegForGhost) return (
        <LazyBackgroundImage
            name="snowHouse"
            className="bg-no-repeat bg-cover bg-center"
        >
            <form className={` w-full flex flex-col items-center justify-center h-screen`}>
                {/* // <form className={`${isRegForGhost ? 'bg-[#eeece7]' : 'bg-login-image'} w-full flex flex-col items-center justify-center h-screen`}> */}
                {/* <div href="/assistants" className="logo flex items-center space-x-2 px-4 h-16 -mt-10">
                    <span className=" flex items-center text-3xl font-extrabold text-white ">
                        <img src={logo} alt="GuestLink Logo" className='h-32 w-32 -ml-8' />
                        <p className='mb-2 -ml-4'>GuestLink</p>
                    </span>
                </div> */}
                <div class="bg-slate-500/10 shadow-lg  rounded-2xl p-8 max-w-sm w-full backdrop-filter backdrop-blur-md bg-opacity-10 ring-2 ring-white ring-opacity-50">
                    <h2 class="text-3xl text-white font-bold mb-2 text-center">Account Creation</h2>
                    <h2 class=" text-white font- mb-8 text-center">To access your assistant please first create an account.</h2>
                    {errorMessage && (
                        <div className="mb-4 -mt-6 text-red-500 font-bold text-center">
                            {errorMessage}
                        </div>
                    )}
                    <div class="mb-8">

                        <div className="relative flex items-center">
                            <FaUser className="absolute ml-5 text-gray-100" />
                            <input
                                onChange={e => { setAccountEmail(e.target.value) }}
                                className="pl-12 appearance-none placeholder-gray-200 bg-white bg-opacity-10 backdrop-blur-md rounded-full w-full py-2 px-3 text-gray-50 leading-tight focus:outline-none focus:ring focus:ring-white focus:ring-opacity-50"
                                id="username"
                                type="text"
                                placeholder="Email"
                            />
                        </div>
                    </div>
                    <div class="mb-10">
                        <div className="relative flex items-center">
                            <FaLock className="absolute ml-5 text-gray-100" />
                            <input
                                onChange={e => { setPassword(e.target.value) }}
                                className="pl-12 appearance-none placeholder-gray-200 bg-white bg-opacity-10 backdrop-blur-md rounded-full w-full py-2 px-3 text-gray-50 leading-tight focus:outline-none focus:ring focus:ring-white focus:ring-opacity-50"
                                id="password"
                                type="password"
                                placeholder="Create Password"
                            />
                        </div>
                    </div>



                    
                    <div class="flex items-center text-center justify-center flex-grow mb-4">
                        <button type="submit" onClick={newHandleSubmit} class="w-full bg-blue-500 hover:bg-blue-400 active:bg-blue-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline">
                            Proceed to billing
                        </button>
                        {/* <Link type="submit" to={'/login-loading'} class="w-full bg-blue-500 hover:bg-blue-400 active:bg-blue-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline">
                            Welcome
                        </Link> */}
                    </div>
                    {/* <div class="mt-4 text-center">
                        <Link to={'/login'} class="inline-block align-baseline font-bold text-sm text-purple-200 hover:text-blue-400">
                            Already signed up? Login
                        </Link>
                    </div> */}
                </div>
            </form>
        </LazyBackgroundImage>
    ) 

    return (
        <LazyBackgroundImage
            name="snowHouse"
            className="bg-no-repeat bg-cover bg-center"
        >
            <form className={`bg-login-image w-full flex flex-col items-center justify-center h-screen`}>
                {/* // <form className={`${isRegForGhost ? 'bg-[#eeece7]' : 'bg-login-image'} w-full flex flex-col items-center justify-center h-screen`}> */}
                <div href="/assistants" className="logo flex items-center space-x-2 px-4 h-16 -mt-10">
                    <span className=" flex items-center text-4xl font-extrabold text-white space-x-3">
                        <img src={logo} alt="GuestLink Logo" className='h-16 w-16 ' />
                        <p className='mb-2 -ml-4'>GuestLink</p>
                    </span>
                </div>
                <div class="bg-slate-500/10 shadow-lg mt-24 rounded-2xl p-8 max-w-sm w-full backdrop-filter backdrop-blur-md bg-opacity-10 ring-2 ring-white ring-opacity-50">
                    <h2 class="text-4xl text-white font-bold mb-12 text-center">Register With</h2>
                    {errorMessage && (
                        <div className="mb-4 -mt-6 text-red-500 font-bold text-center">
                            {errorMessage}
                        </div>
                    )}
                    <div class="mb-8">

                        <div className="relative flex items-center">
                            <FaUser className="absolute ml-5 text-gray-200" />
                            <input
                                onChange={e => {setAccountEmail(e.target.value)}}
                                className="pl-12 appearance-none bg-white bg-opacity-10 backdrop-blur-md rounded-full w-full py-2 px-3 text-gray-200 leading-tight focus:outline-none focus:ring focus:ring-white focus:ring-opacity-50"
                                id="username"
                                type="text"
                                placeholder="Email"
                            />
                        </div>
                    </div>
                    <div class="mb-10">
                        <div className="relative flex items-center">
                            <FaLock className="absolute ml-5 text-gray-200" />
                            <input
                                onChange={e => {setPassword(e.target.value)}}
                                className="pl-12  appearance-none bg-white bg-opacity-10 backdrop-blur-md rounded-full w-full py-2 px-3 text-gray-200 leading-tight focus:outline-none focus:ring focus:ring-white focus:ring-opacity-50"
                                id="password"
                                type="password"
                                placeholder="Create Password"
                            />
                        </div>
                    </div>



                    {/* <div class="flex items-center justify-between mb-">
                        <label class="inline-flex items-center">
                            <input type="checkbox" class="form-checkbox text-white rounded" />
                            <span class="ml-2 text-white font-bold">Remember me</span>
                        </label>
                        <Link class="inline-block align-baseline font-bold text-sm text-purple-200 hover:text-blue-400" >
                            Forgot Password?
                        </Link>
                    </div> */}
                    <div class="flex items-center text-center justify-center flex-grow">
                        <button type="submit" onClick={newHandleSubmit} class="w-full bg-blue-500 hover:bg-blue-400 active:bg-blue-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline">
                            Welcome
                        </button>
                        {/* <Link type="submit" to={'/login-loading'} class="w-full bg-blue-500 hover:bg-blue-400 active:bg-blue-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline">
                            Welcome
                        </Link> */}
                    </div>
                    <div class="mt-4 text-center">
                        <Link to={'/login'} class="inline-block align-baseline font-bold text-sm text-purple-200 hover:text-blue-400">
                            Already signed up? Login
                        </Link>
                    </div>
                </div>
            </form>
        </LazyBackgroundImage>
    )
}

export default RegisterForm;




// const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setErrorMessage('');
//     try {
//         const response = await axios.post('http://localhost:8000/api/auth/register', {
//             accountEmail,
//             password
//         }, {
//             withCredentials: true  // Needed if your server is using cookies
//         });
//         if (response.data.msg === 'success!') {
//             // Perform actions on success, such as saving the token or redirecting
//             console.log("Login successful: ", response.data);
//             localStorage.setItem('userToken', response.data.userToken);
//             console.log('Token stored:', localStorage.getItem('userToken'));

//             const token = localStorage.getItem('userToken');
//             let userId = null;

//             if (token) {
//                 console.log('user token in storage:', token)
//                 try {
//                     const decodedToken = jwtDecode(token);
//                     userId = decodedToken.id;
//                     console.log('Decoded userId:', userId); // Debug log to ensure userId is decoded correctly
//                 } catch (error) {
//                     console.error('Failed to decode token:', error);
//                 }
//             }

//             navigate("/login-loading", { state: { from: '/register' } });
//         } else {
//             // Handle cases where the server explicitly sends a non-success message within a successful HTTP response
//             setErrorMessage('Login was not successful. Please check your credentials.');
//         }
//     } catch (error) {
//         // Handle errors here
//         console.error('Login error:', error);
//         if (error.response && error.response.data) {
//             setErrorMessage(error.response.data.message);
//         } else {
//             setErrorMessage('Network error. Please try again.');
//         }
//     } finally {
//         setLoading(false);
//     }
// }





// works for client only subscription mode
// const newHandleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setErrorMessage('');

//     try {
//         let response = null;
//         const token = localStorage.getItem('userToken');
//         let userId = null;

//         if (token) {
//             try {
//                 const decodedToken = jwtDecode(token);
//                 userId = decodedToken.id;
//             } catch (error) {
//                 console.error('Failed to decode token:', error);
//             }
//         }

//         if (isRegForGhost && userId) {
//             response = await axios.put(`http://localhost:8000/api/users/update/${userId}`, {
//                 accountEmail,
//                 password,
//                 ghost: false
//             }, {
//                 headers: {
//                     'Authorization': `Bearer ${token}`
//                 }
//             });

//             if (response.status === 200 || response.status === 201) {
//                 const stripeResponse = await axios.post('http://localhost:8000/api/subscription/create-customer-setup-intent', {
//                     email: accountEmail,
//                 });

//                 const { customerId, setupIntentClientSecret } = stripeResponse.data;
//                 localStorage.setItem('customerId', customerId);

//                 const stripe = await stripePromise;
//                 const { error } = await stripe.redirectToCheckout({
//                     mode: 'subscription',
//                     lineItems: [{ price: 'price_1PMa3xAVry7Dxu3xOrrqz2s8', quantity: 1 }],
//                     // customer: customerId,
//                     successUrl: 'http://localhost:3000/success',
//                     cancelUrl: 'http://localhost:3000/create-assistant',
//                     // setup_intent_data: {
//                     //     metadata: {
//                     //         customer_id: customerId
//                     //     }
//                     // }
//                 });

//                 if (error) {
//                     console.error(error);
//                 }
//             }
//         } else {
//             response = await axios.post('http://localhost:8000/api/auth/register', {
//                 accountEmail,
//                 password
//             }, {
//                 withCredentials: true
//             });

//             if (response.status === 200 || response.status === 201) {
//                 if (!isRegForGhost) {
//                     localStorage.setItem('userToken', response.data.userToken);
//                 }
//                 const userToken = response.data.userToken;
//                 const decodedToken = jwtDecode(userToken);
//                 userId = decodedToken.id;

//                 const stripeResponse = await axios.post('http://localhost:8000/api/subscription/create-customer-setup-intent', {
//                     email: accountEmail,
//                 });

//                 const { customerId, setupIntentClientSecret } = stripeResponse.data;
//                 localStorage.setItem('customerId', customerId);

//                 const stripe = await stripePromise;
//                 const { error } = await stripe.redirectToCheckout({
//                     mode: 'setup',
//                     customer: customerId,
//                     success_url: 'http://localhost:3000/success',
//                     cancel_url: 'http://localhost:3000/cancel',
//                     setup_intent_data: {
//                         metadata: {
//                             customer_id: customerId
//                         }
//                     }
//                 });

//                 if (error) {
//                     console.error(error);
//                 }

//                 if (!isRegForGhost) {
//                     navigate("/login-loading", { state: { from: '/register' } });
//                 }
//             }
//         }
//     } catch (error) {
//         console.error('Registration error:', error);
//         if (error.response && error.response.data) {
//             setErrorMessage(error.response.data.message);
//         } else {
//             setErrorMessage('Network error. Please try again.');
//         }
//     } finally {
//         setLoading(false);
//     }
// };

// const newHandleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setErrorMessage('');

//     try {
//         let response = null;
//         const token = localStorage.getItem('userToken');
//         let userId = null;
//         let customerId = null;
//         let subscriptionId = null;

//         if (token) {
//             try {
//                 const decodedToken = jwtDecode(token);
//                 userId = decodedToken.id;
//             } catch (error) {
//                 console.error('Failed to decode token:', error);
//             }
//         }

//         if (isRegForGhost && userId) {
//             response = await axios.put(`http://localhost:8000/api/users/update/${userId}`, {
//                 accountEmail,
//                 password,
//                 ghost: false
//             }, {
//                 headers: {
//                     'Authorization': `Bearer ${token}`
//                 }
//             });

//             // Create Stripe customer and initial subscription for ghost users upgrading
//             if (response.status === 200 || response.status === 201) {
//                 const stripeResponse = await axios.post('http://localhost:8000/api/subscription/create-initial-subscription', {
//                     email: accountEmail,
//                 });
//                 customerId = stripeResponse.data.customerId;
//                 subscriptionId = stripeResponse.data.subscriptionId;

//                 // Update user model with Stripe IDs
//                 await axios.put(`http://localhost:8000/api/users/update/${userId}`, {
//                     stripeCustomerId: customerId,
//                     stripeSubscriptionId: subscriptionId
//                 }, {
//                     headers: {
//                         'Authorization': `Bearer ${token}`
//                     }
//                 });

//                 // Redirect to Stripe Checkout for billing details
//                 const checkoutResponse = await axios.post('http://localhost:8000/api/subscription/billing-details', {
//                     customerId,
//                     subscriptionId
//                 });

//                 const stripe = await stripePromise;
//                 await stripe.redirectToCheckout({ sessionId: checkoutResponse.data.id });
//             }
//         } else {
//             response = await axios.post('http://localhost:8000/api/auth/register', {
//                 accountEmail,
//                 password
//             }, {
//                 withCredentials: true
//             });

//             // Possibly take out the check for ghost and reset the user token for now fully registered ghost because old ghost token
//             // is still set to ghost: true so conditionals won't work right.
//             if (response.status === 200 || response.status === 201) {
//                 if (!isRegForGhost) {
//                     localStorage.setItem('userToken', response.data.userToken);
//                 }
//                 const userToken = response.data.userToken;
//                 const decodedToken = jwtDecode(userToken);
//                 userId = decodedToken.id;

//                 // Create Stripe customer and initial subscription for new users
//                 const stripeResponse = await axios.post('http://localhost:8000/api/subscription/create-initial-subscription', {
//                     email: accountEmail,
//                 });
//                 customerId = stripeResponse.data.customerId;
//                 subscriptionId = stripeResponse.data.subscriptionId;

//                 // Update user model with Stripe IDs
//                 await axios.put(`http://localhost:8000/api/users/update/${userId}`, {
//                     stripeCustomerId: customerId,
//                     stripeSubscriptionId: subscriptionId
//                 }, {
//                     headers: {
//                         'Authorization': `Bearer ${userToken}`
//                     }
//                 });

//                 // Commented out to redirect to home page for regular users
//                 // const checkoutResponse = await axios.post('http://localhost:8000/api/subscription/billing-details', {
//                 //     customerId,
//                 //     subscriptionId
//                 // });
//                 // window.location.href = `https://checkout.stripe.com/pay/${checkoutResponse.data.id}`;

//                 if (!isRegForGhost) {
//                     navigate("/login-loading", { state: { from: '/register' } });
//                 }
//             }
//         }
//     } catch (error) {
//         console.error('Registration error:', error);
//         if (error.response && error.response.data) {
//             setErrorMessage(error.response.data.message);
//         } else {
//             setErrorMessage('Network error. Please try again.');
//         }
//     } finally {
//         setLoading(false);
//     }
// };