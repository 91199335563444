import React from 'react';
import { LazyLoadedImage } from '../components/LazyImages';
import { ArrowUpRight } from 'lucide-react';
import { NavLink } from 'react-router-dom';

const BlogPostCard = ({ image, category, date, title, excerpt, author, url }) => {
    return (
        <article className="featured-post rounded-2xl overflow-hidden shadow-md hover:shadow-lg hover:shadow-[#f4ccc9] transition-shadow duration-300 group">
            <NavLink to={url}>
                <div className="relative">
                    <LazyLoadedImage
                        name={image}
                        alt={title}
                        className="w-full h-64 object-cover"
                    />
                    <div className="absolute bottom-0 left-0 right-0 bg-white/10 backdrop-filter backdrop-blur-sm p-3 py-5 shadow-lg">
                        <div className="flex justify-between items-center text-white">
                            <p className="text-sm font-semibold">{author}</p>
                            <p className="text-sm">{date}</p>
                        </div>
                    </div>
                </div>
                <div className="p-4">
                    <div className="flex justify-between items-center mb-2">
                        <span className="text-sm font-bold text-blue-400">{category}</span>
                        <ArrowUpRight className="text-gray-400 group-hover:text-[#FF5A5F] transition-colors duration-300" size={20} />
                    </div>
                    <h2 className="text-xl font-bold mb-2 hover:underline">{title}</h2>
                    <p className="text-gray-600 text-sm line-clamp-3 mb-3">{excerpt}</p>
                </div>
            </NavLink>
        </article>
    );
};

export default BlogPostCard;