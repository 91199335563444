import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LazyBackgroundImage, LazyLoadedImage } from '../components/LazyImages';
import { MoveDown } from 'lucide-react';
import { HiOutlineMail } from "react-icons/hi";
import LandingHeader from '../components/LandingHeader'
import BlogPostCard from '../components/BlogPostCard';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import '../css/random.css'


// const images = {
//     logo,
//     snowHouse,
//     landingHouse,
//     swimming,
//     beachCar,
//     img3,
//     airbnb,
//     vrbo,
//     pool,
//     computer,
//     texting,
//     stretching,
//     luxuryAirbnb,
//     checkIn,
//     mexicoHouse
// };


const BlogDirectory = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('')

    const handleGhost = async () => {
        // e.preventDefault();
        console.log('running handler');
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/ghost`);
            console.log('ghost:', response);
            const { userId } = response.data;

            localStorage.setItem('userToken', response.data.userToken);
            console.log('Token stored:', localStorage.getItem('userToken'));

            sessionStorage.setItem('ghostId', userId);
            navigate("/create-assistant", { state: { from: '/landing-page' } });
        } catch (error) {
            console.error('error with ghost:', error);
        }
    };

    return (
        <div className=" min-h-screen">
            <div className=' pt-2 px-8'>
                <LandingHeader />
            </div>
            <div className='relative h-[300px] mb-12 mt-12 mx-8'>
                <LazyBackgroundImage
                    name="greenBeach"
                    className="absolute inset-0 bg-no-repeat bg-cover bg-center rounded-[25px]"
                />
                <div className="absolute inset-0 bg-black/30 backdrop-filter backdrop-blur-md rounded-[25px] overflow-hidden">
                    <div className="relative container mx-auto h-full flex items-center justify-center p-8 rounded-[25px] border-2 border-white/40 overflow-hidden">
                        <div className="w-ful rounded-[25px]l">
                            <span className="text-blue-300 text-sm font-bold mb-2 inline-block">Featured</span>
                            <h2 className="text-3xl text-white font-bold mb-4">
                                Revolutionize Your Short-Term Rental Management with GuestLink AI
                            </h2>
                            <p className="text-gray-200 mb-4">
                                Discover how GuestLink AI is transforming the Airbnb hosting experience. Our cutting-edge AI assistant helps you automate guest communication, boost direct bookings, and maximize your rental's potential. Start your 14-day free trial today and see the difference GuestLink can make for your short-term rental business.
                            </p>
                            <Link to="/register" className="adjustable-create-button inline-block">
                                Try GuestLink Today
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <main className="blog-directory container mx-auto w-full px-4 flex flex-col md:flex-row">
                <div className="main-content md:w-2/3 md:pr-8">
            
                    <section className="featured-posts grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
                        <BlogPostCard
                            image="checkIn"
                            category="Design"
                            date="8 Aug 2024"
                            title="AI for Airbnb Hosts"
                            excerpt="This post explores how AI is transforming the Airbnb hosting experience, benefiting both hosts and guests alike, and introduces GuestLink AI as a cutting-edge solution to these challenges."
                            author="Peyton Allworth"
                            url={'/AI-for-Airbnb-Hosts'}
                        />

                        <BlogPostCard
                            image="computer"
                            category="Design"
                            date="3 Aug 2024"
                            title="Self Manage your Short Term Rental"
                            excerpt="Discover how GuestLink AI revolutionizes short-term rental management, helping you build loyalty and increase profitability."
                            author="Peyton Allworth"
                            url={'/Self-Manage-your-Short-Term-Rental'}
                        />

                    </section>

                    <section className='featured-posts grid grid-cols-1 md:grid-cols-2 gap-8 mb-12'>
                        <BlogPostCard
                            image="islandAerialHotel"
                            category="Instructional"
                            date="4 Aug 2024"
                            title="Sync Your Calender and Master Guest Opt-In"
                            excerpt="GuestLink's calendar sync allows you to enable our unique opt-in feature. Encourage repeat renters and direct bookings by offering direct booking discounts and up to date offers."
                            author="Jack Piro"
                            url={'/ical-instructions'}
                        />

                    </section>


                    {/* <nav className="pagination flex justify-between items-center mb-10">
                        <button className="bg-gray-200 px-4 py-2 rounded-lg" disabled>Previous</button>
                        <div className="flex space-x-2">
                            {[1, 2, 3, 4, 5].map((page) => (
                                <Link key={page} to={`/blog/page/${page}`} className={`px-3 py-1 rounded-lg ${page === 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
                                    {page}
                                </Link>
                            ))}
                        </div>
                        <Link to="/blog/page/2" className="bg-gray-200 px-4 py-2 rounded-lg">Next</Link>
                    </nav> */}
                </div>

                <aside className="sidebar md:w-1/3 mt-12 md:mt-0">
                    <div className=" p-6 rounded-lg">
                        <div className="mb-6">
                            <LazyLoadedImage name="texting" alt="logo" className="w-16 h-16 rounded-full mb-2" />
                            <h2 className="text-xl font-semibold">Peyton Allworth</h2>
                            <p className="text-sm text-gray-600">Most Recent: Published 8 Aug 2024</p>
                        </div>

                        <nav className="mb-8">
                            <h3 className="text-lg font-semibold mb-4">Top Articles & Mentions</h3>
                            <ul className="space-y-0">
                                {['About GuestLink', 'AI for Airbnb Hosts', 'How to sync your renter calendar', 'Self Manage Successfully with AI'].map((item, index) => (
                                    <li
                                        key={index}
                                        className={`hover:bg-[#f4ccc9] px-2 flex items-center justify-between border-black border-b-2 ${index === 0 ? 'border-t-2' : ''} py-3`}
                                    >
                                        <span>{item}</span>
                                        {/* <MoveDown /> */}
                                    </li>
                                ))}
                            </ul>
                        </nav>


                        <div className="newsletter-signup mb-10">
                            <h3 className="text-xl font-semibold mb-2">Weekly newsletter</h3>
                            <p className="text-sm text-gray-600 mb-4">Just the latest releases and tips, interesting articles, and exclusive interviews in your inbox every week.</p>
                            <form className="space-y-4 flex flex-col justify-center">
                                <div className="relative flex items-center w-full mb-4">
                                    <HiOutlineMail size={18} className="absolute ml-5" />
                                    <input
                                        className="pl-12 bg-white appearance-none rounded-2xl h-12 w-full leading-tight ring-2 ring-black focus:ring-2 focus:ring-[#f6656c] focus:ring-opacity-50 focus:outline-none"
                                        id="email"
                                        type="text"
                                        placeholder="Email "
                                        value={email}
                                        onChange={e => { setEmail(e.target.value) }}
                                    />
                                </div>
                                <div className='flex items-center justify-center w-full px-[15px]'>
                                    <button
                                        type="submit"
                                        className="adjustable-create-button w-full"
                                    >
                                        Subscribe
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </aside>
            </main>

            {/* <div className='pb-10'>
                <nav className="pagination flex justify-between items-center mx-10">
                    <button className="bg-[#f5f4f0] px-4 py-2 rounded-lg hover:bg-[#f4ccc9]" disabled>Previous</button>
                    <div className="flex space-x-2">
                        {[1, 2, 3, 4, 5].map((page) => (
                            <Link key={page} to={`/blog/page/${page}`} className={`px-3 py-1 rounded-lg ${page === 1 ? 'bg-[#FF5A5F] text-white' : 'bg-[#f5f4f0] hover:bg-[#f4ccc9]'}`}>
                                {page}
                            </Link>
                        ))}
                    </div>
                    <Link to="/blog/page/2" className="bg-[#f5f4f0] px-4 py-2 rounded-lg hover:bg-[#f4ccc9]">Next</Link>
                </nav>
            </div> */}

            {/* Footer */}
            <footer className='flex flex-col w-full h-[400px] justify-center px-16 py-10 space-y-7 bg-[#f5f4f0]'>
                <div className='flex justify-between items-center h-full'>
                    <a href="/" className="logo flex items-center space-x-2 px-4 h-16 mt-3 mb-5">
                        <span className="text-black flex items-center text-2xl font-bold space-x-3 ">
                            {/* <img src={logo} alt="GuestLink Logo" className='h-12 w-12 -ml-8' /> */}
                            <LazyLoadedImage name="logo" alt="GuestLink Logo" className='h-12 w-12 -ml-8' />
                            <p className='mb-2 -ml-4 text-3xl'>GuestLink</p>
                        </span>
                    </a>
                    <p className='text-2xl font-bold w-96 text-end'>Streamline your AirBNB Management Today</p>
                </div>
                <div className='flex justify-between pb-10 border-neutral-500 border-b-2 h-full'>
                    <div className='flex items-center space-x-10 font-light'>
                        <div className='space-y-2'>
                            <p>Email</p>
                            <p>hello@guestlinkai.com</p>
                        </div>
                        <div className='space-y-2'>
                            <p>Phone</p>
                            <p>+1 (408) 256-2545</p>
                        </div>
                    </div>
                    <div className='flex space-x-10 items-center'>
                        <button onClick={handleGhost} className='landing-glow-button'>Try It Now</button>
                        <Link to={"/register"} className='border-2 border-black px-4 py-2 rounded-full'>
                            Login or Sign Up
                        </Link>
                    </div>
                </div>
                <div className='flex justify-between font-light h-full text-sm '>
                    <div className='flex items-center space-x-5'>
                        <p>© 2023 GuestLink LLC.</p>
                        <p>All Rights Reserved</p>
                    </div>
                    <div className='flex space-x-8 items-center'>
                        <a
                            href='https://docs.google.com/document/d/e/2PACX-1vSxC7ZSjTLYfp_ZHB9Rna7kzz-10u2wl74pjZxZqbslaNAZKbxkP22UoJEVeOrQvalbF1HkAzZI0Ah3/pub'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privicy Policy
                        </a>
                        <a
                            href='https://docs.google.com/document/d/e/2PACX-1vR-N20f116EB2zHCuKWhBC6GpwFzpj-rZyUe5Rtp3MZaEnrmudBGStzB-JqZIK0kiPhw1obUGu08xyu/pub'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Terms of Service
                        </a>
                        <a
                            href='https://docs.google.com/document/d/e/2PACX-1vQnitjHjZ6fhtCcRGZxKyHWOOl0hiU9lhYhSpSke26MQ5Wmpf0jmJHbqGNP6iTnflrYD09gLdXaSWqT/pub'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Our Twilio Opt-in Disclosures
                        </a>
                    </div>
                </div>
            </footer>

            <div className='w-full h-[15px] self-center bg-[#FF5A5F]'>
                <p> </p>
            </div>
        </div>
        
    );
};

export default BlogDirectory;



{/* <div className='relative h-[550px] mb-12 mt-12'>
    <LazyBackgroundImage
        name="greenBeach"
        className="absolute inset-0 bg-no-repeat bg-cover bg-center rounded-[50px] mx-8"
    >
        <div className="relative container mx-auto h-full flex items-end justify-center z-10 w-full p-8">
            <div className="bg-black/30  backdrop-filter backdrop-blur-md rounded-[25px] p-8  w-full ring-1 ring-white/20">
                <span className="text-blue-300 text-sm font-bold mb-2 inline-block">Featured</span>
                <h2 className="text-3xl text-white font-bold mb-4">
                    Switching From Photography to UX Design: Everything You Need to Know With Viola LeBlanc
                </h2>
                <p className="text-gray-200 mb-4">
                    Viola LeBlanc is a 23-year-old photographer and product designer from Toronto, Ontario. She has worked
                    with Spotify, Nike, Chews, Makr, and Square. Sophia Munn asked her a few questions about her work.
                </p>
                <Link to="/blog/featured-post" className="adjustable-create-button z-20">
                    Read More
                </Link>
            </div>
        </div>
    </LazyBackgroundImage>
    <div className='absolute inset-0 bg-black opacity-20 rounded-[50px] mx-8'></div>
</div> */}