import React, { useState, useEffect } from 'react';
import { MoreHorizontal, ChevronRight, CheckSquare, Square, Loader } from 'lucide-react';
import { ChevronDown, Filter, Table, ArrowUpDown } from 'lucide-react';
import SideBar from '../components/SideBar';
import { Send, ListFilter } from 'lucide-react';
import { TbArrowBackUp } from "react-icons/tb";
import { NavLink } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Check } from 'lucide-react';
import { jwtDecode } from 'jwt-decode';

const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    if (cleaned.length === 11 && cleaned.startsWith('1')) {
        return `+1 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
    }
    return phoneNumber;
};

const MessageRenters = () => {
    const { assistantId } = useParams();
    const navigate = useNavigate();
    const [renters, setRenters] = useState([]);
    const [selectedRenters, setSelectedRenters] = useState([]);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    

    useEffect(() => {
        const fetchRenters = async () => {
            setLoading(true);
            setError(null);
            try {
                const token = localStorage.getItem('userToken');
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/renter/property/${assistantId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                console.log("Full API response:", JSON.stringify(response.data, null, 2));


                // Filter renters to only include those who have opted in for this specific property
                // const optedInRenters = response.data.filter(renter => {
                //     relevantProperty = renter.properties.find(property => property.assistantId === assistantId);
                //     return relevantProperty && relevantProperty.optedIn === true;
                // });

                const optedInRenters = response.data.filter(renter =>
                    renter.properties && renter.properties[0] && renter.properties[0].optedIn
                );

                // const optedInRenters = response.data.filter(renter => {
                //     const relevantProperty = renter.properties.find(prop => prop.assistantId === assistantId);
                //     if (!relevantProperty) {
                //         console.log(`No relevant property found for renter ${renter._id} and assistant ${assistantId}`);
                //         return false;
                //     }
                //     return typeof relevantProperty === 'object' && relevantProperty.optedIn === true;
                // });

                // const optedInRenters = response.data.filter(renter => {
                //     if (!renter || !renter.property) {
                //         console.log("Invalid renter data:", renter);
                //         return false;
                //     }
                //     return renter.property.optedIn === true;
                // });

                if (optedInRenters.length === 0) {
                    console.log("No opted-in renters found for this property");
                    setError("No opted-in renters found for this property.");
                } else {
                    console.log("Opted-in renters:", optedInRenters);
                }

                setRenters(optedInRenters);
                setSelectedRenters(optedInRenters.map(renter => renter._id)); // Default to all selected
            } catch (err) {
                console.error("Error fetching renters:", err);
                setError("Failed to load renters. Please try again.");
                setRenters([]);
            } finally {
                setLoading(false);
            }
        };
        fetchRenters();
    }, [assistantId]);


    const handleClick = async () => {
        if (message.length === 0) return;

        setIsLoading(true);
        setIsSuccess(false);

        try {
            await handleSendMessage();
            setIsSuccess(true);
        } catch (error) {
            console.error("Error sending message:", error);
        } finally {
            setIsLoading(false);
        }

        // Reset success state after 2 seconds
        setTimeout(() => setIsSuccess(false), 2000);
    };

    const handleSelectRenter = (renterId) => {
        setSelectedRenters(prev =>
            prev.includes(renterId)
                ? prev.filter(id => id !== renterId)
                : [...prev, renterId]
        );
    }

    const handleSelectAll = () => {
        if (selectedRenters.length === renters.length) {
            setSelectedRenters([]);
        } else {
            setSelectedRenters(renters.map(renter => renter._id));
        }
    };

    const handleSendMessage = async () => {
        if (selectedRenters.length === 0 || !message.trim() )  {
            alert("Please select renters and enter a message.");
            return;
        }

        setSending(true);
        setError(null);
        try {
            const token = localStorage.getItem('userToken');
            const userId = jwtDecode(token).id
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/renter/send-message`, {
                userId,
                assistantId,
                renterIds: selectedRenters,
                message
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            alert("Message sent successfully!");
            setMessage('');
            setSelectedRenters(renters.map(renter => renter._id)); // Reset to all selected
        } catch (err) {
            console.error("Error sending message:", err);
            setError("Failed to send message. Please try again.");
            if (err.response) {
                console.log('Error response:', err.response.data);
                console.log('Error status:', err.response.status);
            } else if (err.request) {
                console.log('Error request:', err.request);
            } else {
                console.log('Error message:', err.message);
            }
        } finally {
            setSending(false);
        }
    };

    return (
        <div className='flex bg-[#161616] h-screen overflow-hidden'>
            <div>
                <SideBar />
            </div>
            <div className='flex flex-col w-full overflow-hidden p-3 bg-[#eeece7] mt-3 -ml-3 rounded-t-2xl rounded-b-2xl'>
                <div className="flex-1 overflow-y-auto p-6 rounded-2xl pb-16 z-10">
                    <div className="px-6 py-4 flex justify-between items-center border-gray-300 border-b-2">
                        <h2 className="text-xl font-semibold text-gray-800">Send SMS</h2>
                        <div className="flex items-center space-x-2">
                            <NavLink to={'/communication'} className='bg-white p-3 rounded-full ml-auto hover:bg-[#f4ccc9] transition duration-100'>
                                <TbArrowBackUp size={20} />
                            </NavLink>
                            <button className="py-2 px-4 rounded-3xl bg-[#FF5A5F] text-white transition duration-300 flex justify-center items-center">
                                Export
                            </button>
                        </div>
                    </div>

                    <div className="px-6 py-3 flex items-center space-x-4 border-b border-gray-200">
                        <button className="py-2 px-4 space-x-1 bg-[#f4ccc9] text-black rounded-lg hover:bg-white hover:text-black transition duration-300 flex justify-center items-center ">
                            <Table className="w-4 h-4" />
                            <span>Table view</span>
                            {/* <ChevronDown className="w-4 h-4" /> */}
                        </button>
                        {/* <button className="space-x-1 py-2 px-4 bg-[#f4ccc9] text-black rounded-lg hover:bg-white hover:text-black transition duration-300 flex justify-center items-center ">
                            <ListFilter className="w-4 h-4" />
                            <span>Filter</span>
                        </button>
                        <button className="space-x-1 py-2 px-4 bg-[#f4ccc9] text-black rounded-lg hover:bg-white hover:text-black transition duration-300 flex justify-center items-center ">
                            <ArrowUpDown className="w-4 h-4" />
                            <span>Sort</span>
                        </button> */}
                    </div>

                    {/* Renter Table */}
                    <div className="w-full bg-white rounded-lg shadow-sm overflow-hidden  mb-10">
                        <table className="w-full">
                            <thead>
                                <tr className="bg-gray-50">
                                    <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {renters.length > 0 && (
                                            <div
                                                onClick={handleSelectAll}
                                                className="cursor-pointer flex justify-center"
                                            >
                                                {selectedRenters.length === renters.length ? (
                                                    <CheckSquare className="h-5 w-5 text-[#FF5A5F]" />
                                                ) : (
                                                    <Square className="h-5 w-5 text-gray-400" />
                                                )}
                                            </div>
                                        )}
                                    </th>
                                    <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Person
                                    </th>
                                    <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Stay Count
                                    </th>
                                    <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Status
                                    </th>
                                    <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Last Checkout
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {loading ? (
                                    <tr><td colSpan="5" className="py-10 text-center text-gray-500">Loading...</td></tr>
                                ) : renters.length === 0 ? (
                                    <tr><td colSpan="5" className="py-10 text-center text-gray-500">No Renters Opted in Yet</td></tr>
                                ) : (
                                    renters.map((renter) => {
                                        const property = renter.properties[0];

                                        return (
                                            <tr key={renter._id}>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div
                                                        onClick={() => handleSelectRenter(renter._id)}
                                                        className="cursor-pointer flex justify-center"
                                                    >
                                                        {selectedRenters.includes(renter._id) ? (
                                                            <CheckSquare className="h-5 w-5 text-[#FF5A5F]" />
                                                        ) : (
                                                            <Square className="h-5 w-5 text-gray-400" />
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex flex-col items-center">
                                                        <div className="text-sm font-medium text-gray-900 text-center truncate">
                                                            {property.lastGroupName ? `${property.lastGroupName}'s Group` : ''}
                                                        </div>
                                                        <div className="text-sm text-gray-500">{formatPhoneNumber(renter.phoneNumber)}</div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center">
                                                    <div className="text-sm text-gray-900">{property.numberOfStays}</div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center">
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        opted-in
                                                    </span>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center">
                                                    <div className="text-sm text-gray-900">
                                                        {property.lastCheckOut ? new Date(property.lastCheckOut).toLocaleDateString() : 'N/A'}
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>

                    {/* Message Input Area */}
                    <div className="bg-white p-6 rounded-2xl shadow">
                        <textarea
                            className="w-full h-32 p-4 border border-gray-300 rounded-lg resize-none focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
                            placeholder="Type your message here..."
                            value={message}
                            onChange={(e) => {
                                if (e.target.value.length <= 1000) {
                                    setMessage(e.target.value);
                                }
                            }}
                        ></textarea>
                        <div className="flex justify-between items-center mt-4">
                            <p className="text-gray-500">{message.length} / 1000 characters</p>
                            <button
                                onClick={handleClick}
                                disabled={message.length === 0 || isLoading}
                                className={`adjustable-create-button mr-2 text-center relative ${message.length > 0 ? 'bg-[#FF5A5F] text-white' : 'bg-gray-200 text-gray-500'
                                    } ${isLoading ? 'cursor-wait' : ''} ${isSuccess ? 'bg-green-500' : ''}`}
                            >
                                <div className='text-center flex justify-center items-center space-x-2'>
                                    {!isLoading && !isSuccess && (
                                        <>
                                            <p className='font-semibold text-md'>Send</p>
                                            <Send size={16} />
                                        </>
                                    )}
                                    {isLoading && <span className='loader'></span>}
                                    {isSuccess && <Check size={24} className="text-white" />}
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessageRenters;