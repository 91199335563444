import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import '../css/animatedBackground.css'
import loader from '../assets/Box Spin Loader.gif'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { isGhostUser } from '../services/ghostCheck';

import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
// import { loadStripe } from '@stripe/stripe-js';

// const stripePromise = loadStripe("pk_test_51O8APtAVry7Dxu3xUUthjxI0j8lrxyUFrwwkGse2mGeUfGA3rg1AE1rjQCNH8zzfONBaE5vmPxn0j2iyffTKOkXu00Zsm26ot3");


import { loadStripe } from '@stripe/stripe-js';
import { stripePublicKey } from '../config/stripe';

const stripePromise = loadStripe(stripePublicKey);


export default function CreationLoading() {
    const interactiveRef = useRef(null);
    const [isGhost, setIsGhost] = useState(false);
    const [showText, setShowText] = useState(false);
    const navigate = useNavigate();
    const [greeting, setGreeting] = useState();
    const location = useLocation();;
    const token = localStorage.getItem("userToken")

    useEffect(() => {
        setIsGhost(isGhostUser())
        console.log('setting is ghost:', isGhostUser())
    }, [])

    useEffect(() => {
        setIsGhost(isGhostUser())
        console.log('setting is ghost:', isGhostUser())
    }, [])

    useEffect(() => {
        const textTimeout = setTimeout(() => {
            setShowText(true);
        }, 4000);

        const redirectTimeout = setTimeout(() => {
            if (isGhost) {
                navigate("/create-assistant", { state: { from: '/creating-assistant' } });
            } else {
                console.log('token found:', token)
                navigate("/assistants", { state: { from: '/creating-assistant' } });
            }
        }, 6000);

        return () => {
            clearTimeout(textTimeout);
            clearTimeout(redirectTimeout);
        };
    }, [isGhost, navigate, token]);

    // useEffect(() => {
    //     const checkUserDetailsAndNav = async () => {
    //         const textTimeout = setTimeout(() => {
    //             setShowText(true);
    //         }, 4000);
            
    //         if (isGhost) {
    //             navigate("/create-assistant", { state: { from: '/creating-assistant' } });
    //         } else {
    //             try {
    //                 const decodedToken = jwtDecode(token);
    //                 const userId = decodedToken.id;
    //                 console.log('Decoded user ID:', userId);
    
    //                 const response = await axios.get(`http://localhost:8000/api/users/get-user/${userId}`);
    //                 console.log('Response from get user:', response);
    
    //                 const hasBillingDetails = response.data.hasBillingDetails;
    //                 console.log('hasBillingDetails from response:', hasBillingDetails);
    
    
    //                 const redirectTimeout = setTimeout(() => {
                        
    //                     if (!hasBillingDetails && !isGhost) {
    //                         navigate("/assistants", { state: { from: '/creating-first-assistant' } });
    //                     } else {
    //                         navigate("/assistants", { state: { from: '/creating-assistant' } });
    //                     }
    //                 }, 6000);
    
    //                 return () => {
    //                     clearTimeout(textTimeout);
    //                     clearTimeout(redirectTimeout);
    //                 };
    //             } catch (error) {
    //                 console.error('Failed to check user details:', error);
    //             }
    //         }
    //     };

    //     checkUserDetailsAndNav();
    // }, [isGhost, navigate, token]);
    // for bubbles

    //decode token




    useEffect(() => {
        const interBubble = interactiveRef.current;
        let curX = 0;
        let curY = 0;
        let tgX = 0;
        let tgY = 0;

        const move = () => {
            function animationStep() {
                curX += (tgX - curX) / 20;
                curY += (tgY - curY) / 20;
                if (interBubble) {
                    interBubble.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
                }
                requestAnimationFrame(animationStep);
            }

            window.addEventListener('mousemove', (event) => {
                tgX = event.clientX - window.innerWidth / 2;
                tgY = event.clientY - window.innerHeight / 2;
            });

            animationStep();
        };

        move();
        return () => {
            window.removeEventListener('mousemove', move);
        };
    }, []);

    return (
        <div className="gradient-bg">
            {/* Overlay with blur */}
            <div className="absolute inset-0 bg-slate-500/10 backdrop-blur-3xl"></div>

            {/* Centered content */}
            <div className="relative flex flex-col items-center justify-center h-screen p-40">
                <div className='text-white rounded-3xl shadow-xl flex items-center justify-center p-10 flex-col space-y-4 z-10'>
                    {showText ? (
                        <h1 className="font-bold text-4xl">
                            Finishing touches....
                        </h1>
                    ) : (
                        <h1 className="font-bold text-4xl">Building robot army...</h1>
                    )}
                    <div className='w-80 h-80 flex items-center justify-center'>
                        <div className="sk-cube-grid">
                            <div className="sk-cube sk-cube1"></div>
                            <div className="sk-cube sk-cube2"></div>
                            <div className="sk-cube sk-cube3"></div>
                            <div className="sk-cube sk-cube4"></div>
                            <div className="sk-cube sk-cube5"></div>
                            <div className="sk-cube sk-cube6"></div>
                            <div className="sk-cube sk-cube7"></div>
                            <div className="sk-cube sk-cube8"></div>
                            <div className="sk-cube sk-cube9"></div>
                        </div>
                    </div>
                    <p>Get excited...</p>
                </div>
            </div>
            <svg style={{ visibility: 'hidden' }}>
                <defs>
                    <filter id="goo">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="15" result="blur" />
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
                        <feBlend in="SourceGraphic" in2="goo" />
                    </filter>
                </defs>
            </svg>
            <div className="gradients-container">
                <div className="g1"></div>
                <div className="g2"></div>
                <div className="g3"></div>
                <div className="g4"></div>
                <div className="g5"></div>
                <div ref={interactiveRef} className="interactive"></div>
            </div>
        </div>
    );
}