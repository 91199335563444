import React, { useEffect, useState } from 'react';
import { IoSearch } from "react-icons/io5";
import { FaRegBell } from "react-icons/fa6";
import { FaHouseChimneyUser } from "react-icons/fa6";
import { LuSettings } from "react-icons/lu";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { TbArrowBackUp } from "react-icons/tb";
import { isGhostUser } from '../services/ghostCheck';

export default function HeaderMain() {
    const [assistants, setAssistants] = useState([]);
    const [isGhost, setIsGhost] = useState(false);
    const token = localStorage.getItem("userToken");
    const location = useLocation();

    useEffect(() => {
        setIsGhost(isGhostUser());
    }, []);

    useEffect(() => {
        if (token) {
            const getAssistants = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/get-not-locked`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    });
                    setAssistants(response.data);
                } catch (error) {
                    console.error("error getting assistant", error);
                }
            };
            getAssistants();
        }
    }, [token]);

    return (
        <div className={`flex p-3 w-full space-x-3  ${!(location.pathname === '/assistants' || location.pathname === '/analytics') ? 'justify-end' : 'justify-between'}`}>
            {isGhost ?
                <Link to={'/'} className='bg-white p-3 rounded-full ml-auto hover:bg-[#f4ccc9] transition duration-100'>
                    <TbArrowBackUp size={24} />
                </Link>
                : ''}
            {
                (location.pathname === '/assistants' || location.pathname === '/analytics' || location.pathname === '/settings') && (
                    <div className='w-full'>
                        <div className="relative flex items-center w-full">
                            <IoSearch size={18} className="absolute ml-5" />
                            <input
                                className="pl-12 appearance-none rounded-full h-12 w-full py-2 px-3 text-gray-200 leading-tight focus:outline-none focus:ring focus:ring-[#f6656c] focus:ring-opacity-50"
                                id="username"
                                type="text"
                                placeholder="Search . . . "
                            />
                        </div>
                    </div>
                )
            }
            <div className={`flex items-center justify-around space-x-3 ${(location.pathname === '/assistants' || location.pathname === '/analytics') ? 'self-end mr-auto' : null}`}>
                <div className='bg-white flex items-center p-3 rounded-full space-x-3 px-4'>
                    <p className='font-extrabold'>
                        {assistants.length}
                    </p>
                    <FaHouseChimneyUser size={24} />
                </div>
                <Link to={'/settings'} className='bg-white p-3 rounded-full hover:bg-[#f4ccc9] transition duration-100'>
                    <LuSettings size={24} />
                </Link>
                <div className='bg-white p-3 rounded-full hover:bg-[#f4ccc9] transition duration-100'>
                    <FaRegBell size={24} />
                </div>
            </div>
        </div>
    );
}
