// // firebaseAuthService.js
// import { signInWithCustomToken } from 'firebase/auth';
// import { auth } from '../config/firebase'; // Adjust the path to your firebase.js
// import axios from 'axios';

// const authenticateWithFirebase = async (jwtToken) => {
//     try {
//         console.log('running firebaseAuth from services, jwt passed:', jwtToken)
//         // Request to get Firebase custom token from your backend
//         const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/generate-firebase-token`, {}, {
//             headers: { Authorization: `Bearer ${jwtToken}` }
//         });
//         const { firebaseToken } = response.data;

//         // Sign in to Firebase with the custom token
//         await signInWithCustomToken(auth, firebaseToken);
//         console.log('User authenticated with Firebase');
//     } catch (error) {
//         console.error('Error authenticating with Firebase:', error);
//     }
// };

// export { authenticateWithFirebase };




// firebaseAuthService.js
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from '../config/firebase';
import axios from 'axios';

const authenticateWithFirebase = async (jwtToken, retries = 3) => {
    try {
        console.log(`Authenticating with Firebase, attempt ${4 - retries}`);
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/generate-firebase-token`, {}, {
            headers: { Authorization: `Bearer ${jwtToken}` }
        });
        console.log('Received response from backend for Firebase token');
        const { firebaseToken } = response.data;

        await signInWithCustomToken(auth, firebaseToken);
        console.log('User successfully authenticated with Firebase');
    } catch (error) {
        console.error('Error during Firebase authentication:', error);
        if (retries > 0) {
            console.log(`Retrying Firebase authentication, ${retries} attempts left`);
            await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second before retrying
            return authenticateWithFirebase(jwtToken, retries - 1);
        } else {
            console.error('Firebase authentication failed after all retry attempts');
        }
    }
};

export { authenticateWithFirebase };