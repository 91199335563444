
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';
import { getAuth } from "firebase/auth";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAfyUlFuLg_gyVUL683wm-EUARyztCl4BI",
    authDomain: "guestlink-56a36.firebaseapp.com",
    projectId: "guestlink-56a36",
    storageBucket: "guestlink-56a36.appspot.com",
    messagingSenderId: "13556573482",
    appId: "1:13556573482:web:c570003d482923da29ea53",
    measurementId: "G-V150DLCM83"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { storage, auth };