import React, { useState, useEffect } from 'react';
import { MoreHorizontal, ChevronRight, MessageSquare, Star, HelpCircle } from 'lucide-react';
import SideBar from '../components/SideBar';
import HeaderMain from '../components/HeaderMain';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
// import { jwtDecode } from 'jwt-decode';
import { CiCircleQuestion } from "react-icons/ci";



const Communication = () => {
    const [activeTab, setActiveTab] = useState('SMS');
    const messagesLeft = 256;
    const totalMessages = 400;
    const percentage = (messagesLeft / totalMessages) * 100;
    const [assistants, setAssistants] = useState([])
    const [loading, setLoading] = useState(true)
    const [sentMessages, setSentMessages] = useState([]);
    const [error, setError] = useState(null);

    const truncateStyles = {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: 2,
        wordBreak: 'break-word'
    };

    useEffect(() => {
        const fetchSentMessages = async () => {
            try {
                const token = localStorage.getItem('userToken');
                if (!token) {
                    console.error("No token found");
                    setError("You must be logged in to view messages");
                    return;
                }

                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/recent-messages`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                if (response.data && response.data.renterPushNotifications) {
                    setSentMessages(response.data.renterPushNotifications);
                } else {
                    setSentMessages([]);
                }
            } catch (err) {
                console.error("Error fetching sent messages:", err);
                setError("Failed to load sent messages");
                setSentMessages([]); // Set to empty array in case of error
            }
        };
        fetchSentMessages();
    }, []);



    useEffect(() => {
        const getAssistants = async () => {
            const token = localStorage.getItem('userToken');
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/assistants/get-not-locked`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                const assistantsData = response.data;

                const filteredAssistants = assistantsData.filter(assistant => assistant.iCalLink && assistant.iCalLink.trim() !== '');

                setAssistants(filteredAssistants);

                // Set mostRecentAssistant
                // setMostRecentAssistant(assistantsData.length > 0 ? assistantsData[assistantsData.length - 1] : null);
            } catch (error) {
                console.error("error getting assistant", error);
            } finally {
                setLoading(false);
            }
        };
        getAssistants();
    }, []);

    return (
        // Main container - full height of the screen
        <div className='flex bg-[#161616] h-screen overflow-hidden'>
            {/* Sidebar */}
            <div>
                <SideBar />
            </div>
            {/* Main content area - flex column to stack header and content */}
            <div className='flex flex-col w-full overflow-hidden p-3 bg-[#eeece7] mt-3 -ml-3 rounded-t-2xl rounded-b-2xl'>
                {/* Header - fixed at the top */}
                {/* <HeaderMain /> */}
                {/* Scrollable content area */}
                <div className="flex-1 overflow-y-auto p-6 rounded-2xl pb-16 z-10">
                    <h1 className="text-2xl font-bold mb-4">Communication</h1>

                    {/* Tabs */}
                    <div className="flex mb-6 items-center space-x-3">
                        <button
                            className={`pb-2 ${activeTab === 'SMS' ? 'text-[#FF5A5F] border-b-2 border-[#FF5A5F]' : 'text-gray-500'}`}
                            onClick={() => setActiveTab('SMS')}
                        >
                            SMS
                        </button>

                        <div className="relative group inline-block">
                            <NavLink to={'/ical-instructions'} className="hover:text-blue-400 ">
                                <CiCircleQuestion size={20} style={{ strokeWidth: 0.5 }} className="text-black hover:text-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm" />
                            </NavLink>
                            <div className="absolute z-10 hidden group-hover:block w-56 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm dark:bg-gray-700">
                                Find out more about this page and how to best utilize it
                                <div className="tooltip-arrow" data-popper-arrow></div>
                            </div>
                        </div>
                    </div>

                    <>
                        {assistants.length === 0 ? (
                            <div className='flex flex-col mt-10 space-y-4 items-center justify-center justify-self-center place-items-center w-full text-center'>
                                <p className='text-grey-400 font-bold text-3xl'>Nothing here yet sorry...</p>
                                <p className='w-96'>To enable the return renters feature or find out more, <span className='font-bold'>sync your renter calendar</span> for one or more of your assistants :)</p>
                                <NavLink to={'/ical-instructions'} className='mb-5 p-1 rounded-2xl px-3 py-2 bg-[#f4ccc9] inline-block hover:bg-white'>See How</NavLink>
                            </div>
                        ) : (
                            <>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                {/* Messages Left */}
                                {/* <div className="bg-white p-6 rounded-2xl shadow">
                                    <div className="flex justify-center">
                                        <CircleProgress percentage={percentage} />
                                    </div>
                                    <p className="text-center mt-4 font-semibold">{messagesLeft} Renters Returning</p>
                                    <p className="text-center text-sm text-gray-500">Out of {totalMessages}</p>
                                    
                                </div> */}
                                

                                {/* Favourite Groups */}
                                {/* <div className="bg-white p-6 rounded-2xl shadow flex flex-col justify-between">
                                    <div className=''>
                                        <h2 className="text-xl font-semibold mb-2">Quick Send</h2>
                                        <div className="space-y-4">
                                            <div className="flex justify-between items-center">
                                                <div>
                                                    <h3 className="font-medium">All Opted-in Renters</h3>
                                                    <p className="text-sm text-gray-500">58 Renters</p>
                                                    

                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <button className="w-full py-2 px-4 bg-[#FF5A5F] text-white rounded-2xl ">
                                            Compose SMS
                                        </button>
                                        <div className='flex items-center justify-between mt-2'>
                                            <div className="flex items-center space-x-2 mb-2 mt-4">
                                                <span className="bg-white text-[#FF5A5F] border border-[#FF5A5F] px-3 py-1 rounded-full text-sm">SMS</span>
                                                <span className="bg-white text-[#FF5A5F] border border-[#FF5A5F] px-3 py-1 rounded-full text-sm">all opted-in</span>
                                                <span className="bg-white text-[#FF5A5F] border border-[#FF5A5F] px-3 py-1 rounded-full text-sm">renters</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div> */}
                                            {/* <button className="text-[#FF5A5F] hover:underline text-sm text-right">
                                                Send to Specific People
                                            </button> */}


                            </div>

                            {/* Saved Groups */}
                            <div className="mt-8 bg-white p-6 rounded-2xl shadow overflow-x-scroll ">
                                <h2 className="text-xl font-semibold mb-4">Groups by Property</h2>
                                <div className="flex space-x-4">
                                    {assistants.map((assistant) => (
                                        <div key={assistant._id} className="bg-white border border-gray-200 p-4 rounded-xl shadow-sm w-64 min-w-64">
                                            <div className="flex justify-between items-center mb-3">
                                                <div>
                                                    <h3 className="font-medium">{assistant.propertyAddress}</h3>
                                                    <p className="text-sm text-gray-500">{assistant.currentBookings?.length || 0} Current Bookings</p>
                                                </div>
                                                <button className="text-gray-400 hover:text-gray-600">
                                                    <MoreHorizontal size={18} />
                                                </button>
                                            </div>
                                            <NavLink
                                                to={`/message-renters/${assistant._id}`}
                                                className="w-full py-2 px-4 bg-[#FF5A5F] text-white rounded-lg hover:bg-[#FF5A5F] hover:text-white transition duration-300 flex justify-center items-center"
                                            >
                                                Compose SMS
                                                <ChevronRight size={18} className="ml-2" />
                                            </NavLink>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Recent SMS's */}
                            <div className="mt-8 bg-white p-6 rounded-2xl shadow overflow-x-auto">
                                <h2 className="text-xl font-semibold mb-4">Recent SMS's</h2>
                                <table className="w-full min-w-[600px]">
                                    <thead>
                                        <tr className="text-left text-gray-500">
                                            <th className="pb-2 w-[50%] min-w-[200px]">SMS</th>
                                            <th className="pb-2 w-[20%] min-w-[100px]">Sent To</th>
                                            <th className="pb-2 w-[25%] min-w-[120px]">Date Sent</th>
                                            <th className="pb-2 w-[5%] min-w-[40px]"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sentMessages && sentMessages.length === 0 ? (
                                            <tr>
                                                <td colSpan="4" className="py-10 text-center text-gray-500">No messages sent yet.</td>
                                            </tr>
                                        ) : (
                                            sentMessages.map((msg, index) => (
                                                <tr key={index} className="border-t">
                                                    <td className="py-3 pr-4">
                                                        <div style={truncateStyles} className="max-w-[400px]">
                                                            {msg.messageContent}
                                                        </div>
                                                    </td>
                                                    <td className="py-3">{msg.sentTo} renters</td>
                                                    <td className="py-3">{new Date(msg.date).toLocaleDateString()}</td>
                                                    <td className="py-3 text-right">
                                                        <ChevronRight size={20} className="inline text-gray-400" />
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </>
                        )}
                    </>


                </div>
            </div>
        </div>
    );
};

export default Communication;




// const CircleProgress = ({ percentage, size = 150 }) => {
//     const strokeWidth = 10;
//     const radius = (size - strokeWidth) / 2;
//     const circumference = radius * 2 * Math.PI;
//     const strokeDashoffset = circumference - (percentage / 100) * circumference;

//     return (
//         <div className="relative" style={{ width: size, height: size }}>
//             <svg className="w-full h-full" viewBox={`0 0 ${size} ${size}`}>
//                 <circle
//                     className="text-[#f5f4f0]"
//                     strokeWidth={strokeWidth}
//                     stroke="currentColor"
//                     fill="transparent"
//                     r={radius}
//                     cx={size / 2}
//                     cy={size / 2}
//                 />
//                 <circle
//                     className="text-[#FF5A5F]"
//                     strokeWidth={strokeWidth}
//                     strokeDasharray={circumference}
//                     strokeDashoffset={strokeDashoffset}
//                     strokeLinecap="round"
//                     stroke="currentColor"
//                     fill="transparent"
//                     r={radius}
//                     cx={size / 2}
//                     cy={size / 2}
//                     style={{
//                         transform: 'rotate(-90deg)',
//                         transformOrigin: '50% 50%',
//                         transition: 'stroke-dashoffset 0.35s',
//                     }}
//                 />
//             </svg>
//             <div className="absolute inset-0 flex items-center justify-center">
//                 <span className="text-3xl font-bold">{Math.round(percentage)}%</span>
//             </div>
//         </div>
//     );
// };